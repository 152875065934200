<template>
    <!-- 流行趋势 -->
    <div class="container">
        <div class="trend-box">
            <!-- 轮播图 -->
            <el-carousel trigger="click" height="440px">
                <el-carousel-item v-for="item in 4" :key="item">
                    <h3 class="small">{{ item }}</h3>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import {Cookies} from 'js-cookie'
export default {
    data(){
        return{

        }
    },
    created:{
        
    },
    methods:{

    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    .trend-box {
        width: 1920px;
        height: 2270px;
        margin: auto;
        // background-color: aqua;

        .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 150px;
            margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }

        .el-carousel__item:nth-child(2n+1) {
            background-color: #d3dce6;
        }

        //左箭头
        ::v-deep .el-carousel__arrow--left {
            top: 180px;
            left: 366px;
            font-size: 86px;
            color: #FFFFFF;
            opacity: 0.4;
            background: rgba(255, 255, 255, 0);
            border-radius: 50%;
            width: 86px;
            height: 86px;
        }

        //右箭头
        ::v-deep .el-carousel__arrow--right {
            top: 180px;
            right: 366px;
            font-size: 86px;
            color: #FFFFFF;
            opacity: 0.4;
            background: rgba(255, 255, 255, 0);
            border-radius: 50%;
            width: 86px;
            height: 86px;
        }
    }
}
</style>