<template>
    <!-- 首页 -->
    <div class="contenter">
        <PcHeader></PcHeader>
      
        <div class="top">
            <!-- 轮播图（广告位） -->
            <el-carousel>
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <img src="../../../assets/images/image_banner.png">
                </el-carousel-item>
            </el-carousel>

        </div>
      
        <div class="min-hot">
            <div class="min-top">
                <span class="span1" style="width: 100%;text-align: center;">Hot List</span>
               <!--  <div class="color"></div> 
                <span class="span2">Hot&nbsp;products</span>-->
            </div>
            <div class="min-min">
                <div class="mim-min-item" v-for="(item, index) in hotList" :key="index" @click="goDetail(item.id)">
                    <!-- <img :src="item.originalImage" alt=""> -->
                    <img  v-if="imgloading" ref="imgRef" :src="item.thumbnail" alt="">
                    <img v-else ref="imgRef" :src="item.originalImage" alt="">
                    <div class="commdity_name">{{item.commodityName}}</div>
                </div>
            </div>
            <!-- <div class="min-footer">
                <div class="min-footer-inner" @click="goToFabric">
                    {{ $t('navbar.loadMore') }}&nbsp;<img src="../../../assets/images/right-jt2.png" alt="">
                </div>
            </div> -->
        </div>
        <PcFooter></PcFooter>
    </div>

</template>

<script>
import { homeListApi,bannerListApi,specialistListApi } from '../../../api/index'
import PcHeader from "../../../components/pc/Header.vue"
import PcFooter from "../../../components/pc/Footer.vue"
export default {
    components: {
        PcHeader,
        PcFooter,
    },
    data() {
        return {
            bannerList: [{"bannerImage":""}], //轮播图列表
            list: [], 
            // 新品
            hotList: [], // hot
            id: '',
            comList: [], // 通过传入id获取选中的商品列表
            total: 0, // 总条数
            pageSize: 0, // 每页多少条

            // 传入的新品参数
            xpObj: {
                current: 1,     //当前第几页
                commodityLabel: 1,
                pageSize: 8
            },
            // 传入的热门参数
            hotObj: {
                current: 1,     //当前第几页
                commodityLabel: 2,
                pageSize: 12
            },
            firsthot: [],
            hotright: [],
            // language: 'zh', // 选中的语言
            // languagelist: ['english', zh],
            // huancun: {}
            imgloading: true,
            hotimgloading: true,
            num: 0,
            nums: 0,

            specialistObj:{			//面料专家查询
                enable: 0,
                pageNumber: 1,
                pageSize: 3,
            },
            specialistList:[],		//面料专家列表数据
            tagList:[]
        }
    },
    watch: {
        list(val) {
            this.$nextTick(function () {
                let lists = this.$refs.imgRef;
                for (let i in lists) {
                    this.imgLoad(lists[i], () => {
                        if (this.num == lists.length) {
                            // console.log("高清图加载完成");
                            this.imgloading = false
                        }
                    })
                }
            })
        },
        hotList(val) {
            this.$nextTick(function () {
                let lists = this.$refs.imgRefs;
                for (let i in lists) {
                    this.imgLoads(lists[i], () => {
                        if (this.nums == lists.length) {
                            // console.log("高清图加载完成");
                            this.hotimgloading = false
                        }
                    })
                }
            })
        },
    },
    methods: {
        // 高清图片加载
        imgLoad(img, callback) {
            var _this = this;
            var timer = setInterval(function () {
                if (img.complete) {
                    _this.num++;
                    // console.log(_this.num);
                    callback(img);
                    clearInterval(timer);
                }
            }, 50);
        },
        imgLoads(img, callback) {
            var _this = this;
            var timer = setInterval(function () {
                if (img.complete) {
                    _this.nums++;
                    // console.log(_this.num);
                    callback(img);
                    clearInterval(timer);
                }
            }, 50);
        },

        // 首页图片渲染
        async getcommodityList(xpObj, hotObj) {
            // const { data: xpres } = await homeListApi(xpObj)
            const { data: hotres } = await homeListApi(hotObj)

            // console.log("新品展示", xpres)
            // console.log("热门商品", hotres)
            // this.list = xpres.result.rows
            this.hotList = hotres.result.rows
            console.log("hotlist",this.hotList.length)
            // console.log(this.list)
            // console.log(this.hotList)
            // this.firsthot = this.hotList[0]
            // // console.log(this.firsthot)
            // this.hotright = this.hotList.splice(1, 5)
            // console.log(this.hotright)
        },
        goDetail(id) {
            this.$router.push({ path: `/fabricdetail/${id}` })
        },
        goToFabric() {
            this.$router.push({ path: '/fabric' })
        },
        //获取轮播图列表
        async getBannerList() {
            const {data:res} = await bannerListApi()
            // console.log('轮播图列表==>',res)
            // this.bannerList = res.result
        },
        //点击轮播图跳转
        getBannerPath(item){
            console.log(item,item.bannerPath) 
            // window.open('https://' + item.bannerPath)
            window.open(item.bannerPath)
        },
        //获取面料专家数据
        // async getSpecialistList(specialistObj){
        //     let {data:res} = await specialistListApi(specialistObj)
        //     if (res.result == null) {
        //         return
        //     } else {
        //         this.specialistList = res.result.rows
        //         this.specialistList.forEach((item,index)=>{
        //             this.tagList[index] = item.businessSector.split(',')
        //         })
        //         // console.log('获取面料专家数据',this.specialistList)
        //         // console.log('this.tagList',this.tagList)
        //     }
        // },
        //跳转面料专家页面
        getSpecialist(){
            this.$router.push({ path: '/specialist' })
        },
        //跳转推荐选品（面料专家个人详情页）
        getSpecialistDetail(id){
            this.$router.push({ path: `/specialistdetail/${id}`})
        }
    },
    // 页面初始化加载商品
    created() {
        this.getcommodityList(this.xpObj, this.hotObj)
        this.getBannerList()
        // this.getSpecialistList(this.specialistObj)
    }
}
</script>

<style lang="scss" scoped>
.contenter {
    width: 100%;
    // height: 2000px;
    .top {
        // position: relative;
        max-width: 1920px;
        height: 756px;
        // background-image: url(../../../assets/images/bg.png);
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: 100% 100%;
        // margin: auto;

        .el-carousel{
            max-width: 1920px;
            height: 100%;
            // background-color: aqua;
            /deep/.el-carousel__container{
                position: relative;
                height: 100%;
            }
            img{
                width: 100%;
                cursor: pointer;
            }
        }
    }

    .min-hot {
        width: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        min-height: 1200px;
        margin-bottom: 200px;

        .min-top {
            width: 717px;
            height: 85px;
            margin-top: 64px;
            display: flex;
            align-items: center;

            .span1 {
                width: 256px;
                opacity: 1;
                font-family: MiSans;
                letter-spacing: 0em;
                color: #000;
                font-size: 50px;
                font-weight: normal;
                line-height: 75px;
            }

            .color {
                width: 16px;
                height: 33px;
                background-color: #FF235A;
                margin: 0 18px 0 35px;
            }

            .span2 {
                display: flex;
                width: 392px;
                line-height: 80px;
                opacity: 1;
                font-family: MiSans;
                font-size: 60px;
                font-weight: normal;
                letter-spacing: 0em;
                color: #1A1919;
            }
        }

        .min-min {
            width: 1140px;
            height: 580px;
            margin-top: 44px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .mim-min-item {
                width: 252px;
                height: 252px;
                // background-color: aqua;
                // border: 1px solid rgb(211, 211, 211);
                margin: 5px;
                margin-bottom: 50px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 20px;
                }
                .commdity_name {
                    font-family: MiSans;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: normal;
                }
            }
        }

        .min-footer {
            width: 308px;
            height: 65px;
            background-color: #FF235A;
            margin-top: 59px;
            border-radius: 70px;
            display: flex;
            align-items: center;
            justify-content: center;

            .min-footer-inner {
                width: 186px;
                height: 48px;
                display: flex;
                align-items: center;
                color: #FFFFFF;
                font-size: 36px;
                cursor: pointer;

                img {
                    width: 29px;
                    height: 29px;
                }
            }
        }
    }

    .footer {
        width: 100%;
        height: 945px;
        background-color: #F4F4F4;
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-top {
            width: 424px;
            height: 85px;
            margin-top: 60px;
            display: flex;
            align-items: center;

            .span3 {
                width: 256px;
                line-height: 85px;
                font-size: 64px;
                color: #1A1919;
                font-family: MiSans;
                font-weight: normal;
            }

            .color2 {
                width: 16px;
                height: 33px;
                background-color: #FF235A;
                margin: 0 18px 0 35px;
            }

            .span4 {
                width: 99px;
                line-height: 80px;
                font-size: 60px;
                color: #1A1919;
            }
        }

        .footer-footer {
            display: flex;

            .left {
                width: 559px;
                height: 642px;
                // box-sizing: border-box;
                // background-color: aquamarine;
                border: 1px solid rgb(211, 211, 211);
                margin: 75px 0 0 -4px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .left-footer {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 48px;
                    background: rgba($color: #FFFFFF, $alpha: 0.3);

                    .left-footer-inner {
                        width: 229px;
                        height: 29px;
                        // background-color: aqua;
                        margin: 8px 0 0 27px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }

            .right {
                width: 549px;
                height: 641px;
                // background-color: #FF235A;
                border: 1px solid rgb(211, 211, 211);
                margin: 75px 0 0 36px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: space-between;

                .right-item {
                    width: 253px;
                    height: 300px;
                    // background-color: brown;
                    border: 1px solid rgb(211, 211, 211);
                    // margin-bottom: 38px;

                    img {
                        width: 253px;
                        height: 253px;
                        // background-color: rgb(212, 212, 212);
                    }

                    .right-footer {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .right-footer-inner {
                            width: 229px;
                            height: 29px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
    .specialist {
        width: 100%;
        height: 1014px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;

        .specialist-top {
            width: 717px;
            height: 85px;
            margin-top: 92px;
            display: flex;
            align-items: center;

            .span1 {
                width: 256px;
                line-height: 85px;
                opacity: 1;
                font-family: MiSans;
                font-size: 64px;
                font-weight: normal;
                letter-spacing: 0em;
                color: #1A1919;
            }

            .color {
                width: 16px;
                height: 33px;
                background-color: #FF235A;
                margin: 0 18px 0 35px;
            }

            .span2 {
                display: flex;
                width: 392px;
                line-height: 80px;
                opacity: 1;
                font-family: MiSans;
                font-size: 60px;
                font-weight: normal;
                letter-spacing: 0em;
                color: #1A1919;
            }
        }

        .specialist-specialist {
            width: 1140px;
            height: 580px;
            margin-top: 70px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .mim-specialist-item {
                width: calc(413px * 0.7);
                height: 560px;

                .item_img{
                    width: calc(413px * 0.7);
                    height: calc(579px * 0.7);
                    border-radius: 20px;
                    box-sizing: border-box;
                    border: 1px solid rgb(211, 211, 211);
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                    }
                }
                .item_name{
                    font-size: 24px;
                    line-height: 40px;
                    margin: 10px;
                }
                .item_businessSector{
                    width: calc(413px * 0.7);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    .businessSector_item{
                        display: inline-block;
                        border-radius: 5px;
                        opacity: 1;
                        padding: 0 12px;
                        background: rgba(250, 81, 81, 0.2);
                        margin: 5px 10px;

                        span{
                            font-family: Source Han Sans;
                            font-size: 20px;
                            font-weight: normal;
                            line-height: 36px;
                            letter-spacing: 0em;
                            color: #FA5151;
                        }
                    }
                }
            }
        }

        .specialist-footer {
            width: 308px;
            height: 65px;
            background-color: #FF235A;
            margin-top: 59px;
            border-radius: 70px;
            display: flex;
            align-items: center;
            justify-content: center;

            .specialist-footer-inner {
                width: 186px;
                height: 48px;
                display: flex;
                align-items: center;
                color: #FFFFFF;
                font-size: 36px;
                cursor: pointer;

                img {
                    width: 29px;
                    height: 29px;
                }
            }
        }
    }
}
</style>