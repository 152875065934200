<template>
    <!-- 今日询样数表格 -->
    <div class="taber-box">
        <el-table :data="tableData" style="width: 900px;" :row-class-name="tableRowClassName"
            header-cell-class-name="table_header" :cell-style="tableCellStyle">
            <el-table-column prop="date" label="日期" width="180">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="address" label="地址">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            },]
        }
    },
    watch: {},
    methods: {
        tableCellStyle(){
            // return 'border: none;'
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 === 1) {
                return 'warning-row';
            } else if (rowIndex % 2 === 0) {
                return 'success-row';
            }
            return '';
        }
    },
    mounted() {

    },
}
</script>

<style lang='scss' scoped>
.taber-box{
    border-bottom: 1px solid #2C345A !important;
}
/deep/.tbody {
    // border-bottom: 1px solid #2C345A !important;
    border: none;
}

/deep/.el-table {
    color: #B7C0DD;
    margin-top: 5px;
    border-bottom: none;
    background-color: #202648 !important;
    // border-bottom: 1px solid #2C345A !important;
}

/deep/.table_header {
    background-color: #202648 !important;
    border-bottom: 1px solid #2C345A !important;
}

.has-gutter {
    background-color: #202648;
}

/deep/.el-table .warning-row {
    background: #202648;
}

/deep/.el-table .success-row {
    background: #202648;
}

/deep/.el-table .el-table__cell {
    border-bottom: 1px solid #2C345A;
}

// 鼠标滑过td上作用的样式，ie6不支持:hover伪类属性，如果是ie6要写成tr.over样式
/deep/.el-table tbody tr:hover>td {
    background-color: #323c77 !important;
}

// 如果td没有颜色，可以去掉，这里加上保险
/deep/.el-table__body tr.hover-row>td {
    background-color: transparent;
}
</style>