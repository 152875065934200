<template>
    <div class="contenter">
        <PcHeader></PcHeader>
        
        <div class="contenter-inner">
            <div class="Select-identity">
                请选您的身份 / Please select your identity
            </div>
            <div class="line"></div>

            <div class="select-identity__content">
                <div class="select-item item1" :class="{ selected: selectedIndex === 0 }" ref="item1" @click="handleClick(0)">
                    <img v-if="selectedIndex == 0" src="../../../assets/images/gys.png" alt="">
                    <img v-else src="../../../assets/images/gys_red.png" alt="">
                    <div style="text-align: center;">
                        <div style="font-size: 20px; line-height: 1.5;">供应商</div>
                        <div style="font-size: 12px;">supplier</div>
                    </div>
                </div>
                <div class="select-item item2" :class="{ selected: selectedIndex === 1 }" ref="item1" @click="handleClick(1)">
                    <img v-if="selectedIndex == 1" src="../../../assets/images/sjs.png" alt="">
                    <img v-else src="../../../assets/images/sjs_red.png" alt="">
                    <div style="text-align: center;">
                        <div style="font-size: 20px; line-height: 1.5;">设计师</div>
                        <div style="font-size: 12px;">designer</div>
                    </div>
                </div>
            </div>

            <div class="Select-identity">
                请填写您的信息 / Please fill in your information
            </div>
            <div class="line"></div>

            <div class="formInfo_gys" v-if="selectedIndex == 0">
                <el-form ref="form" :model="formData" :rules="rules">
                    <el-form-item prop="company">
                        <el-input v-model="formData.company" placeholder="公司 / company" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="legalName">
                        <el-input v-model="formData.legalName" placeholder="法人名字 / legalName" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="legalId">
                        <el-input v-model="formData.legalId" placeholder="法人身份证号 / legalId" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="legalPhone">
                        <el-input v-model="formData.legalPhone" placeholder="法人联系方式 / legalPhone" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="enterprisePhone">
                        <el-input v-model="formData.enterprisePhone" placeholder="企业联系电话 / enterprisePhone" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="licencePhoto" class="licencePhoto">
                        <div style="position: relative; padding-right: 90px;">
                            <el-input v-model="displayFileName" placeholder="营业执照(png,jpg格式) / licencePhoto" disabled style="width: 230px;" />
                            <el-upload :show-file-list="false" class="avatar-uploader" name="multipartFile" :auto-upload="true" :on-change="uploadChange" :action="upUrl">
                                <i class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </div>
                        <div class="star">*</div>
                    </el-form-item>

                    <el-form-item prop="licenseNum">
                        <el-input v-model="formData.licenseNum" placeholder="营业执照号码 / licenseNum" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="registeredCapital">
                        <el-input v-model="formData.registeredCapital" placeholder="注册资本（万） / registeredCapital" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="standardTime" class="expirationTime">
                        <!-- <el-input v-model="formData.expirationTime" placeholder="有效期 / expirationTime" /> -->
                        <el-date-picker v-model="standardTime" type="date" placeholder="有效期 / expirationTime"></el-date-picker>
                        <div class="star">*</div>
                    </el-form-item>

                    <el-form-item prop="gateAddress">
                        <el-input v-model="formData.gateAddress" placeholder="档口地址 / gateAddress" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="factoryAddress">
                        <el-input v-model="formData.factoryAddress" placeholder="工厂地址 / factoryAddress" />
                        <div class="star">*</div>
                    </el-form-item>
                    
                </el-form>
                <div class="select-identity__footer">
                    <button class="btn btn1" @click="submit">
                        提交
                    </button>
                </div>
            </div>

            <div class="formInfo_sjs" v-if="selectedIndex == 1">
                <el-form ref="sjsForm" :model="sjsFormData" :rules="rules2">
                    <el-form-item prop="designerName">
                        <el-input v-model="sjsFormData.designerName" placeholder="姓名 / name" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="company">
                        <el-input v-model="sjsFormData.company" placeholder="公司 / company" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="industry">
                        <el-input v-model="sjsFormData.industry" placeholder="行业 / industry" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="position">
                        <el-input v-model="sjsFormData.position" placeholder="职位 / job" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="address">
                        <el-input v-model="sjsFormData.address" placeholder="地址 / address" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="phoneNumber">
                        <el-input v-model="sjsFormData.phoneNumber" placeholder="联系电话 / telephone" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="email">
                        <el-input v-model="sjsFormData.email" placeholder="邮箱 / email" />
                        <div class="star">*</div>
                    </el-form-item>
                </el-form>
                <div class="select-identity__footer">
                    <button class="btn btn1" @click="sjsSubmit">
                        提交
                    </button>
                </div>
            </div>

            <el-dialog ref="popup" :visible="dialogVisible" width="50%">
			<div class="icon-tick">
				<img src="../../../assets/images/icon-tick.png" />
			</div>
			<div class="popup-bg">
				<div class="popup-content">
					<div style="font-size: 32px; font-weight: 500; line-height: 1.2; margin-bottom: 10px;">提交成功</div>
					<div style="margin-bottom: 20px; font-size: 20px;">success</div>
					<div style="font-size: 16px; line-height: 1.2; margin-bottom: 10px;">
                        请耐心等待工作人员后台审核，审核时常3个工作日，审核通过后请留意邮箱和短信信息。
                    </div>
                        <div style="font-size: 16px; line-height: 1.2;">
                            Please be patient and wait for the staff to review in the background. The review period is 3 working days. After the review 
                            is passed, please pay attention to the information in the email and text messages.
                        </div>
                    </div>
                    <div class="popup-content__footer">
                        <button class="btn btn1" @click="goHome">
                            返回首页<br><text>return to the homepage</text>
                        </button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import PcHeader from "../../../components/pc/Header.vue"
    import { supplierAddApi } from "../../../api/index"
    export default {
        components: {
            PcHeader,
        },
        data() {
            return {
                dialogVisible: false,
                selectedIndex: 0,
                upUrl: "/fabTank-ftfs/image/upload" + "?categoryNum=shop", //图片上传
                displayFileName: '',        //上传图片的名字  仅用于显示
                standardTime: '',       //中国标准时间
                formData: {
					company: '',        //公司
					legalName: '',      //法人名字
					legalId: '',        //法人身份证号
					legalPhone: '',     //法人联系方式
                    enterprisePhone: '',    //企业联系电话
					licencePhoto: '',       //营业执照
					licenseNum: '',     //营业执照号码
					registeredCapital: '',  //注册资本
                    expirationTime: '',     //有效期  时间 yyyy-mm-dd
                    gateAddress: '',     //档口地址
                    factoryAddress: '',     //工厂地址
                    status: 0,              //供应商状态  不显示 默认为0
				},
				rules: {
					company: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					legalName: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					legalId: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					legalPhone: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    enterprisePhone: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					displayFileName: [{
						required: true,
						message: '请输入',
						// trigger: 'blur'
					}],
					licenseNum: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					registeredCapital: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    gateAddress: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    factoryAddress: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
				},

                sjsFormData: {
                    designerName: '',   //设计师姓名
                    company: '',	    //公司
                    industry: '',	    //行业
                    position: '',	    //职位
                    address: '',	    //地址
                    phoneNumber: '',	    //联系电话
                    email: '',	    //邮箱
                },
                rules2: {
                    designerName: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					company: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    industry: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    position: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    address: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    phoneNumber: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                    email: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
                },
            }
        },
        watch: {
            
        },
        methods: {
            goHome() {
				this.$router.push('/')
			},
            handleClick(index) {
                
                if (this.selectedIndex !== index) {
                    this.selectedIndex = index;
                    if(this.selectedIndex == 1){
                        //replace 不计入历史记录
                        this.$router.replace({
                            path:'/joinClubDesigner'
                        })
                    }
                }
            },
            uploadChange(e) {
				// console.log('uploadChange', e)
                if(e.response){
                    this.displayFileName = e.response.result.fileName;
				    this.formData.licencePhoto = e.response.result.image_url;
                }
			},

            //提交
			submit() {
                //提交前的时间转化  中国标准时间转 yyyy-mm-dd
                let parsedDate = new Date(this.standardTime);
                let year = parsedDate.getFullYear();
                let month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
                let day = parsedDate.getDate().toString().padStart(2, '0');
                this.formData.expirationTime = `${year}-${month}-${day}`;
                // console.log(this.formData.expirationTime);


				this.$refs.form.validate((valid) => {
					if (valid) {
                        if(this.formData.licencePhoto == ''){
                            this.$message({
                                message: '请上传营业执照',
                                type: 'warning'
                            });
                        } else if(this.formData.expirationTime == 'NaN-NaN-NaN'){
                            this.$message({
                                message: '请选择有效期',
                                type: 'warning'
                            });
                        } else {
                            this.getSupplierAdd()
                        }
                        
					}
				})
			},
            //俱乐部添加供应商
            async getSupplierAdd() {
                let { data: res } = await supplierAddApi(this.formData)
                // console.log('俱乐部添加供应商--res',res)
                if(res && res.success==true){
                    this.dialogVisible = true;
                }else{
                    this.$message({
						message: '提交失败，请联系客服',
						type: 'warning'
					});
                }
            },
            //设计师表单提交
            sjsSubmit(){
                this.$refs.sjsForm.validate((valid) => {
					if (valid) {
						// this.dialogVisible = true;
					}
				})
            }
        },
        created() {
            this.selectedIndex = 0;
        },
        mounted() {
            
        },
    }
</script>

<style lang="scss" scoped>
    .contenter{
        width: 100%;
    }
    .contenter-inner{
        width: 1200px;
        margin: 0 auto;
        .Select-identity{
            font-family: Source Han Sans;
            font-size: 24px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #000000;
            margin: 64px 0 14px 0;
        }
        .line{
            height: 1px;
            opacity: 1;
            background: #D8D8D8;
        }
        .select-identity__content{
            width: 100%;
            height: 110px;
            margin-top: 30px;
            display: flex;
            // background-color: aqua;
            .select-item{
                width: 260px;
                height: 110px;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 32px;
                cursor: pointer;
                img{
                    width: 60px;
                    margin-right: 30px;
                    height: 60px;
                }
            }
            .select-item.selected {
                background: linear-gradient(90deg, #FF235A 0%, #FB6969 100%);
            }
        }
        .formInfo_gys{
            width: 480px;
            margin: 30px 0;
            /deep/.el-input__inner{
                width: 480px;
                height: 56px;
                border-radius: 10px;
                padding-left: 44px;
                font-size: 18px;
            }
            /deep/.el-form-item {
                margin-bottom: 36px;
            }
            /deep/.el-form-item__error {
                font-size: 18px;
            }
            /deep/.el-form-item {
                position: relative;
                .star {
                    position: absolute;
                    left: 20px;
                    top: 17px;
                    color: #FA5151;
                    font-weight: bolder;
                    font-size: 30px;
                }
                .el-input__content {
                    padding-left: 40px;
                }
                .el-input__content-input{
                    font-size: 30px;
                    height: 80px;
                }
                // .is-input-border {
                //     border-radius: 10px;
                //     background: rgba(255, 255, 255, 0.5) !important;
                //     border: 2px solid #FFA9A9 !important;
                // }
                // .el-input__placeholder-class {
                //     font-size: 30px;
                //     color: rgba(0, 0, 0, 0.6);
                // }
                .add-file {
                    width: 80px;
                    height: 80px;
                    border-radius: 6px;
                    // background: #FEFEFE;
                    // box-sizing: border-box;
                    // border: 2px solid #FFA9A9;
                    margin-left: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            /deep/.licencePhoto .el-input__inner{
                width: 380px;
            }
            /deep/.expirationTime .el-input__icon{
                width: 460px;
                text-align: end;
                line-height: 56px;
            }
            /deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
                width: 380px;
            }
            .avatar-uploader-icon{
                font-size: 1.75rem;
                color: #8c939d;
                width: 56px;
                height: 56px;
                line-height: 56px;
                text-align: center;
                border: .0625rem solid #d3d3d3;
                border-radius: .625rem;
                box-sizing: border-box;
                margin-left: 20px;
            }
            .select-identity__footer{
                width: 100%;
                height: 64px;
                // background-color: aqua;
                .btn1{
                    width: 100%;
                    height: 100%;
                    background-color: #FF235A;
                    color: #FFFFFF;
                    font-size: 24px;
                    border: none;
                    border-radius: 10px;
                }

            }
        }
        .formInfo_sjs{
            width: 480px;
            margin: 30px 0;
            /deep/.el-input__inner{
                width: 480px;
                height: 56px;
                border-radius: 10px;
                padding-left: 44px;
                font-size: 18px;
            }
            /deep/.el-form-item {
                margin-bottom: 36px;
            }
            /deep/.el-form-item__error {
                font-size: 18px;
            }
            /deep/.el-form-item {
                position: relative;
                .star {
                    position: absolute;
                    left: 20px;
                    top: 17px;
                    color: #FA5151;
                    font-weight: bolder;
                    font-size: 30px;
                }
                .el-input__content {
                    padding-left: 40px;
                }
                .el-input__content-input{
                    font-size: 30px;
                    height: 80px;
                }
                // .is-input-border {
                //     border-radius: 10px;
                //     background: rgba(255, 255, 255, 0.5) !important;
                //     border: 2px solid #FFA9A9 !important;
                // }
                // .el-input__placeholder-class {
                //     font-size: 30px;
                //     color: rgba(0, 0, 0, 0.6);
                // }
                .add-file {
                    width: 80px;
                    height: 80px;
                    border-radius: 6px;
                    // background: #FEFEFE;
                    // box-sizing: border-box;
                    // border: 2px solid #FFA9A9;
                    margin-left: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            /deep/.licencePhoto .el-input__inner{
                width: 380px;
            }
            /deep/.expirationTime .el-input__icon{
                width: 460px;
                text-align: end;
                line-height: 56px;
            }
            /deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
                width: 380px;
            }
            .avatar-uploader-icon{
                font-size: 1.75rem;
                color: #8c939d;
                width: 56px;
                height: 56px;
                line-height: 56px;
                text-align: center;
                border: .0625rem solid #d3d3d3;
                border-radius: .625rem;
                box-sizing: border-box;
                margin-left: 20px;
            }
            .select-identity__footer{
                width: 100%;
                height: 64px;
                // background-color: aqua;
                .btn1{
                    width: 100%;
                    height: 100%;
                    background-color: #FF235A;
                    color: #FFFFFF;
                    font-size: 24px;
                    border: none;
                    border-radius: 10px;
                }

            }
        }
        /deep/.el-icon-close:before{
            display: none;
        }
        .popup-bg {
            background-color: white;
            border-radius: 16px;
            width: 40vw;
            height: 50vh;
            margin: 0 auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 50px;
        }
        .popup-content {
            background: linear-gradient(180deg, rgba(255, 107, 107, 0.5) 0%, rgba(255, 255, 255, 0) 64%);
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 36px;
            padding-top: 60px;
            box-sizing: border-box;
            position: relative;
            &__footer {
                flex: 0 0 120px;
                height: 120px;
                padding: 36px;
                .btn{
                    height: 120px;
                    padding: 16px 100px;
                    border-radius: 16px;
                    border: none;
                    font-size: 28px;
                    margin-bottom: 30px;
                    line-height: 1.5;
                    box-sizing: border-box;
                    width: 100%;
                }
                .btn1 {
                    background: linear-gradient(90deg, #FF235A 0%, #FB6969 100%);
                    box-shadow: 0px 5px 20px 0px rgba(251, 105, 105, 0.3);
                    color: #FFFFFF;
                }
                .btn2 {
                    background-color: white;
                    border: 2px solid #D2D2D2;
                    color: rgba(0, 0, 0, 0.7);
                }
            }
        }
        .icon-tick {
            width: 130px;
            height: 130px;
            background: linear-gradient(318deg, #FB6969 15%, #FF245A 85%);
            border-radius: 65px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -65px;
            z-index: 3;
            left: 50%;
            margin-left: -65px;
            img {
                width: 75px;
                height: 60px;
            }
        }
        .avatar-uploader {
            position: absolute;
            right: 0;
            width: 80px;
            top: 0;
        }
        /deep/.el-dialog {
            border-radius: 10px;
        }
    }
</style>

<style lang="scss">
	.el-date-picker {
    	width: 460px;
	}
	.el-date-picker .el-picker-panel__content {
		width: 450px;
	}
	.el-picker-panel__icon-btn {
		font-size: 16px;
        margin-right: 20px;
        margin-left: 20px;
	}
	.el-date-table{
		font-size: 16px;
	}
</style>