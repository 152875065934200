var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PcHeader'),_c('div',{staticClass:"inquiry-box"},[_c('div',{staticClass:"inquiry-inner"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"left"},[(!_vm.imgList.url)?_c('img',{attrs:{"src":require("../../../assets/images/default.jpg"),"alt":""}}):_c('img',{attrs:{"src":_vm.imgList.url,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"company"},[_vm._v(" "+_vm._s(_vm.$t("navbar.supertex"))+" ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"flannelette-specification-box"},[_c('span',{staticClass:"flannelette"},[_vm._v(_vm._s(_vm.$t("navbar.ArticleNumber"))+":")]),_c('span',{staticClass:"specification"},[_vm._v(_vm._s(_vm.sn))])]),_c('el-form',{ref:"formLabelAlign",attrs:{"label-position":_vm.labelPosition,"label-width":"126px","model":_vm.formLabelAlign}},[_c('el-form-item',{attrs:{"label":_vm.$t('navbar.ExpectedOrderQuantity'),"prop":"number","rules":[
                {
                  required: true,
                  trigger: 'blur',
                  message: _vm.$t('navbar.QuantityCannotEmpty'),
                },
                {
                  type: _vm.number,
                  message: _vm.$t('navbar.ExpectedQuantity'),
                  pattern: /^[1-9]\d*$/,
                  trigger: 'blur',
                },
              ]}},[_c('el-input',{staticStyle:{"height":"50px"},model:{value:(_vm.formLabelAlign.number),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "number", $$v)},expression:"formLabelAlign.number"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.ExpectedPrice'),"prop":"prices","rules":[
                {
                  required: true,
                  trigger: 'blur',
                  message: _vm.$t('navbar.PriceCannotEmpty'),
                },
                {
                  type: _vm.number,
                  message: _vm.$t('navbar.PriceMustRoundNumber'),
                  pattern: /^[1-9]\d*$/,
                  trigger: 'blur',
                },
              ]}},[_c('el-input',{model:{value:(_vm.formLabelAlign.prices),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "prices", $$v)},expression:"formLabelAlign.prices"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.question'),"prop":"question"}},[_c('el-input',{model:{value:(_vm.formLabelAlign.question),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "question", $$v)},expression:"formLabelAlign.question"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.SupplementaryInformation'),"prop":"textarea","rules":{
                required: true,
                message: _vm.$t('navbar.SupplementaryCannotEmpty'),
              }}},[_c('el-input',{staticStyle:{"margin-bottom":"140px"},attrs:{"type":"textarea","placeholder":"","autosize":{ minRows: 8, maxRows: 8 },"maxlength":"200"},model:{value:(_vm.formLabelAlign.textarea),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "textarea", $$v)},expression:"formLabelAlign.textarea"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.contacts'),"prop":"contact","rules":{
                required: true,
                message: _vm.$t('navbar.contactCannotEmpty'),
              }}},[_c('el-input',{model:{value:(_vm.formLabelAlign.contact),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "contact", $$v)},expression:"formLabelAlign.contact"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.companyName'),"prop":"company","rules":{
                required: true,
                message: _vm.$t('navbar.companyNameCannotEmpty'),
              }}},[_c('el-input',{model:{value:(_vm.formLabelAlign.company),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "company", $$v)},expression:"formLabelAlign.company"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.phone'),"prop":"phone","rules":[
                {
                  required: true,
                  trigger: 'blur',
                  message: _vm.$t('navbar.contactNumberCannotEmpty'),
                },
                {
                  type: _vm.number,
                  message: _vm.$t('navbar.contactNumberMustNumber'),
                  pattern:
                    '^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$',
                  trigger: 'blur',
                },
              ]}},[_c('el-input',{model:{value:(_vm.formLabelAlign.phone),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "phone", $$v)},expression:"formLabelAlign.phone"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.address'),"prop":"address","rules":{
                required: true,
                message: _vm.$t('navbar.addressCannotEmpty'),
              }}},[_c('el-input',{model:{value:(_vm.formLabelAlign.address),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "address", $$v)},expression:"formLabelAlign.address"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.faxes'),"prop":"faxes"}},[_c('el-input',{model:{value:(_vm.formLabelAlign.faxes),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "faxes", $$v)},expression:"formLabelAlign.faxes"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('navbar.EMAIL'),"prop":"email"}},[_c('el-input',{model:{value:(_vm.formLabelAlign.email),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "email", $$v)},expression:"formLabelAlign.email"}})],1),_c('el-form-item',[_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle",value:(2000),expression:"2000"}],staticClass:"btn",on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t("navbar.CommitConfirm")))])],1)],1)],1)])])]),_c('PcFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }