<template>
    <div class="contenter">
        <PcHeader></PcHeader>
        
        <div class="contenter-inner">
            <div class="back-box">
                <div class="back" @click="goBack"><i class="el-icon-arrow-left"></i></div>
            </div>
            <div class="Select-identity">
                请填写您的信息 / Please fill in your information
            </div>
            <div class="line"></div>

            <div class="formInfo_gys">
                <el-form ref="form" :model="formData" :rules="rules">
                    <el-form-item prop="lastName">
                        <el-input v-model="formData.lastName" placeholder="姓氏 / lastName" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="firstName">
                        <el-input v-model="formData.firstName" placeholder="名字 / name" />
                        <div class="star">*</div>
                    </el-form-item>

                    <el-form-item prop="sex">
                        <el-select v-model="formData.sex" placeholder="性别 / sex" @change="sexChange" :popper-append-to-body="false">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <div class="star">*</div>
                    </el-form-item>

                    <el-form-item prop="businessSector">
                        <el-select v-model="value2" multiple  placeholder="行业标签 / businessSector" @change="businessSectorChange">
                            <el-option v-for="item in optionsTag" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="country">
                        <el-select v-model="formData.country" placeholder="请选择国家 / Please select a country" @change="countryChange" :popper-append-to-body="false">
                            <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <div class="star">*</div>
                    </el-form-item>

                    <el-form-item prop="email">
                        <el-input v-model="formData.email" placeholder="邮箱 / email" />
                        <div class="star">*</div>
                    </el-form-item>
                    <el-form-item prop="phoneNumber">
                        <el-input v-model="formData.phoneNumber" placeholder="手机号码 / phoneNumber" />
                        <div class="star">*</div>
                    </el-form-item>

                    <el-form-item prop="icon" class="icon">
                        <div style="position: relative; padding-right: 90px;">
                            <el-input v-model="displayFileName" placeholder="头像图片 / profilePhoto" disabled style="width: 230px;" />
                            <el-upload :show-file-list="false" class="avatar-uploader" name="multipartFile" :auto-upload="true" :on-change="uploadChange" :action="upUrl">
                                <img v-if="formData.icon" :src="formData.icon" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <div class="upload-description" v-show="!formData.icon">
                                    <span>上传头像</span>
                                    <span>413px*579px</span>
                                </div>
                            </el-upload>
                        </div>
                        <div class="star">*</div>
                    </el-form-item>
                </el-form>
                <div class="select-identity__footer">
                    <button class="btn btn1" @click="submit">
                        提交
                    </button>
                </div>
            </div>

            <el-dialog ref="popup" :visible="dialogVisible" width="50%">
			<div class="icon-tick">
				<img src="../../../assets/images/icon-tick.png" />
			</div>
			<div class="popup-bg">
				<div class="popup-content">
					<div style="font-size: 32px; font-weight: 500; line-height: 1.2; margin-bottom: 10px;">提交成功</div>
					<div style="margin-bottom: 20px; font-size: 20px;">success</div>
					<div style="font-size: 16px; line-height: 1.2; margin-bottom: 10px;">
                        请耐心等待集团人事部面试邀约通知
                    </div>
                        <div style="font-size: 16px; line-height: 1.2;">
                            Please wait patiently for the group personnel department interview invitation notice
                        </div>
                    </div>
                    <div class="popup-content__footer">
                        <button class="btn btn1" @click="goHome">
                            返回首页<br><text>return to the homepage</text>
                        </button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import PcHeader from "../../../components/pc/Header.vue"
    import { specialistAddApi } from "../../../api/index"
    import countryName from "./countryName.json"
    export default {
        components: {
            PcHeader,
        },
        data() {
            return {
                value2:[],
                options: [
                    { value: '男 / M',label: '男 / M'}, 
                    { value: '女 / F',label: '女 / F'},
                    { value: '中性 / N',label: '中性 / N'},
                    { value: '偏男两性人 / IM',label: '偏男两性人 / IM'},
                    { value: '偏女两性人 / IF',label: '偏女两性人 / IF'},
                    { value: '不完全男性 / PM',label: '不完全男性 / PM'},
                    { value: '不完全女性 / PF',label: '不完全女性 / PF'}
                ],
                optionsTag: [
                    { value: '女装 / W',label: '女装 / W'}, 
                    { value: '男装 / M',label: '男装 / M'},
                    { value: '童装 / C',label: '童装 / C'},
                    { value: '运动装 / S',label: '运动装 / S'},
                    { value: '休闲装 / Casualwear',label: '休闲装 / Casualwear'},
                    { value: '职业装 / Workwear',label: '职业装 / Workwear'},
                    { value: '礼服 / Dress',label: '礼服 / Dress'},
                    { value: '内衣 / U',label: '内衣 / U'},
                    { value: '泳装 / Swimwear',label: '泳装 / Swimwear'},
                    { value: '家居服 / Loungewear',label: '家居服 / Loungewear'},
                    { value: '羽绒服 / Downwear',label: '羽绒服 / Downwear'},
                    { value: '牛仔装 / Jeanswear',label: '牛仔装 / Jeanswear'},
                    { value: '针织衫 / Knitwear',label: '针织衫 / Knitwear'},
                    { value: '风衣 / Trenchcoat',label: '风衣 / Trenchcoat'},
                    { value: '皮草 / Furwear',label: '皮草 / Furwear'}
                ],
                optionss: [],
                value: '',
                is260x390 : true,

                dialogVisible: false,
                selectedIndex: 0,
                upUrl: "/fabTank-ftfs/image/upload" + "?categoryNum=shop", //图片上传
                displayFileName: '',        //上传图片的名字  仅用于显示
                standardTime: '',       //中国标准时间
                formData: {
					lastName: '',		//姓
					firstName: '',		//名
					sex: '',			//性别
					businessSector: '',	//行业标签
					country: '',		//国家
					email: '',			//邮箱
					phoneNumber: '',	//手机号码
					icon: '',			//头像图片
					enable: 1,			//启用禁用   默认启用
				},
				rules: {
					lastName: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					firstName: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请选择',
						trigger: 'change',
					}],
					businessSector: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
                    country: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					email: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					phoneNumber: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					icon: [{
						required: true,
						message: '请上传头像',
						// trigger: 'blur'
					}],
				},
            }
        },
        watch: {
            
        },
        methods: {
            sexChange(e){
                // console.log('选择性别', e)
            },
            businessSectorChange(e){
                // console.log('选择行业标签', e)
                let str = e.join(',');
                // console.log('类型转换',str); 
                this.formData.businessSector = str
            },
            countryChange(e){
                // console.log('选择国家', e)
            },
            goHome() {
				this.$router.push('/')
			},

            uploadChange(e) {
				// console.log('uploadChange', e)
                if(e.response){
                    const reader = new FileReader();
                    reader.readAsDataURL(e.raw);
                    reader.onload = (theFile) => {
                        const image = new Image();
                        image.src = theFile.target.result;
                        image.onload = () => {
                            const { width, height } = image;
                            if(width !== 413 && height !== 579) {
                                this.$message.error("请上传标准二寸照 ( 413*579 px ) ！");
                                this.formData.icon = null
                            };
                        };
                    };
                    this.displayFileName = e.response.result.fileName;
				    this.formData.icon = e.response.result.image_url;
                }
			},


            //提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
                        this.getSupplierAdd()
					}
				})
			},
            //新增面料专家
            async getSupplierAdd() {
                let { data: res } = await specialistAddApi(this.formData)
                // console.log('新增面料专家--res',res)
                if(res && res.success==true){
                    this.dialogVisible = true;
                }else{
                    this.$message({
						message: '提交失败，请联系客服',
						type: 'warning'
					});
                }
            },
            goBack() {
                this.$router.back()
            },
        },
        created() {
            this.optionss = countryName
        },
        mounted() {
            
        },
    }
</script>

<style lang="scss" scoped>
    .contenter{
        width: 100%;
    }
    .contenter-inner{
        width: 1200px;
        margin: 0 auto;

        .back-box{
            width: 100%;
            height: 40px;
            line-height: 40px;
            margin-left: -50px;
            .back {
                width: 80px;
                height: 30px;
                background-color: rgb(241, 241, 241);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 700;
                margin: 20px 0 -5px 0;
                padding: 5px 0;
            }
        }

        .Select-identity{
            font-family: Source Han Sans;
            font-size: 24px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #000000;
            margin: 34px 0 14px 0;
        }
        .line{
            height: 1px;
            opacity: 1;
            background: #D8D8D8;
        }
        .select-identity__content{
            width: 100%;
            height: 110px;
            margin-top: 30px;
            display: flex;
            // background-color: aqua;
            .select-item{
                width: 260px;
                height: 110px;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 32px;
                cursor: pointer;
                img{
                    width: 60px;
                    margin-right: 30px;
                    height: 60px;
                }
            }
            .select-item.selected {
                background: linear-gradient(90deg, #FF235A 0%, #FB6969 100%);
            }
        }
        .formInfo_gys{
            width: 480px;
            margin: 30px 0;
            /deep/.el-input__inner{
                width: 480px;
                height: 56px;
                border-radius: 10px;
                padding-left: 44px;
                font-size: 18px;
            }
            /deep/.el-form-item {
                margin-bottom: 36px;
                .el-select{
                    width: 480px;
                    margin-left: 0;
                }
            }
            /deep/.el-form-item__error {
                font-size: 18px;
            }
            /deep/.el-form-item {
                position: relative;
                .star {
                    position: absolute;
                    left: 20px;
                    top: 17px;
                    color: #FA5151;
                    font-weight: bolder;
                    font-size: 30px;
                }
                .el-input__content {
                    padding-left: 40px;
                }
                .el-input__content-input{
                    font-size: 30px;
                    height: 80px;
                }
                // .is-input-border {
                //     border-radius: 10px;
                //     background: rgba(255, 255, 255, 0.5) !important;
                //     border: 2px solid #FFA9A9 !important;
                // }
                // .el-input__placeholder-class {
                //     font-size: 30px;
                //     color: rgba(0, 0, 0, 0.6);
                // }
                .add-file {
                    width: 80px;
                    height: 80px;
                    border-radius: 6px;
                    // background: #FEFEFE;
                    // box-sizing: border-box;
                    // border: 2px solid #FFA9A9;
                    margin-left: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            /deep/.licencePhoto .el-input__inner{
                width: 460px;
            }
            /deep/.expirationTime .el-input__icon{
                width: 460px;
                text-align: end;
                line-height: 56px;
            }
            /deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
                width: 380px;
            }
            .upload-description{
                width: calc(413px * 0.35);
                position: absolute;
                bottom: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                opacity: 0.7;
            }
            .avatar-uploader-icon{
                font-size: 1.75rem;
                color: #8c939d;
                width: calc(413px * 0.35);
                height: calc(579px * 0.35);
                line-height: 120px;
                text-align: center;
                border: .0625rem solid #d3d3d3;
                border-radius: .625rem;
                box-sizing: border-box;
                // margin-left: 20px;
                margin-top: 10px;
            }
            .select-identity__footer{
                width: 100%;
                height: 64px;
                // background-color: aqua;
                .btn1{
                    width: 100%;
                    height: 100%;
                    background-color: #FF235A;
                    color: #FFFFFF;
                    font-size: 24px;
                    border: none;
                    border-radius: 10px;
                }

            }
        }
        
        /deep/.el-icon-close:before{
            display: none;
        }
        .popup-bg {
            background-color: white;
            border-radius: 16px;
            width: 40vw;
            height: 50vh;
            margin: 0 auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 50px;
        }
        .popup-content {
            background: linear-gradient(180deg, rgba(255, 107, 107, 0.5) 0%, rgba(255, 255, 255, 0) 64%);
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 36px;
            padding-top: 60px;
            box-sizing: border-box;
            position: relative;
            &__footer {
                flex: 0 0 120px;
                height: 120px;
                padding: 36px;
                .btn{
                    height: 80px;
                    padding: 16px 100px;
                    border-radius: 16px;
                    border: none;
                    font-size: 26px;
                    margin-bottom: 30px;
                    line-height: 1.5;
                    box-sizing: border-box;
                    width: 100%;
                }
                .btn1 {
                    background: linear-gradient(90deg, #FF235A 0%, #FB6969 100%);
                    box-shadow: 0px 5px 20px 0px rgba(251, 105, 105, 0.3);
                    color: #FFFFFF;
                }
                .btn2 {
                    background-color: white;
                    border: 2px solid #D2D2D2;
                    color: rgba(0, 0, 0, 0.7);
                }
            }
        }
        .icon-tick {
            width: 130px;
            height: 130px;
            background: linear-gradient(318deg, #FB6969 15%, #FF245A 85%);
            border-radius: 65px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -65px;
            z-index: 3;
            left: 50%;
            margin-left: -65px;
            img {
                width: 75px;
                height: 60px;
            }
        }
        .avatar-uploader {
            width: calc(413px * 0.35);
            height: calc(579px * 0.35);
            // overflow: hidden;
            border-radius: 10px;
            margin-bottom: 15px;
            
            img{
                margin-top: 10px;
                width: 100%;
                max-height: 205px;
                border-radius: 10px;
            }
        }
        /deep/.el-dialog {
            border-radius: 10px;
        }
        /deep/.el-input__suffix{
            right: 0;
        }

        /deep/.el-input--suffix{
            width: 458px;
        }
        /deep/.el-select-dropdown{
            position: absolute;
            left: 0 !important;
        }
        /deep/.el-select-dropdown__list{
            width: 478px;
            margin-left: 0;
            padding-left: 0;
        }
        /deep/.el-select-dropdown__item{
            font-size: 20px;
            height: 50px;
            line-height: 50px;
        }
        /deep/.el-select__tags{
            width: 380px !important;
            margin-left: 35px;
            flex-wrap: nowrap;
            overflow: hidden;
        }
        /deep/.el-tag--small{
            height: 35px;
            line-height: 35px;
        }
        /deep/.el-tag{
            font-size: 16px;
        }
    }
</style>

<style lang="scss">
	.el-date-picker {
    	width: 460px;
	}
	.el-date-picker .el-picker-panel__content {
		width: 450px;
	}
	.el-picker-panel__icon-btn {
		font-size: 16px;
        margin-right: 20px;
        margin-left: 20px;
	}
	.el-date-table{
		font-size: 16px;
	}
    .is-multiple{
        width: 478px;
        margin-left: 0;
        padding-left: 0;
        .el-select-dropdown__item{
            font-size: 20px;
            height: 50px;
            line-height: 50px;
        }
    }
</style>