// import Vue from 'vue'
// import App from './App.vue'
// import router from '@/router/pc'
// import store from './store'
// // 浏览器视口小于900px时，使用mobile路由
// import '@/utils/autoSwitch'
// import '@/utils/rem'
// // 按需引入element-ui
// import './plugins/element-ui'
// // 引入全局样式
// import '@/styles/css-p/index.scss'
// Vue.config.productionTip = false

// // eslint-disable-next-line no-new
// new Vue({
//   store,
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })

import Vue from 'vue'
import Thing from 'thingjs';
import App from './App.vue'
import router from '@/router/pc'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// 国际化
// import i18n from './i18n'
import { i18n } from './i18n/index.js';
import Cookies from 'js-cookie'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// import Element from 'element-ui';

import Lang from './components/pc/Lang/index.vue'
// 浏览器视口小于900px时，使用mobile路由
import '@/utils/autoSwitch'
import '@/utils/rem'
// 按需引入element-ui
import './plugins/element-ui'
// 引入全局样式
// import '@/styles/css-p/index.scss'
import * as echarts from 'echarts'

import "swiper/swiper-bundle.css";
import { Swiper, Navigation, Pagination, Autoplay } from "swiper";

import locale from 'element-ui/lib/locale/lang/en'

// 点击防抖
const throttle = {
    bind: (el, binding) => {
        let throttleTime = binding.value // 防抖时间
        if (!throttleTime) { // 用户若不设置防抖时间，则默认1s
            throttleTime = 1000
        }
        let timer
        let disable = false
        el.addEventListener('click', event => {
            if (timer) {
                clearTimeout(timer)
            }
            if (!disable) { // 第一次执行(一点击触发当前事件)
                disable = true
            } else {
                event && event.stopImmediatePropagation()
            }
            timer = setTimeout(() => {
                timer = null
                disable = false
            }, throttleTime)
        }, true)
    }
}
Vue.directive('throttle', throttle);

Vue.component('Lang', Lang)
Vue.component('thing-component', Thing);

Vue.prototype.$echarts = echarts

Vue.prototype.$EventBus = new Vue()
Vue.prototype.$axios = axios
Vue.config.productionTip = false

Swiper.use([Navigation, Pagination, Autoplay])

Vue.use(VueAxios, axios)
// Vue.use(ElementUI);

Vue.use(ElementUI, {
    locale,
    size: Cookies.get('size') || 'medium',  // set element-ui default size
    i18n: (key, value) => i18n.t(key, value)
})

// eslint-disable-next-line no-new
new Vue({
    i18n,
    store,
    el: '#app',
    router,
    components: { App },
    template: '<App/>'
})

import listJson from "../public/marketplace.json"
// /* 读取外部配置文件 */
Vue.prototype.$config = listJson
