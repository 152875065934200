<template>
    <div>
        <el-select v-model="languageValue" @change="changeLanguage" placeholder="请选择">
            <el-option v-for="item in languageOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    data() {
        return {
            languageValue: '',
            languageOptions: [
                { value: 'zh', label: '中国' },
                { value: 'en', label: '欧洲' },
                { value: 'en2', label: '亚洲' }
            ],
        }
    },
    created() {
        //最开始请求的时候看缓存是什么状态
        if (this.$i18n.locale == 'zh') {
            this.languageValue = 'zh';
        } else if(this.$i18n.locale == 'en'){
            this.languageValue = 'en';
        } else if(this.$i18n.locale == 'en2'){
            this.languageValue = 'en2';
        }
        // console.log('加载语言', localStorage.getItem('locale'));
        this.$EventBus.$emit('this.languageValue', this.languageValue);
    },
    methods: {
        // 多语言切换
        changeLanguage(type) {
            console.log(type);
            // 此处做了语言选择记录，存在localStorage中，这里的作用只有一个当我重新请求页面
            //的时候先取localStorage的记录值
            localStorage.setItem('locale', type)
            this.$i18n.locale = type; // 修改页面需要显示的语言
            if (this.$i18n.locale == 'zh') {
                this.languageValue = 'zh';
                this.$EventBus.$emit('this.languageValue', this.languageValue);
            } else {
                this.languageValue = 'en';
                this.$EventBus.$emit('this.languageValue', this.languageValue);
            }
            this.$router.go(0)
        },
    }
}
</script>
<style lang="scss">
    .el-select{
        width: 120px;
        margin-left: 20px;
    }
</style>
