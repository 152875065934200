<template>
    <div class="container">
        <PcHeader></PcHeader>
        <div class="container-inner">
            求购信息
            <!-- <div style="display: flex;justify-content: space-between;">
                <leftTop></leftTop>
                <rightTop></rightTop>
            </div>
            <div>
                <leftMin></leftMin>
            </div> -->
        </div>
        <PcFooter></PcFooter>
    </div>
</template>

<script>
import PcHeader from "../../../components/pc/Header.vue"
import PcFooter from "../../../components/pc/Footer.vue"

// import leftTop from "../../../components/pc/Echarts/leftTop.vue"
// import leftMin from "../../../components/pc/Echarts/leftMin.vue"
// import rightTop from "../../../components/pc/Echarts/rightTop.vue"

export default {
    components: {
        PcHeader,
        PcFooter,
        // leftTop,
        // leftMin,
        // rightTop,
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 1000px;
    // background-color: aqua;
    .container-inner{
        width: 1140px;
        margin: auto;
    }
}
</style>