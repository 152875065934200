<template>
    <!-- 店铺入驻页面 -->
    <div class="shopEntry-container">
        <div class="shopEntry-inner">
            <div class="content">
                <h1>店铺入驻</h1>
                <!-- 第一步:定义出4个步骤 -->
                <el-steps :active="active" finish-status="success">
                    <el-step title="企业资质信息" />
                    <el-step title="财务资质信息" />
                    <el-step title="其他信息" />
                    <el-step title="提交审核" />
                </el-steps>

                <!-- 第二步:定义form表单 -->
                <el-form ref="shopEntryForm" :model="shopEntryForm" label-position="right" label-width="140px"
                    :rules="rules">
                    <!-- 第三步:定义4个盒子对象active => 1 到 4 -->
                    <div v-show="active == 1">
                        <!-- 第四步:放置表单项 -->
                        <div class="basic-information">
                            <h3>基础信息</h3>
                        </div>
                        <el-form-item label="公司名称" prop="companyName">
                            <el-input v-model="shopEntryForm.companyName" placeholder="请填写公司信息"></el-input>
                        </el-form-item>
                        <el-form-item label="公司所在地" prop="companyAddressPath">
                            <!-- <el-input v-model="shopEntryForm.companyAddressPath" placeholder="请填写公司所在地"></el-input> -->
                            <el-cascader size="large" :options="pcaTextArr" v-model="shopEntryForm.companyAddressPath"
                                placeholder="请选择公司所在地">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="公司详细地址" prop="companyAddress">
                            <el-input v-model="shopEntryForm.companyAddress" placeholder="请填写公司详细地址"></el-input>
                        </el-form-item>
                        <el-form-item label="员工总数" prop="employeeNum">
                            <el-input v-model="shopEntryForm.employeeNum" placeholder="请填写公司员工总数">
                                <template slot="append">人</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="公司电话" prop="companyPhone">
                            <el-input v-model="shopEntryForm.companyPhone" placeholder="请填写公司电话"></el-input>
                        </el-form-item>
                        <el-form-item label="注册资金" prop="registeredCapital">
                            <el-input v-model="shopEntryForm.registeredCapital" placeholder="请填写公司注册资金">
                                <template slot="append">万元</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="联系人姓名" prop="linkName">
                            <el-input v-model="shopEntryForm.linkName" placeholder="请填写联系人姓名"></el-input>
                        </el-form-item>
                        <!-- { required: true, message: '请填写联系人电话' } -->
                        <el-form-item label="联系人电话" prop="linkPhone">
                            <el-input v-model="shopEntryForm.linkPhone" placeholder="请填写联系人电话"></el-input>
                        </el-form-item>
                        <el-form-item label="电子邮箱" prop="companyEmail">
                            <el-input v-model="shopEntryForm.companyEmail" placeholder="请填写电子邮箱"></el-input>
                        </el-form-item>

                        <div class="basic-information">
                            <h3>营业执照信息</h3>
                        </div>
                        <el-form-item label="营业执照号" prop="licenseNum">
                            <el-input v-model="shopEntryForm.licenseNum" placeholder="请填写营业执照号"></el-input>
                        </el-form-item>
                        <el-form-item label="法定经营范围" prop="scope">
                            <el-input type="textarea" placeholder="请输入营业执照所示经营范围" :autosize="{ minRows: 5, maxRows: 5 }"
                                v-model="shopEntryForm.scope" maxlength="200" show-word-limit>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="营业执照电子版" prop="licencePhoto" ref="licencePhotoImage">
                            <p>图片格式为gif，jpg，png，请确保文字清晰,以免上传或审核失败</p>
                            <!-- v-model="shopEntryForm.licencePhoto"   :before-upload="uptp"-->
                            <el-upload v-if="storeId" name="multipartFile" :action="upUrl" list-type="picture-card"
                                :class="fileList.length ? 'hide_box' : ''" :on-exceed="handleUploadExceed"
                                :on-success="imgSuccess" :on-progress="uptp" :on-error="imgError"
                                :on-preview="handlePictureCardPreview" :on-remove="handleRemoveLicencePhoto"
                                :before-upload="beforeAvatarUpload" :limit="1" ref="uploadLicencePhotoImage"
                                :file-list="fileList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-upload v-else name="multipartFile" :action="upUrl" list-type="picture-card"
                                :class="listLength ? 'hide_box' : ''" :on-exceed="handleUploadExceed"
                                :on-success="imgSuccess" :on-progress="uptp" :on-error="imgError"
                                :on-preview="handlePictureCardPreview" :on-remove="handleRemoveLicencePhoto"
                                :before-upload="beforeAvatarUpload" :limit="1" ref="uploadLicencePhotoImage"
                                :file-list="fileList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <!-- :append-to-body="true" -->

                            <el-dialog :visible.sync="dialogVisible2" :modal-append-to-body="false">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>

                        <div class="basic-information">
                            <h3>法人信息</h3>
                        </div>
                        <el-form-item label="法人姓名" prop="legalName">
                            <el-input v-model="shopEntryForm.legalName" placeholder="请输入法人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="法人证件号" prop="legalId">
                            <el-input v-model="shopEntryForm.legalId" placeholder="请输入法人证件号"></el-input>
                        </el-form-item>
                        <el-form-item label="法人证件电子版" prop="legalPhoto" ref="legalPhotoImage">
                            <p>请上传身份证正反面两张照片,确保图片清晰无缺角</p>
                            <el-upload v-if="storeId" name="multipartFile" :action="upUrl" list-type="picture-card"
                                :class="imageList.length === 2 ? 'hide_box' : ''" :on-success="imgSuccesslegalPhoto"
                                :on-progress="uptp" :on-error="imgError" :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemoveLegalPhoto" :on-exceed="handleUploadExceed"
                                :before-upload="beforeAvatarUpload" :limit="2" ref="uploadLegalPhotoImage"
                                :file-list="imageList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-upload v-else name="multipartFile" :action="upUrl" list-type="picture-card"
                                :class="listLength2 === 2 ? 'hide_box' : ''" :on-success="imgSuccesslegalPhoto"
                                :on-progress="uptp" :on-error="imgError" :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemoveLegalPhoto" :on-exceed="handleUploadExceed"
                                :before-upload="beforeAvatarUpload" :limit="2" ref="uploadLegalPhotoImage"
                                :file-list="imageList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible2" :modal-append-to-body="false">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </div>
                    <div v-show="active == 2">
                        <div class="basic-information">
                            <h3>基础信息</h3>
                        </div>
                        <el-form-item label="银行开户名" prop="settlementBankAccountName">
                            <el-input v-model="shopEntryForm.settlementBankAccountName" placeholder="请填写银行开户名称"></el-input>
                        </el-form-item>
                        <el-form-item label="银行账号" prop="settlementBankAccountNum">
                            <el-input v-model="shopEntryForm.settlementBankAccountNum" placeholder="请填写银行账号"></el-input>
                        </el-form-item>
                        <el-form-item label="开户银行支行名称" prop="settlementBankBranchName">
                            <el-input v-model="shopEntryForm.settlementBankBranchName" placeholder="请填写开户银行支行名称"></el-input>
                        </el-form-item>
                        <el-form-item label="支行联行号" prop="settlementBankJointName">
                            <el-input v-model="shopEntryForm.settlementBankJointName" placeholder="请填写支行联行号"></el-input>
                        </el-form-item>
                    </div>
                    <div v-show="active == 3">
                        <div class="basic-information">
                            <h3>基础信息</h3>
                        </div>
                        <el-form-item label="店铺名称" prop="storeName">
                            <el-input v-model="shopEntryForm.storeName" placeholder="请填写店铺名称"></el-input>
                        </el-form-item>
                        <el-form-item label="店铺logo" prop="storeLogo" ref="storeLogoImage">
                            <p>图片格式为gif，jpg，png</p>
                            <el-upload v-if="storeId" name="multipartFile" :action="upUrl" list-type="picture-card"
                                :class="fileListLogo.length ? 'hide_box' : ''" :on-success="imgSuccessStoreLogo"
                                :on-progress="uptp" :on-error="imgError" :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemoveStoreLogo" :on-exceed="handleUploadExceed"
                                :before-upload="beforeAvatarUpload" :limit="1" ref="uploadStoreLogoImage"
                                :file-list="fileListLogo">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-upload v-else name="multipartFile" :action="upUrl" list-type="picture-card"
                                :class="listLength3 === 1 ? 'hide_box' : ''" :on-success="imgSuccessStoreLogo"
                                :on-progress="uptp" :on-error="imgError" :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemoveStoreLogo" :on-exceed="handleUploadExceed"
                                :before-upload="beforeAvatarUpload" :limit="1" ref="uploadStoreLogoImage"
                                :file-list="fileListLogo">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible2" :modal-append-to-body="false">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                        <!-- <el-form-item label="店铺经营类目" prop="commodityManagementCateqory">
                            <el-input v-model="shopEntryForm.commodityManagementCateqory"
                                placeholder="请填写店铺经营类目"></el-input>
                        </el-form-item> -->
                        <el-form-item label="店铺所在地" prop="storeAddressPath">
                            <!-- <el-input v-model="shopEntryForm.storeAddressPath" placeholder="请填写店铺所在地"></el-input> -->
                            <el-cascader size="large" :options="pcaTextArr" v-model="shopEntryForm.storeAddressPath"
                                placeholder="请选择店铺所在地">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="店铺详细地址" prop="storeAddressDetail">
                            <el-input v-model="shopEntryForm.storeAddressDetail" placeholder="请输入店铺详细地址"></el-input>
                        </el-form-item>
                        <el-form-item label="店铺简介" prop="storeDesc">
                            <el-input type="textarea" placeholder="请输入店铺简介" :autosize="{ minRows: 5, maxRows: 5 }"
                                v-model="shopEntryForm.storeDesc" maxlength="200" show-word-limit>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div v-show="active == 4">
                        <span v-show="this.storeDisable == 'APPLY'" class="hint">入驻申请提交成功，等待平台审核</span>
                        <span v-show="this.storeDisable == 'REFUSED'" class="hint">入驻申请提交成功，平台审核未通过</span>
                        <span v-show="this.storeDisable == 'OPEN'" class="hint">平台审核已通过，如有问题请联系管理员</span>
                        <!-- 审核未通过,请修改资质信息，如有疑问请联系管理员 -->
                    </div>
                </el-form>

                <!-- 第五步:设置上一步和下一步的按钮 -->
                <el-button v-if="active > 1" style="margin: 30px 0 20px 50px" @click="pre">返回</el-button>
                <el-button v-show="active == 1" v-if="active < 4" class="buttons" @click="gocwzx">填写财务资质信息</el-button>
                <el-button v-show="active == 2" v-if="active < 4" class="buttons" @click="goqtxx">填写其他信息</el-button>
                <el-button v-show="active == 3" v-if="active < 4" class="buttons" @click="subform">提交平台审核</el-button>
                <el-button v-show="this.storeDisable == 'REFUSED' && active == 4" class="buttons"
                    @click="reapply">重新申请</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { storeAdd, storeDetail } from "../../../api/index";
import { pcaTextArr } from 'element-china-area-data';
// import "@wangeditor/editor/dist/css/style.css";
// import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
            // 商家入驻
            pcaTextArr,
            listLength: 0,
            listLength2: 0,
            listLength3: 0,
            active: 1,
            shopEntryForm: {
                memberId: 1,             //会员ID
                companyName: '',         //公司名称
                companyAddressPath: [],  //公司所在地
                companyAddress: '',      //公司详细地址
                employeeNum: '',         //员工总数
                companyPhone: '',        //公司电话
                registeredCapital: '',   //注册资金
                linkName: '',            //联系人姓名
                linkPhone: '',           //联系人电话
                companyEmail: '',        //电子邮箱

                licenseNum: '',          //营业执照号
                scope: '',               //法定经营范围
                licencePhoto: '',        //营业执照电子版

                legalName: '',           //法人姓名
                legalId: '',             //法人证件号
                legalPhoto: [],          //法人证件电子版

                settlementBankAccountName: '',      //银行开户名
                settlementBankAccountNum: '',       //银行账号
                settlementBankBranchName: '',       //开户银行支行名称
                settlementBankJointName: '',        //支行联行号

                storeName: '',                       //店铺名称
                storeLogo: '',                       //店铺logo
                // commodityManagementCateqory: '',     //店铺经营类目
                storeAddressPath: [],                //店铺所在地
                storeAddressDetail: '',              //店铺详细地址
                storeDesc: '',                       //店铺简介
            },
            dialogImageUrl: '',
            dialogVisible2: false,
            upUrl: "/fabTank-ftfs/image/picture" + "?categoryNum=shop",      //图片上传后面搞 http://196.192.168.164:9091
            fileList: [],
            imageList: [],
            imageList2: [],
            fileListLogo: [],
            StoreDetailLegalPhoto: [],

            //正则验证
            rules: {
                companyName: [{
                    required: true,
                    message: '请填写公司信息'
                },
                {
                    min: 2,
                    max: 100,
                    message: ' 公司名称错误'
                }],
                companyAddressPath: [{
                    required: true,
                    message: '请选择公司所在地'
                }],
                companyAddress: [{
                    required: true,
                    message: '请填写公司详细地址'
                },
                {
                    min: 1,
                    max: 200,
                    message: ' 公司地址,长度为1-200字符'
                }],
                employeeNum: [{
                    required: true,
                    message: '请填写公司员工总数'
                }],
                companyPhone: [{
                    required: true,
                    message: '请填写公司电话'
                }],
                registeredCapital: [{
                    required: true,
                    message: '请填写公司注册资金'
                }],
                linkName: [{
                    required: true,
                    message: '请填写联系人姓名'
                }],
                linkPhone: [{
                    required: true,
                    message: '请填写联系人电话',
                    trigger: "blur"
                },
                {
                    pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                    message: "手机号格式有误"
                }],
                companyEmail: [{
                    required: true,
                    message: '请填写电子邮箱'
                }],
                licenseNum: [{
                    required: true,
                    message: '请填写营业执照号'
                },
                {
                    min: 18,
                    max: 18,
                    message: "营业执照长度为18位字符"
                }],
                scope: [{
                    required: true,
                    message: '请填写营业执照所示经营范围'
                }],
                licencePhoto: [{
                    required: true,
                    message: '请上传营业执照',
                    trigger: 'change'
                }],
                legalName: [{
                    required: true,
                    message: '请输入法人姓名'
                },
                {
                    min: 2,
                    max: 20,
                    message: "法人姓名长度为2-20位字符"
                }],
                legalId: [{
                    required: true,
                    message: '请输入法人证件号'
                },
                {
                    min: 18,
                    max: 18,
                    message: "法人身份证号长度为18位"
                }],
                legalPhoto: [{
                    required: true,
                    message: '请上传法人身份证照片',
                    trigger: 'change'
                }],
                settlementBankAccountName: [{
                    required: true,
                    message: '请填写银行开户名称'
                },
                {
                    min: 1,
                    max: 200,
                    message: "结算银行开户行名称长度为1-200位"
                }],
                settlementBankAccountNum: [{
                    required: true,
                    message: '请填写银行账号'
                },
                {
                    min: 1,
                    max: 200,
                    message: "结算银行开户账号长度为1-200位"
                }],
                settlementBankBranchName: [{
                    required: true,
                    message: '请填写开户银行支行名称'
                },
                {
                    min: 1,
                    max: 200,
                    message: "结算银行开户支行名称长度为1-200位"
                }],
                settlementBankJointName: [{
                    required: true,
                    message: '请填写支行联行号'
                },
                {
                    min: 1,
                    max: 200,
                    message: "结算银行支行联行号长度为1-200位"
                }],
                storeName: [{
                    required: true,
                    message: '请填写店铺名称'
                },
                {
                    min: 2,
                    max: 200,
                    message: ' 店铺名称长度为2-200位'
                }],
                storeLogo: [{
                    required: true,
                    message: '请上传店铺logo',
                    trigger: 'change'
                }],
                // commodityManagementCateqory: [{
                //     required: true,
                //     message: '请填写店铺经营类目'
                // }],
                storeAddressPath: [{
                    required: true,
                    message: '请选择店铺所在地'
                }],
                storeAddressDetail: [{
                    required: true,
                    message: '请输入店铺详细地址'
                }],
                storeDesc: [{
                    required: true,
                    message: '请填写店铺简介'
                },
                {
                    min: 6,
                    max: 200,
                    message: '店铺简介需在6-200字符之间'
                }]
            },

            storeId: '',           //通过添加店铺获取店铺id 然后用id查询店铺详情 获取状态码
            storeDisable: 'APPLY',       //判断审核的状态    默认待审核
        }
    },
    watch: {

    },
    computed: {
        // imageList() {
        //     const res = []
        //     if (this.value) {
        //         const imageArray = this.value.split(',')
        //         for (let i = 0; i < imageArray.length; i++) {
        //             res.push({ url: this.resourcesUrl + imageArray[i], response: imageArray[i] })
        //         }
        //     }
        //     this.$emit('input', this.value)
        //     return res
        // }
    },
    methods: {
        // ...mapMutations(['setStoreId']),
        //申请未通过，点击重新申请
        reapply() {
            this.active = 1
            // if (localStorage.getItem("storeId")) {
            //     window.localStorage.removeItem('storeId');
            // }
        },
        //去填写财务资质信息
        gocwzx() {
            if (!this.shopEntryForm.companyName || !this.shopEntryForm.companyAddressPath || !this.shopEntryForm.companyAddress
                || !this.shopEntryForm.employeeNum || !this.shopEntryForm.companyPhone || !this.shopEntryForm.registeredCapital
                || !this.shopEntryForm.linkName || !this.shopEntryForm.linkPhone || !this.shopEntryForm.companyEmail
                || !this.shopEntryForm.licenseNum || !this.shopEntryForm.scope || !this.shopEntryForm.licencePhoto
                || !this.shopEntryForm.legalName || !this.shopEntryForm.legalId || this.shopEntryForm.legalPhoto.length !== 2 || this.imageList.length !== 2) {
                this.$refs.shopEntryForm.validateField("companyName", errMsg => { });
                this.$refs.shopEntryForm.validateField("companyAddressPath", errMsg => { });
                this.$refs.shopEntryForm.validateField("companyAddress", errMsg => { });
                this.$refs.shopEntryForm.validateField("employeeNum", errMsg => { });
                this.$refs.shopEntryForm.validateField("companyPhone", errMsg => { });
                this.$refs.shopEntryForm.validateField("registeredCapital", errMsg => { });
                this.$refs.shopEntryForm.validateField("linkName", errMsg => { });
                this.$refs.shopEntryForm.validateField("linkPhone", errMsg => { });
                this.$refs.shopEntryForm.validateField("companyEmail", errMsg => { });
                this.$refs.shopEntryForm.validateField("licenseNum", errMsg => { });
                this.$refs.shopEntryForm.validateField("scope", errMsg => { });
                this.$refs.shopEntryForm.validateField("licencePhoto", errMsg => { });
                this.$refs.shopEntryForm.validateField("legalName", errMsg => { });
                this.$refs.shopEntryForm.validateField("legalId", errMsg => { });
                this.$refs.shopEntryForm.validateField("legalPhoto", errMsg => {
                    this.$message({
                        showClose: true,
                        message: '请上传身份证正反两张证件照',
                        type: 'warning'
                    });
                });
            } else {
                this.next()
            }
        },
        //去填写其他信息
        goqtxx() {
            if (!this.shopEntryForm.settlementBankAccountName || !this.shopEntryForm.settlementBankAccountNum
                || !this.shopEntryForm.settlementBankBranchName || !this.shopEntryForm.settlementBankJointName) {
                this.$refs.shopEntryForm.validateField("settlementBankAccountName", errMsg => { });
                this.$refs.shopEntryForm.validateField("settlementBankAccountNum", errMsg => { });
                this.$refs.shopEntryForm.validateField("settlementBankBranchName", errMsg => { });
                this.$refs.shopEntryForm.validateField("settlementBankJointName", errMsg => { });
            } else {
                this.next()
            }
        },
        async getStoreAdd() {
            let subform = await storeAdd(this.shopEntryForm)
            console.log(subform)
            this.storeId = subform.data.result.storeId
            console.log('店铺id', this.storeId)
            //提交成功后
            if (subform.status === 200) {
                //将数据存储到本地
                window.localStorage.setItem(
                    "storeId",
                    JSON.stringify(this.storeId)
                );
                //将数据存储到vuex
                // this.setStoreId(subform.data.result.storeId)
            };

        },
        //提交表单  提交平台审核
        subform() {
            // console.log("提交审核！！！");
            this.$refs.shopEntryForm.validate((valid) => {
                if (valid) {
                    // alert('提交成功!');
                    this.$message({
                        message: '提交成功!',
                        // offset: 300,
                        type: 'success'
                    });
                    this.getStoreAdd()
                    this.storeDisable = 'APPLY'
                    // this.$refs.shopEntryForm.resetFields()
                    // this.$refs.uploadLicencePhotoImage.clearFiles()
                    // this.$refs.uploadLegalPhotoImage.clearFiles()
                    // this.$refs.uploadStoreLogoImage.clearFiles()
                    this.next()
                } else {
                    // console.log('提交失败!!');
                    this.$message.error('提交失败!!');
                    return false;
                }
            });
        },
        // 商家入驻步骤条下一步的方法
        next() {
            if (this.active++ > 3) this.active = 1
        },
        // 商家入驻步骤条上一步的方法
        pre() {
            if (this.active-- < 2) this.active = 1
            if (this.active == 3) {
                // location.reload()
                this.$router.push('/home')
                // window.localStorage.removeItem('storeId');
            }
        },
        //商家入驻图片预览
        handlePictureCardPreview(file) {
            console.log('商家入驻图片预览', file)
            this.dialogImageUrl = file.url;
            this.dialogVisible2 = true;
        },
        handleUploadExceed(files, fileList) {
            // this.loading = false
            this.$message.warning(`对不起，最多上传个` + fileList.length + `图片`)
        },
        // 营业执照电子版 上传成功
        imgSuccess(res, file, fileList) {
            // this.fileList = fileList
            // console.log('cccccc', res)
            // console.log('dddddd', file)
            // console.log('eeeeee', fileList)
            // console.log('ffrfff', this.fileList)
            if (this.storeId) {
                this.fileList = fileList
            } else {
                this.listLength = fileList.length
            }

            this.shopEntryForm.licencePhoto = res.result.image_url
            this.$refs.licencePhotoImage.clearValidate()
            // console.log('hhhhhhh', this.shopEntryForm.licencePhoto);
            // this.getUploadMultipartFile()
        },
        handleRemoveLicencePhoto(file, fileList) {
            console.log(file, fileList);
            // this.listLength = fileList.length
            if (this.storeId) {
                this.fileList = fileList
            } else {
                this.listLength = fileList.length
            }
        },
        // 法人证件电子版  上传成功   legalPhoto: [],  //法人证件电子版
        imgSuccesslegalPhoto(res, file, imageList) {
            // this.imageList = imageList
            // console.log('cccccc', res)
            // console.log('dddddd', file)
            // console.log('eeeeee', imageList)
            // this.listLength2 = imageList.length
            if (this.storeId) {
                this.imageList = imageList
                // this.imageList.push(this.imageList2)
                // this.imageList = imageList.length
                console.log('有storeId', this.imageList);
                const pics = imageList.map(file => {
                    if ((typeof file.response) === 'object') {
                        return file.response.result.image_url
                    } else {
                        return file.response
                    }
                })
                // console.log('kkkkkkk', pics)
                this.$emit('input', pics)
                if (this.imageList2[0]) {
                    console.log(this.imageList2[0].url)
                    pics.unshift(this.imageList2[0].url)
                    pics.splice(1, 1)
                    this.shopEntryForm.legalPhoto = pics
                } else {
                    this.shopEntryForm.legalPhoto = pics
                }
                this.$refs.legalPhotoImage.clearValidate()
                console.log('kkkkkkk', this.shopEntryForm.legalPhoto);
            } else {
                this.imageList = imageList
                this.listLength2 = imageList.length
                // if(this.listLength2 !== 2){
                //     this.$message.error('请上传身份证正反两张证件照')
                // }
                const pics = imageList.map(file => {
                    if ((typeof file.response) === 'object') {
                        return file.response.result.image_url
                    } else {
                        return file.response
                    }
                })
                // console.log('kkkkkkk', pics)
                this.$emit('input', pics)
                this.shopEntryForm.legalPhoto = pics
                this.$refs.legalPhotoImage.clearValidate()
                console.log('kkkkkkk', this.shopEntryForm.legalPhoto);
            }

        },
        handleRemoveLegalPhoto(file, imageList) {
            // this.listLength2 = imageList.length
            // console.log('oooooooooo',imageList);
            if (this.storeId) {
                this.imageList = []
                this.imageList = imageList
                this.imageList.length = imageList.length
                this.imageList2 = imageList
                // this.$message({
                //     showClose: true,
                //     message: '请重新上传身份证正反两张证件照',
                //     type: 'warning'
                // });
                console.log('oooooooooo', imageList);
            } else {
                // this.imageList = []
                this.listLength2 = imageList.length
                console.log('oooooooooo', imageList);
            }
        },
        // 店铺logo  上传成功
        imgSuccessStoreLogo(res, file, fileListLogo) {
            if (this.storeId) {
                this.fileListLogo = fileListLogo
            } else {
                this.listLength3 = fileListLogo.length
            }
            this.shopEntryForm.storeLogo = res.result.image_url
            this.$refs.storeLogoImage.clearValidate()
            // console.log('ppppppp', this.shopEntryForm.storeLogo);
        },
        handleRemoveStoreLogo(file, fileListLogo) {
            if (this.storeId) {
                this.fileListLogo = fileListLogo
            } else {
                this.listLength3 = fileListLogo.length
            }
        },
        // 上传失败
        imgError(res) {
            this.$message({
                type: "error",
                message: "附件上传失败",
            });
        },
        // async getUploadMultipartFile() {
        //     //调取所有商品列表的接口
        //     let res = await uploadMultipartFile(this.fileList);
        //     console.log(res)
        // },
        uptp() {
            console.log("上传图片！！");
            // console.log('bbbbbbb',this.shopEntryForm.licencePhoto)
            // this.getUploadMultipartFile()
        },
        // 图片上传前的判断
        beforeAvatarUpload(file) {
            let imgType = ['gif', 'jpg', 'png']
            let judge = false // 后缀
            let type = file.name.split('.')[file.name.split('.').length - 1]
            for (let k = 0; k < imgType.length; k++) {
                if (imgType[k].toUpperCase() === type.toUpperCase()) {
                    judge = true
                    break
                }
            }
            // 验证图片格式
            if (!judge) {
                this.$message.error('图片格式只支持：GIF、JPG、PNG')
                return false
            }
            // const isLt2M = file.size / 1024 / 1024 < 2
            // if (!isLt2M) {
            //     this.$message.error('上传图片大小不能超过2MB')
            //     return false
            // }
            return true
        },
        //查询店铺详情 获取状态码
        async getStoreDetail(storeId) {
            let StoreDetail = await storeDetail(this.storeId)
            console.log('获取店铺详情', StoreDetail);

            this.shopEntryForm = StoreDetail.data.result
            this.fileList.push({
                'url': StoreDetail.data.result.licencePhoto
            })
            this.imageList.push({
                'url': StoreDetail.data.result.legalPhoto[0]
            })
            this.imageList.push({
                'url': StoreDetail.data.result.legalPhoto[1]
            })
            this.fileListLogo.push({
                'url': StoreDetail.data.result.storeLogo
            })
            console.log('qqqqqqqq', this.imageList);
            // this.fileList.length = StoreDetail.data.result.licencePhoto.length
            this.imageList.length = StoreDetail.data.result.legalPhoto.length
            this.storeDisable = StoreDetail.data.result.storeDisable

        }
    },
    mounted() {
        var storage = localStorage.getItem("storeId");
        if (storage) {
            this.storeId = JSON.parse(storage);
            console.log(this.storeId);
            this.getStoreDetail(this.storeId)
            this.active = 4
        }
    },
    components: {

    },

}
</script>

<style lang="scss" scoped>
/deep/.hide_box .el-upload--picture-card {
    display: none;
}

.shopEntry-container {
    width: 100%;
    height: 100%;
    font-family: MiSans;

    .shopEntry-inner {
        width: 70%;
        // min-height: 1500px;
        margin: 60px auto;
        border-radius: 20px;
        background: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);

        .content {
            padding: 10px 20px;

            .select-wapper {
                min-height: 500px;

                .selectGoods {
                    width: 250%;
                    height: 50px;
                    line-height: 50px;
                    // background-color: aqua;
                    border: 1px solid #FBEED5;
                    background-color: #FCF8E3;
                    margin: 10px 0 20px -54px;

                    h4 {
                        color: #C09853;
                        margin-left: 20px;

                        .hidden {
                            display: none;
                        }
                    }
                }

                .list-box {
                    width: 250%;
                    min-height: 500px;
                    background-color: #ededed;
                    margin: 20px 0 30px -54px;
                    border-radius: 0.8em;
                    box-sizing: border-box;
                    padding: 10px;

                    ul {
                        list-style: none;
                        width: 18%;

                        .li-wapper {
                            position: relative;
                            // height: 30px;
                            background-color: #FFFFFF;
                            padding: 10px 15px;
                            border-radius: 10px;

                            li {
                                width: 100%;
                                height: 35px;
                                line-height: 35px;
                                // margin: 5px 0;
                                border-bottom: 1px solid #F0F0F0;
                                cursor: pointer;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .actives {
                                background-color: #FFDEDE;
                                border: 1px solid #FF7773;
                                border-radius: 5px;
                                padding: 0 5px;
                                margin: 0 -5px;
                            }

                            .dialog-one {
                                position: absolute;
                                left: 105%;
                                top: 0;
                                // margin-left: 260px;
                                // margin-top: -200px;
                                width: 90%;
                                background-color: #FFFFFF;
                                padding: 0 15px;
                                border-radius: 10px;

                                li {
                                    margin: 5px 0;
                                }
                            }

                            .dialog-two {
                                position: absolute;
                                left: 211%;
                                top: 0;
                                // margin-left: 550px;
                                width: 90%;
                                background-color: #FFFFFF;
                                padding: 0 15px;
                                border-radius: 10px;

                                li {
                                    margin: 5px 0;
                                }
                            }

                            .dialog-three {
                                position: absolute;
                                left: 318%;
                                top: 0;
                                // margin-left: 550px;
                                width: 90%;
                                background-color: #FFFFFF;
                                padding: 0 15px;
                                border-radius: 10px;

                                li {
                                    margin: 5px 0;
                                }
                            }

                            .dialog-four {
                                position: absolute;
                                left: 425%;
                                top: 0;
                                // margin-left: 550px;
                                width: 90%;
                                background-color: #FFFFFF;
                                padding: 0 15px;
                                border-radius: 10px;

                                li {
                                    margin: 5px 0;
                                }
                            }
                        }
                    }
                }
            }

            .shop-box {

                .base-info {
                    width: 250%;
                    margin-left: -50px;
                    border-bottom: 1px solid #DEDEDE;
                    box-sizing: border-box;

                    span {
                        display: block;
                        color: #2374ff;
                        padding-bottom: 5px;
                        font-weight: 700;
                    }
                }

                .commodity_info {
                    width: 250%;
                    margin-left: -50px;

                    .shop-class {
                        margin: 10px 0;
                    }

                    .commodity_info_box {
                        margin-left: -50px;

                        .commodity_info_list {
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            // margin-left: -5%;
                        }

                        .sellingPoints {
                            width: 80%;
                            // margin-left: -3%;
                        }
                    }
                }

                .shop-classify {
                    width: 250%;
                    margin-left: -50px;
                    margin-top: 50px;
                    border-bottom: 1px solid #DEDEDE;
                    box-sizing: border-box;

                    span {
                        display: block;
                        color: #2374ff;
                        padding-bottom: 5px;
                        font-weight: 700;
                    }
                }

                .tree-list {
                    min-height: 120px;
                }

                .shop-specification {
                    width: 250%;
                    margin-left: -50px;
                    margin-top: 50px;
                    border-bottom: 1px solid #DEDEDE;
                    box-sizing: border-box;

                    span {
                        display: block;
                        color: #2374ff;
                        padding-bottom: 5px;
                        font-weight: 700;
                    }
                }

                .upload-specification {
                    width: 250%;
                    margin: 10px 0 30px -40px;
                    box-sizing: border-box;
                }

                .spec-info {
                    .spec-Name-Value {
                        width: 250%;
                        margin-left: -120px;
                        // line-height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .spec-Name-Value-left {
                            display: flex;
                            align-items: center;

                            .spec-btn {
                                margin: -25px 0 0 30px;
                            }
                        }

                        .el-icon-circle-close {
                            font-size: 26px;
                            margin-top: -35px;
                        }
                    }

                    .spec-footer {
                        width: 250%;
                        margin-left: -40px;

                        .el-row {
                            margin-left: -40px;
                            margin-bottom: 15px;
                            display: flex;
                            justify-content: space-between;

                            .el-col {
                                text-align: center;

                                /deep/.el-input-group__append,
                                .el-input-group__prepend {
                                    padding: 0 8px;
                                }

                                .el-upload {
                                    /deep/.el-upload--picture-card {
                                        width: 80px;
                                        height: 80px;
                                        background-color: #fbfdff;
                                        border: 1px dashed #c0ccda;
                                        border-radius: 6px;
                                        box-sizing: border-box;
                                        cursor: pointer;
                                        line-height: 30px;
                                        vertical-align: top;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;

                                    }

                                    /deep/.el-upload-list--picture-card .el-upload-list__item-thumbnail {
                                        width: 80px;
                                        height: 80px;
                                    }

                                    /deep/.el-upload-list--picture-card .el-upload-list__item {
                                        width: 80px;
                                        height: 80px;
                                    }
                                }
                            }
                        }
                    }
                }

                .shop-description {
                    width: 250%;
                    margin-left: -50px;
                    margin-top: 50px;
                    border-bottom: 1px solid #DEDEDE;
                    box-sizing: border-box;

                    span {
                        display: block;
                        color: #2374ff;
                        padding-bottom: 5px;
                        font-weight: 700;
                    }
                }

                // .rich-text-editor {
                //     width: 250%;
                //     margin-left: -50px;
                //     margin-top: 10px;
                //     box-sizing: border-box;
                //     // /deep/.w-e-text-container{
                //     //     height: 500px !important;
                //     // }
                // }

                // .other-info {
                //     width: 250%;
                //     margin-left: -50px;
                //     margin-top: 50px;
                //     border-bottom: 1px solid #DEDEDE;
                //     box-sizing: border-box;

                //     span {
                //         display: block;
                //         color: #2374ff;
                //         padding-bottom: 5px;
                //         font-weight: 700;
                //     }
                // }

                // .product-recommend {
                //     width: 250%;
                //     margin-left: -50px;
                //     margin-top: 10px;
                //     box-sizing: border-box;
                // }
            }


            h1 {
                margin-top: 20px;
                color: #515A6E;
            }

            .button-box {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            /deep/.el-step.is-horizontal .el-step__line {
                height: 20x;
                top: 12px;
                left: 138px;
                right: 24px;
            }

            /deep/.el-step__title {
                font-size: 16px;
                line-height: 38px;
                margin: -32px 0 0 30px;
            }

            .el-steps--horizontal {
                margin: 30px 0;
            }

            .el-form {
                width: 40%;
                margin: 30px 0 30px 50px;

                /deep/.el-form-item__content {
                    margin: 5px 0;
                }

                /deep/.el-form-item__label {
                    margin: 5px 0;
                }

                .basic-information {
                    width: 250%;
                    height: 50px;
                    line-height: 50px;
                    // background-color: aqua;
                    border: 1px solid #DDDDDD;
                    margin: 10px 0 20px -10%;

                    h3 {
                        color: #333;
                        margin-left: 20px;
                    }
                }

                p {
                    width: 300%;
                }

                .hint {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 235%;
                    height: 500px;
                    font-size: 20px;
                    color: #515A6E;
                }
            }

            .buttons {
                margin: 30px 0 20px 50px;
                background-color: #FF5C58;
                color: #fff;
            }
        }
    }
}
</style>