import Vue from 'vue'
import Router from 'vue-router'
// import Index from '../../views/pc/home/index.vue'
// import { importRouterFn } from '@/utils/importRouter'
// 引入cookie.js 我们只需要获取
import { getToken } from '../../utils/cookie'
// const importRouter = importRouterFn(require.context('@/router/pc/modules', false, /.js$/))
Vue.use(Router)

let routes = [
  {
    path: '/home',
    // name:'首页',
    name: 'index',
    component: () => import('../../views/pc/home/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/joinClubSupplie',
    // name:'供应商',
    name: 'joinClubSupplie',
    component: () => import('../../views/pc/joinClubSupplie/joinClubSupplie.vue'),
    meta: {
      // requireAuth: true
    }
  },
  {
    path: '/joinClubDesigner',
    // name:'设计师',
    name: 'joinClubDesigner',
    component: () => import('../../views/pc/joinClubDesigner/joinClubDesigner.vue'),
    meta: {
      // requireAuth: true
    }
  },
  {
    path: '/recruitment',
    // name:'面料专家招募',
    name: 'recruitment',
    component: () => import('../../views/pc/recruitment/recruitment.vue'),
    meta: {
      
    }
  },
  {
    path: '/specialist',
    // name:'面料专家页面',
    name: 'specialist',
    component: () => import('../../views/pc/specialist/specialist.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/specialistdetail/:id',
    // name:'推荐选品',
    name: 'specialistdetail',
    component: () => import('../../views/pc/specialistdetail/specialistdetail.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/fabric',
    name: 'fabric',
    // name:'面料库',
    component: () => import('../../views/pc/fabric/fabric.vue'),
    meta: {
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/buyinformation',
    name: 'buyinformation',
    // name:'求购信息',
    component: () => import('../../views/pc/buyinformation/buyinformation.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/information',
    name: 'information',
    // name:'资讯',
    component: () => import('../../views/pc/information/information.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/trend',
    name: 'trend',
    // name:'流行趋势',
    component: () => import('../../views/pc/trend/trend.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path:'/selection',
    name: 'selection',
    // name:'选品中心',
    component: () => import('../../views/pc/selection/selection.vue'),
    meta: {
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path:'/apparel',
    name: 'apparel',
    // name:'选品中心',
    component: () => import('../../views/pc/apparel/index.vue'),
    meta: {
      keepAlive: true,
      requireAuth: true
    }
  },
  // 重定向到首页
  {
    path: '/',
    redirect: { path: '/apparel' }
  },

  {
    path: '/fabricdetail/:id',
    name: 'fabricdetail',
    component: () => import('../../views/pc/fabric/fabricdetail.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/appareldetail/:id',
    name: 'appareldetail',
    component: () => import('../../views/pc/apparel/appareldetail.vue'),
    meta: {
      requireAuth: true
    }
  },

  // VIP页面
  // {
  //   path: '/member',
  //   name: 'member',
  //   component: () => import('../../views/pc/fabric/member.vue'),
  // },

  {
    path: '/sample/:id',
    name: 'sample',
    component: () => import('../../views/pc/fabric/sample.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/inquiry/:id',
    name: 'inquiry',
    component: () => import('../../views/pc/fabric/inquiry.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/shopEntry',
    name: 'shopEntry',
    component: () => import('../../views/pc/shopEntry/shopEntry.vue'),
    meta: {
      requireAuth: true,
    }
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () => import('../../views/pc/marketplace/marketplace.vue'),
    meta: {
      // requireAuth: true,
    }
  },
  //询盘拿样报表
  {
    path: '/statement',
    name: 'statement',
    component: () => import('../../views/pc/fabric/statement.vue'),
    meta: {
      // requireAuth: true,
    }
  },

]
// routes = routes.concat(importRouter)
const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
/**
 * 判断是否为移动设备，是，则跳转到移动端的路径
 */
const windowWidth = document.documentElement.clientWidth
router.beforeEach((to, from, next) => {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && windowWidth < 750) {
    window.location.href = './mobile.html#/'
    return
  }

  // 判断要去的路由有没有requiresAuth
  // to.matched.some(r => r.meta.requireAuth) or to.meta.requiresAuth
  if (to.matched.some(r => r.meta.requireAuth)) {
    // getToken 如果有就返回有值
    if (getToken()) {
      // 有token,进行request请求，后台还会验证token
      next()

      // 登录状态  禁止跳转求购信息、资讯、流行趋势   建设中。。。
      if (to.path == '/trend') {
        alert('建设中,请您耐心等待！')
        next(from)
      } else if (to.path == '/information') {
        alert('建设中,请您耐心等待！')
        next(from)
      } else if (to.path == '/buyinformation') {
        alert('建设中,请您耐心等待！')
        next(from)
      }
    } else {
      next({
        path: '/home',
        // 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由，这要进一步在登陆页面判断
        query: { redirect: to.fullPath }
      })
      alert('请先登录！')
      // this.config.dialogFormVisible = true
    }
  } else {
    next()
  }
  next()
})

export default router
