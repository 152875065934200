<template>
    <!-- pc端 我要拿样页面 -->
    <div>
        <PcHeader></PcHeader>
        <div class="sample-box">
            <div class="sample-inner">
                <!-- <div class="back" @click="goBack"><i class="el-icon-arrow-left"></i></div> -->
                <div class="inner">
                    <div class="left">
                        <img v-if="!imgList.url" src="../../../assets/images/default.jpg" alt="">
                        <img v-else :src="imgList.url" alt="">
                    </div>
                    <div class="right">
                        <div class="company">
                            <!-- {{ detailMsg.storeName }} -->
                            {{ $t('navbar.supertex') }}
                        </div>
                        <div class="xian"></div>
                        <div class="flannelette-specification-box">
                            <span class="flannelette">{{ $t('navbar.ArticleNumber') }}:</span>
                            <span class="specification">{{ sn }}</span>
                        </div>
                        <el-form ref="formLabelAlign2" :label-position="labelPosition" label-width="126px"
                            :model="formLabelAlign2">
                            <el-form-item :label="$t('navbar.SupplementaryInformation')" prop="textarea" :rules="{ required: true, message: $t('navbar.SupplementaryCannotEmpty') }">
                                <el-input type="textarea" placeholder="" :autosize="{ minRows: 8, maxRows: 8 }"
                                    v-model="formLabelAlign2.textarea" style="margin-bottom: 140px;" maxlength="200">
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="contact" :label="$t('navbar.contacts')" :rules="{ required: true, message: $t('navbar.contactCannotEmpty') }">
                                <el-input v-model="formLabelAlign2.contact"></el-input>
                            </el-form-item>
                            <el-form-item prop="company" :label="$t('navbar.companyName')" :rules="{ required: true, message: $t('navbar.companyNameCannotEmpty') }">
                                <el-input v-model="formLabelAlign2.company"></el-input>
                            </el-form-item>
                            <el-form-item prop="phone" :label="$t('navbar.phone')" :rules="[
                                { required: true, trigger: 'blur', message: $t('navbar.contactNumberCannotEmpty') },
                                { type: number, message: $t('navbar.contactNumberMustNumber'), pattern: '^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$', trigger: 'blur' }]">
                                <el-input v-model="formLabelAlign2.phone"></el-input>
                            </el-form-item>
                            <el-form-item prop="address" :label="$t('navbar.address')" :rules="{ required: true, message: $t('navbar.addressCannotEmpty') }">
                                <el-input v-model="formLabelAlign2.address"></el-input>
                            </el-form-item>
                            <el-form-item prop="faxes" :label="$t('navbar.faxes')">
                                <el-input v-model="formLabelAlign2.faxes"></el-input>
                            </el-form-item>
                            <el-form-item prop="email" :label="$t('navbar.EMAIL')">
                                <el-input v-model="formLabelAlign2.email"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="btn" @click="submitForm" v-throttle="2000">{{ $t('navbar.CommitConfirm') }}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <PcFooter></PcFooter>
    </div>
</template>

<script>
import { commodityDetail, sampleCommodity } from "../../../api/index"
import PcHeader from "../../../components/pc/Header.vue"
import PcFooter from "../../../components/pc/Footer.vue"
export default {
    components: {
        PcHeader,
        PcFooter
    },
    data() {
        return {
            labelPosition: 'right',
            formLabelAlign2: {
                commodityId: '',
                number: '',
                prices: '',
                textarea: '',
                contact: '',//联系人
                company: '',//公司名称
                phone: '',
                address: '',
                faxes: '',//传真
                email: '',
            },
            number: '',
            detailMsg: {},
            imgList: [],
            id: '',
            sn: ''
        };
    },
    methods: {
        goBack() {
            this.$router.back()
        },
        async getSampleCommodity() {
            let subform = await sampleCommodity(this.formLabelAlign2)
            console.log(subform)
        },
        submitForm() {
            // this.getSampleCommodity()
            this.$refs.formLabelAlign2.validate((valid) => {
                if (valid) {
                    this.$message({
                        message: '提交成功!',
                        // offset: 300,
                        type: 'success'
                    });
                    this.getSampleCommodity()
                    this.$refs.formLabelAlign2.resetFields()
                    timer = setTimeout(() => (window.close()), 1000)
                } else {
                    this.$message.error('提交失败!!');
                    return false;
                }
            });
        },
        async getCommodityDetail(id) {
            let { data: res } = await commodityDetail(this.id)
            // console.log(res)
            this.detailMsg = res.result
            this.sn = res.result.skuList[0].sn
            //详情页图片处理
            this.imgList = JSON.parse(JSON.stringify(this.detailMsg.commodityImagesList[0]))
        },
    },
    created() {
        this.id = this.$route.params.id
        this.formLabelAlign2.commodityId = this.id
        this.getCommodityDetail()
    },
    beforeDestroy() {
        clearInterval(timer);//清除定时器
    }
}
</script>

<style lang="scss" scoped>
/deep/.el-form-item.is-error .el-textarea__inner{
    border: 0.0625rem solid #DCDFE6;
}
/deep/.el-form-item__error{
    // margin-left: -100px;
    font-size: 16px;
}
.sample-box {
    width: 100%;
    // height: 1190px;
    display: flex;
    justify-content: center;

    .sample-inner {
        // width: 1140px;
        // height: 1190px;
        margin-top: 40px;
        font-family: MiSans;

        // display: flex;
        .back {
            width: 80px;
            height: 30px;
            background-color: rgb(241, 241, 241);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
            margin: -20px 0 10px 0;
            padding: 5px 0;
        }

        .inner {
            display: flex;

            .left {
                width: 350px;
                height: 350px;
                // background-color: yellowgreen;
                margin-right: 64px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .right {
                // flex: 1;
                width: 68%;
                // height: 1080px;
                // background-color: azure;

                .company {
                    width: 437px;
                    line-height: 48px;
                    font-size: 36px;
                    color: #1A1919;
                }

                .xian {
                    border-top: 1px solid #404040;
                    margin: 17px 0;
                }

                .flannelette-specification-box {
                    font-size: 24px;
                    color: #1A1919;
                    display: flex;

                    .flannelette {
                        display: block;
                        padding: 0 10px;
                        line-height: 32px;
                        // width: 105px;
                        // height: 32px;
                        // background-color: aqua;
                    }

                    .specification {
                        display: block;
                        // background-color: #FF235A;
                        width: 50%;
                        height: 32px;
                        // margin-left: 37px;
                    }
                }

                .el-form {
                    margin-top: 30px;
                    // background-color: aqua;

                    .el-form-item {
                        // height: 50px;
                        display: flex;
                        margin-bottom: 30px;

                        .el-input,
                        .el-textarea {
                            width: 574px;
                            height: 50px;
                            border-radius: 10px;
                            box-sizing: border-box;
                            // border: 1px solid #B8B8B8;
                            margin-left: -110px;

                            /deep/.el-textarea__inner {
                                border-radius: 10px;
                            }

                            /deep/.el-input__inner {
                                width: 574px;
                                height: 50px;
                                border-radius: 10px;
                                border: 0.0625rem solid #DCDFE6;
                            }

                            /deep/.el-input__inner:focus {
                                border: 0.0625rem solid #007aff;
                            }
                        }

                        textarea {
                            min-width: 574px;
                            max-width: 574px;
                            min-height: 190px;
                            border-radius: 10px;
                            box-sizing: border-box;
                            border: 1px solid #B8B8B8;
                            margin-left: -110px;
                            outline: none;
                        }

                        .btn {
                            // width: 182px;
                            padding: 0 30px;
                            line-height: 48px;
                            background-color: #323233;
                            border-radius: 70px;
                            color: #FFFFFF;
                            font-size: 22px;
                            margin: 10px 0 50px -110px;
                            cursor: pointer;
                        }

                        /deep/.el-form-item__label {
                            font-size: 20px;
                            min-width: 180px;
                        }
                    }
                    /deep/.el-form-item.is-error .el-input__inner {
                        border: 0.0625rem solid #F56C6C;
                    }
                    /deep/.el-form-item.is-error .el-textarea__inner {
                        border: 0.0625rem solid #F56C6C;
                    }
                }
            }
        }
    }
}</style>