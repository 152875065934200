<template>
    <div>
        <PcHeader></PcHeader>
        <div class="container">
            <div class="back-box">
                <div class="back" @click="goBack"><i class="el-icon-arrow-left"></i></div>
            </div>
            <div class="container-inner">
                <div class="mim-specialist-item" v-for="(item, index) in specialistList" :key="index" @click="getSpecialistDetail(item.id)">
                    <div class="item_img">
                        <img ref="imgRef" :src="item.icon" alt="">
                    </div>
                    <div class="item_name">
                        <span>{{item.lastName}}</span><span>{{item.firstName}}</span>
                    </div>
                    <div class="item_businessSector">
                        <div class="businessSector_item" v-for="(item2,index2) in tagList[index]" :key="index2">
                            <span>{{item2}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="specialistObj.pageNumber"
                :page-size="specialistObj.pageSize" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        <PcFooter></PcFooter>
    </div>
</template>

<script>
import PcHeader from "../../../components/pc/Header.vue"
import PcFooter from "../../../components/pc/Footer.vue"
import { specialistListApi } from "../../../api/index"
export default {
    components: {
        PcHeader,
        PcFooter
    },
    data() {
        return {
            specialistObj:{			//面料专家查询
                enable: 0,
                pageNumber: 1,
                pageSize: 15,
            },
            total: 0,       //总条数
            specialistList:[],		//面料专家列表数据
            tagList:[]
        }
    },
    methods: {
        getSpecialistDetail(id){
            this.$router.push({ path: `/specialistdetail/${id}`})
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.specialistObj.pageNumber = val
            this.getSpecialistList(this.specialistObj)
        },
        //获取面料专家数据
        async getSpecialistList(){
            let {data:res} = await specialistListApi(this.specialistObj)
            if (res.result == null) {
                return
            } else {
                this.specialistList = res.result.rows
                // console.log('获取面料专家数据',res.result)
                this.total = res.result.totalCount * 1
                this.specialistObj.pageNumber = res.result.page * 1
                this.specialistList.forEach((item,index)=>{
                    this.tagList[index] = item.businessSector.split(',')
                })
            }
        },
        goBack() {
            this.$router.back()
        },
    },
    created() {
        
    },
    mounted() {
        this.getSpecialistList(this.specialistObj)
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 1360px;
    min-height: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .back-box{
        width: 100%;
        height: 80px;
        .back {
            width: 80px;
            height: 30px;
            background-color: rgb(241, 241, 241);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
            margin: 20px 0 -5px 0;
            padding: 5px 0;
        }
    }

    .container-inner {
        width: 1360px;
        min-height: 1450px;
        display: flex;
        align-content: start;
        flex-wrap: wrap;
        box-sizing: border-box;

        .mim-specialist-item {
            width: 19%;
            height: 500px;
            margin: 20px 7px 0 6px;

            .item_img{
                width: 100%;
                height: calc(579px * 0.6);
                border-radius: 20px;
                
                display: flex;
                justify-content: center;
                
                img {
                    width: calc(413px * 0.6);
                    height: 100%;
                    box-sizing: border-box;
                    border: 1px solid rgb(211, 211, 211);
                    border-radius: 20px;
                }
            }
            .item_name{
                font-size: 24px;
                line-height: 40px;
                margin: 10px 10px 10px 15px;
            }
            .item_businessSector{ 
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                .businessSector_item{
                    display: inline-block;
                    border-radius: 5px;
                    opacity: 1;
                    padding: 0 12px;
                    background: rgba(250, 81, 81, 0.2);
                    margin: 5px 10px;

                    span{
                        font-family: Source Han Sans;
                        font-size: 18px;
                        font-weight: normal;
                        line-height: 36px;
                        letter-spacing: 0em;
                        color: #FA5151;
                    }
                }
            }
        }
    }

    .el-pagination {
        margin: 20px 0;
    }
}
</style>