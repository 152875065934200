<template>
  <div>
    <PcHeader></PcHeader>
    <div class="detail-box">
      <div class="back" @click="goBack">
        <i class="el-icon-arrow-left"></i
        >{{ datalang == "中文简体" ? "返回" : "Return" }}
      </div>
      <div class="detail-box-inner">
        <div class="top">
          <div class="left">
            <img
              v-if="this.photopath == ''"
              src="../../../assets/images/default.jpg"
              alt=""
            />
            <img v-else @click="getprev" :src="photopath" alt="" />
            <el-image
              ref="elImageRef"
              v-show="showImgPrew"
              class="img"
              :src="photopath"
              :preview-src-list="imgList"
              @click.native="handleImageClick()"
            ></el-image>
            <div class="smallimg-box">
              <div style="text-align: center">
                <img
                  style="width: 18px; height: 10px; margin-bottom: 10px"
                  src="../../../assets/images/ic_arrow_up.png"
                />
              </div>
              <!-- 轮播图 -->
              <div class="swiper-container">
                <div class="swiper-wrapper" v-if="imgloading">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in smallImageList"
                    :key="index"
                    @click.stop="getItem(item, index)"
                  >
                    <div
                      :class="index == active ? 'active' : 'banner-item-img'"
                    >
                      <img class="activeImg" ref="imgRef" :src="item" alt="" />
                    </div>
                  </div>
                </div>
                <div class="swiper-wrapper" v-else>
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in goodsGalleryList"
                    :key="index"
                    @click.stop="getItem(item, index)"
                  >
                    <div
                      :class="index == active ? 'active' : 'banner-item-img'"
                    >
                      <img class="activeImg" ref="imgRef" :src="item" alt="" />
                    </div>
                  </div>
                </div>
                <!-- 如果需要分页器 -->
                <!-- <div class="swiper-pagination"></div> -->

                <!-- 如果需要导航按钮 -->
              </div>
              <div style="text-align: center; margin-top: 10px">
                <img
                  style="width: 18px; height: 10px"
                  src="../../../assets/images/ic_arrow_down.png"
                />
              </div>
            </div>
          </div>
          <div class="right">
            <div class="right-top">
              {{ skuList.sn }}
            </div>
            <div class="xian"></div>
            <div class="describe" v-show="datalang == '中文简体'">
              <div class="describe-top">
                <p>
                  <span>规&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格: </span
                  >{{ specification ? specification : "--" }}
                </p>

                <p v-if="weights || TheWeight">
                  <span>克&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重: </span
                  >{{ weights ? weights : TheWeight }}
                </p>
                <p v-else>
                  <span>克&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重: </span>--
                </p>

                <p>
                  <span>门&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;幅: </span
                  >{{ TheWidth ? TheWidth : "--" }}
                </p>
                <p>
                  <span>经&nbsp; 纬&nbsp;密: </span
                  >{{ TheConst ? TheConst : "--" }}
                </p>
                <p>
                  <span>有&nbsp; 效&nbsp;期: </span
                  >{{ mobileCommodityVO.periodOfValidity }}
                </p>
                <p><span>俱&nbsp; 乐&nbsp;部: </span>fabtank</p>
                <p>
                  <span>送货方式: </span>{{ mobileCommodityVO.shippingMethod }}
                </p>
                <p>
                  <span>物流方式: </span
                  >{{ logisticsMethods ? logisticsMethods : "--" }}
                </p>
                <p>
                  <span>物流条款: </span
                  >{{ logisticsTerms ? logisticsTerms : "--" }}
                </p>
                <p><span>发布时间: </span>{{ releaseTime }}</p>
                <p>
                  <span>原&nbsp; 产&nbsp;地: </span
                  >{{ productionPlace ? productionPlace : "--" }}
                </p>
                <p>
                  <span>发&nbsp; 货&nbsp;地: </span
                  >{{ deliverPlace ? deliverPlace : "--" }}
                </p>
                <p>
                  <span>原料成分: </span>{{ ingredient ? ingredient : "--" }}
                </p>
                <p v-show="isfabric">
                  <span>布料分类: </span>{{ mobileCommodityVO.structure }}
                </p>
                <p v-show="isSelection">
                  <span>选品分类: </span>{{ mobileCommodityVO.structure }}
                </p>
              </div>
            </div>

            <div class="describe" v-show="datalang == 'English'">
              <div class="describe-top">
                <p>
                  <span>{{ $t("navbar.specification") }}:</span
                  >{{ specification ? specification : "--" }}
                </p>

                <p v-if="weights || TheWeight">
                  <span>{{ $t("navbar.theWeight") }}: </span
                  >{{ weights ? weights : TheWeight }}
                </p>
                <p v-else>
                  <span>{{ $t("navbar.theWeight") }}: </span>--
                </p>

                <p>
                  <span>{{ $t("navbar.theWidth") }}: </span
                  >{{ TheWidth ? TheWidth : "--" }}
                </p>
                <p>
                  <span>{{ $t("navbar.theConst") }}: </span
                  >{{ TheConst ? TheConst : "--" }}
                </p>
                <p>
                  <span>{{ $t("navbar.periodOfValidity") }}: </span
                  >{{ $t("navbar.LongTermEffective") }}
                </p>
                <p>
                  <span>{{ $t("navbar.club") }}: </span>Fabtank
                </p>
                <p>
                  <span>{{ $t("navbar.shippingMethod") }}: </span
                  >{{ $t("navbar.ByMutualAgreement") }}
                </p>
                <p>
                  <span>{{ $t("navbar.LogisticsMethods") }}: </span
                  >{{ logisticsMethods ? logisticsMethods : "--" }}
                </p>
                <p>
                  <span>{{ $t("navbar.LogisticsTerms") }}: </span
                  >{{ logisticsTerms ? logisticsTerms : "--" }}
                </p>
                <p>
                  <span>{{ $t("navbar.releaseTime") }}: </span>{{ releaseTime }}
                </p>
                <p>
                  <span>{{ $t("navbar.ProductionPlace") }}: </span
                  >{{ productionPlace ? productionPlace : "--" }}
                </p>
                <p>
                  <span>{{ $t("navbar.DeliverPlace") }}: </span
                  >{{ deliverPlace ? deliverPlace : "--" }}
                </p>
                <p>
                  <span>{{ $t("navbar.theContent") }}: </span
                  >{{ ingredientEn ? ingredientEn : "--" }}
                </p>
                <p v-show="isfabric">
                  <span>{{ $t("navbar.structure") }}: </span
                  >{{ mobileCommodityVO.structure }}
                </p>
                <p v-show="isSelection">
                  <span>{{ $t("navbar.structure") }}: </span
                  >{{ mobileCommodityVO.structure }}
                </p>
              </div>
            </div>
            <!-- 色卡下载隐藏后占位 -->
            <div class="occupied" v-show="isSelection"></div>

            <!-- 色卡下载 -->
            <div class="download-box" @click="getdown(id)" v-show="isfabric">
              {{ $t("navbar.ColorCardDownload") }}
              <img src="../../../assets/images/xiazai.png" alt="" />
            </div>

            <!-- <div class="download-box" @click="gomember, dialogVIP = true" v-show="ischeck">
                            XXXXu3m文件下载
                            <img src="../../../assets/images/xiazai.png" alt="">
                        </div>

                        <el-dialog title="" :visible.sync="dialogVIP" class="dialog" fullscreen>
                            <div class="dialog-top">开通VIP可以下载u3m格式文件</div>
                            <div class="dialog-select">
                                <div class="dialog-select-item">
                                    <img class="discounts-logo" src="../../../assets/images/discounts_logo.png" alt="">
                                    <div class="dialog-select-item-top">
                                        <span>5000</span>元/终身
                                    </div>
                                    <div class="line"></div>
                                    <div class="dialog-select-item-footer">
                                        终身海量下载
                                    </div>
                                    <img class="lower-right-logo" src="../../../assets/images/lower_right_corner.png" alt=""
                                        v-show="ischeck = false">
                                    <img class="check-logo" src="../../../assets/images/check.png" alt=""
                                        v-show="ischeck = false">
                                </div>
                                <div class="dialog-select-item">
                                    <img class="discounts-logo" src="../../../assets/images/discounts_logo.png" alt="">
                                    <div class="dialog-select-item-top">
                                        <span>2000</span>元/年
                                    </div>
                                    <div class="line"></div>
                                    <div class="dialog-select-item-footer">
                                        海量下载
                                    </div>
                                    <img class="lower-right-logo" src="../../../assets/images/lower_right_corner.png" alt=""
                                        v-show="ischeck = false">
                                    <img class="check-logo" src="../../../assets/images/check.png" alt=""
                                        v-show="ischeck = false">
                                </div>
                                <div class="dialog-select-item">
                                    <img class="discounts-logo" src="../../../assets/images/discounts_logo.png" alt="">
                                    <div class="dialog-select-item-top">
                                        <span>1000</span>元/年
                                    </div>
                                    <div class="line"></div>
                                    <div class="dialog-select-item-footer">
                                        10个/天
                                    </div>
                                    <img class="lower-right-logo" src="../../../assets/images/lower_right_corner.png" alt=""
                                        v-show="ischeck = false">
                                    <img class="check-logo" src="../../../assets/images/check.png" alt=""
                                        v-show="ischeck = false">
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="money">
                                应付金额: <span>XXXX</span>元
                            </div>
                            <div class="scan">
                                <div class="scan-left">
                                    <img src="../../../assets/images/vip_wx.png" alt="">
                                    <div class="scan-left-footer">
                                        <img src="../../../assets/images/wx_icon.png" alt="">
                                        微信支付
                                    </div>
                                </div>
                                <div class="scan-right">
                                    <img src="../../../assets/images/vip_zfb.png" alt="">
                                    <div class="scan-right-footer">
                                        <img src="../../../assets/images/zfb_icon.png" alt="">
                                        支付宝支付
                                    </div>
                                </div>
                            </div>
                        </el-dialog> -->
            <div class="right-footer">
              <button class="inquiry" @click="goinquiry(id)">
                {{ $t("navbar.inquiry") }}
              </button>
              <button class="sample" @click="gosample(id)">
                {{ $t("navbar.sample") }}
              </button>
            </div>
          </div>
        </div>

        <div v-show="isfabric" class="message-box">
          <span>{{ $t("navbar.QualityCertificate") }}</span>
        </div>
        <div v-show="isfabric" class="quality-box">
          <div v-for="(item, index) in certList" :key="index" class="img-box">
            <img :src="item.certImage" alt="" />
          </div>
        </div>

        <div class="message-box">
          <span>{{ $t("navbar.detailedInformation") }}</span>
        </div>
        <div class="cloth">
          <img v-if="!skuImg" src="../../../assets/images/default.jpg" alt="" />
          <img v-else :src="skuImg" alt="" mode="widthFix" />
          <div class="cloth-detail" v-show="datalang == '中文简体'">
            <p>
              <span>规&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 格: </span
              >{{ specification ? specification : "--" }}
            </p>

            <p v-if="weights || TheWeight">
              <span>克&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 重: </span
              >{{ weights ? weights : TheWeight }}
            </p>
            <p v-else>
              <span>克&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 重: </span>--
            </p>

            <p>
              <span>门&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 幅: </span
              >{{ TheWidth ? TheWidth : "--" }}
            </p>
            <p>
              <span>经&nbsp; 纬&nbsp; 密: </span
              >{{ TheConst ? TheConst : "--" }}
            </p>
            <p><span>原料成分: </span>{{ ingredient ? ingredient : "--" }}</p>
            <p v-show="isfabric">
              <span>布料分类: </span>{{ mobileCommodityVO.structure }}
            </p>
            <p v-show="isSelection">
              <span>选品分类: </span>{{ mobileCommodityVO.structure }}
            </p>
          </div>
          <div class="cloth-detail" v-show="datalang == 'English'">
            <p>
              <span>{{ $t("navbar.specification") }}:</span
              >{{ specification ? specification : "--" }}
            </p>

            <p v-if="weights || TheWeight">
              <span>{{ $t("navbar.theWeight") }}: </span
              >{{ weights ? weights : TheWeight }}
            </p>
            <p v-else>
              <span>{{ $t("navbar.theWeight") }}: </span>--
            </p>

            <p>
              <span>{{ $t("navbar.theWidth") }}: </span
              >{{ TheWidth ? TheWidth : "--" }}
            </p>
            <p>
              <span>{{ $t("navbar.theConst") }}: </span
              >{{ TheConst ? TheConst : "--" }}
            </p>
            <p>
              <span>{{ $t("navbar.theContent") }}: </span
              >{{ ingredientEn ? ingredientEn : "--" }}
            </p>
            <p v-show="isfabric">
              <span>{{ $t("navbar.structure") }}: </span
              >{{ mobileCommodityVO.structure }}
            </p>
            <p v-show="isSelection">
              <span>{{ $t("navbar.structure") }}: </span
              >{{ mobileCommodityVO.structure }}
            </p>
          </div>
        </div>
        <div class="show3D" v-show="isfabric">
          <div style="margin-bottom: 10px">
            <el-button @click="cameraResert()" class="resert">{{
              $t("navbar.reset")
            }}</el-button>
          </div>
          <div class="render-button-box">
            <el-button
              v-show="showbutton"
              v-throttle="2000"
              @click="gorenderer()"
              class="render-button"
              >{{ $t("navbar.Render3DEffect") }}</el-button
            >
          </div>

          <!-- <button style="position: absolute;top: 15px;left: 135px;width: 75px;" @click="flyToCar()"> 飞到小车
                    </button> -->
          <div
            id="objContaier"
            ref="objContaier"
            class="objContaier"
            v-loading="loading"
            :element-loading-text="$t('navbar.Loading')"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <!-- <iframe style="width: 100%;height: 100%;" id="iframe"
                            src='https://www.thingjs.com/s/32dcd92a656897d14bb428a3'></iframe> -->
          </div>
        </div>
      </div>
    </div>
    <div class="new_product">
      <div class="new_product_box">
        <div class="new_product_title">
          <span class="product_label">{{ $t("navbar.new_product_name") }}</span>
          <span class="product_label_right" @click="goToNewProduct">{{
            $t("navbar.more")
          }}</span>
        </div>
        <div class="min-min">
          <div
            class="mim-min-item"
            v-for="(item, index) in new_product_list"
            :key="index"
            @click="goDetail(item.id)"
          >
            <!-- <img :src="item.originalImage" alt=""> -->
            <img ref="imgRef" :src="item.thumbnail" alt="" />
            <!-- <img v-else ref="imgRef" :src="item.originalImage" alt="" /> -->
            <div class="commdity_name">{{ item.commodityName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commodityDetail, certListApi } from "../../../api/index";

// --------- three js -------------------------------------------------------------
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
// --------------------------------------------------------------------------------

// --------- thing js -------------------------------------------------------------
// import Thing from 'thingjs'
// --------------------------------------------------------------------------------

import PcHeader from "../../../components/pc/Header.vue";
import PcFooter from "../../../components/pc/Footer.vue";
import { homeListApi } from "../../../api/index";

import { Swiper, Navigation, Pagination, Autoplay } from "swiper";
import store from "../../../store/index";
export default {
  components: {
    PcHeader,
    PcFooter,
  },
  data() {
    return {
      certList: [], //认证信息列表
      dialogVIP: false,
      ischeck: false,
      releaseTime: "", //商品发布时间

      scene: undefined,
      light: undefined,
      camera: undefined,
      controls: undefined,
      renderer: undefined,
      dragControls: undefined,
      object: [],
      mesh: null,
      conWidth: undefined,
      conHeight: undefined,

      msg: [],
      id: null,
      detailMsg: {},
      skuList: [],
      mobileCommodityVO: {},
      skuImg: "",
      showbutton: true,
      loading: false,
      specification: "", //规格

      weights: "", //克重  ERP的 回显用
      TheWeight: "", //克重  以后录数据用

      TheWidth: "", //门幅
      TheConst: "", //经纬密
      ingredient: "", //原料成分-中文
      ingredientEn: "", //原料成分-英文
      goodsGalleryList: [], //相册图片列表
      photopath: "", //选中的相册
      active: 0, //相册当前选中
      imgList: [], //预览的图片
      showImgPrew: false, //显示预览图片
      num: 0, //加载完成高清图片的数量
      imgloading: true, //缩略图转高清图的判断条件
      smallImageList: [],
      imgViewerVisible: false,
      isfabric: true, //是否是面料库（用于判断分类字段及3D渲染）
      isSelection: false, //是否是选品中心（用于判断分类字段及3D渲染）
      datalang: "", //路由语言判断
      thingSrc: "",

      deliverPlace: "", //发货地
      productionPlace: "", //原产地
      logisticsMethods: "", //物流方式
      logisticsTerms: "", //物流条款
      xpObj: {
        current: 1, //当前第几页
        commodityLabel: 1,
        pageSize: 8,
      },
      new_product_list: [], //new product
    };
  },
  // components:{
  //     Lang,
  // },
  created() {
    this.id = this.$route.params.id;

    this.$EventBus.$on("this.languageValue", (data) => {
      this.datalang = data;
    });
    this.getcommodityList(this.xpObj);
  },
  mounted() {
    // -----xxxxx three js 下面放事件里了 xxxxx-------------
    // this.conWidth = this.$refs.objContaier.offsetWidth
    // this.conHeight = this.$refs.objContaier.offsetHeight
    // this.init()
    // this.animate()
    // ----- three js -------------

    // this.getCertList()
    this.getCommodityDetail();

    //横向小相册
    Swiper.use([Navigation, Pagination, Autoplay]);
    new Swiper(".swiper-container", {
      direction: "vertical",
      slidesPerView: 4, //设置slider容器能够同时显示的slides数量
      spaceBetween: 10, //slide之间的距离（单位px）
      // centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper

      // loop: true,  //开启循环属性
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // autoplay: {
      //     delay: 3000, //3秒切换一次
      // },
      // 如果需要分页器
      // pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true,
      // },
      // swiper点击事件
      // on: {
      //     click: function (e) {
      //         console.log(e);
      //     },
      // },
    });

    // 监听$refs.elImageRef.showViewer  预览图片时禁止页面滚动  关闭预览时页面可以滚动
    this.$watch(
      "$refs.elImageRef.showViewer",
      (newVal, oldVal) => {
        // console.log('监听elImageRef.showViewer',newVal, oldVal);
        if (newVal == true) {
          document.documentElement.style.overflowY = "hidden"; //禁止滚动
        } else {
          document.documentElement.style.overflowY = "auto";
        }
      },
      { deep: true }
    );
  },

  watch: {
    goodsGalleryList() {
      setTimeout(() => {
        new Swiper(".swiper-container", {
          slidesPerView: 6, //设置slider容器能够同时显示的slides数量
          spaceBetween: 10, //slide之间的距离（单位px）
          direction: "vertical",
          // centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          // loop: true,  //开启循环属性
          // 如果需要前进后退按钮
          // navigation: {
          //     nextEl: ".swiper-button-next",
          //     prevEl: ".swiper-button-prev",
          // },
          // autoplay: {
          //     delay: 3000, //3秒切换一次
          // },
          // 如果需要分页器
          // pagination: {
          //     el: ".swiper-pagination",
          //     clickable: true,
          // },
          // swiper点击事件
          // on: {
          //     click: function (e) {
          //         console.log(e);
          //     },
          // },
        });
      }, 0);
    },
    goodsGalleryList(val) {
      this.$nextTick(function () {
        let list = this.$refs.imgRef;
        for (let i in list) {
          this.imgLoad(list[i], () => {
            if (this.num == this.goodsGalleryList.length) {
              // console.log("高清图加载完成");
              this.imgloading = false;
            }
          });
        }
      });
    },
    mobileCommodityVO() {
      const structure = this.mobileCommodityVO.structure.substring(0, 2);
      // 从面料库进入详情页！
      if (structure == "面料") {
        this.isfabric = true;
        this.isSelection = false;
      }
      // 从选品中心进入详情页！
      if (structure == "选品") {
        this.isfabric = false;
        this.isSelection = true;
      }
    },
  },
  methods: {
    // ----------------- thing js --------------------------
    flyToCar() {
      var a = document.getElementById("iframe");
      var data = this.skuImg;
      window.addEventListener("message", () => this.listenFunc(), {
        passive: true,
      });
      a.contentWindow.postMessage(data, "*");
    },
    //thingjs处理事件
    handleClick(event, skuImg) {
      // 在这里处理点击事件，并使用传递的参数
      console.log("Clicked!", event, skuImg);
    },
    handleThingEvent(event, data) {
      // 在这里处理ThingJS事件，并使用传递的数据
      console.log("Thing event triggered!", event, data);
    },
    // ----------------- thing js --------------------------

    //根据id查询行业认证  certListApi
    async getCertList(id) {
      let { data: res } = await certListApi(this.id);
      // console.log('打印行业认证信息》》》',res)
      this.certList = res.result;
    },

    //色卡下载
    getdown(id) {
      const token = store.getters.getToken;
      //下载excel文件功能
      this.$http({
        url: "/fabTank-ftfs/download/excel/color/card",
        method: "post",
        responseType: "blob", // 表明返回服务器返回的数据类型
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
        data: {
          colorIds: [this.id * 1],
        },
      })
        .then((res) => {
          //创建一个隐藏的a连接，
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          link.style.display = "none";
          //设置连接
          link.href = URL.createObjectURL(blob);
          link.download = this.skuList.sn; //文件命名
          document.body.appendChild(link);
          //模拟点击事件
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //高清图片加载
    imgLoad(img, callback) {
      var _this = this;
      var timer = setInterval(function () {
        if (img.complete) {
          _this.num++;
          // console.log(_this.num);
          callback(img);
          clearInterval(timer);
        }
      }, 50);
    },
    //图片预览
    getprev(e) {
      // console.log("图片预览", e.target.currentSrc);
      // this.imgList = [e.target.currentSrc] // 需要的预览列表图片地址(单张)
      this.imgList = this.goodsGalleryList; // 需要的预览列表图片地址(多张可左右切换)
      this.$refs.elImageRef.showViewer = true; // 这个属性就可以控制 预览视图打开
    },
    handleImageClick(item) {
      // console.log("close关闭预览图片");
      this.$nextTick(() => {
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener(
          "click",
          () => {
            // 点击遮罩层时调用关闭按钮的 click 事件
            document.querySelector(".el-image-viewer__close").click();
          },
          { passive: true }
        );
      });
    },
    goToNewProduct() {
      if (this.$route.path != "/selection") {
        this.$router.push("/selection");
      }
      window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 让滚动平滑进行
    }); 
    },
    //点击小图切换图片
    getItem(item, index) {
      this.active = index;
      this.photopath = item;
    },
    gorenderer() {
      this.loading = true;
      this.conWidth = this.$refs.objContaier.offsetWidth;
      this.conHeight = this.$refs.objContaier.offsetHeight;
      this.init();
      this.animate();
      this.showbutton = false;
      // this.getCommodityDetail()
    },
    goBack() {
      this.$router.back();
    },
    async getCommodityDetail(id) {
      let { data: res } = await commodityDetail(this.id);
      // console.log("详情打印一下", res)
      this.detailMsg = res.result; //商品详情 总
      this.deliverPlace = this.detailMsg.deliverPlace; //发货地
      this.productionPlace = this.detailMsg.productionPlace; //原产地
      this.logisticsMethods = this.detailMsg.logisticsMethods; //物流方式
      this.logisticsTerms = this.detailMsg.logisticsTerms; //物流条款

      this.smallImageList = this.detailMsg.smallImageList;

      //如果没有缩略图就直接加载高清图
      if (this.smallImageList == null) {
        this.imgloading = false;
      }

      this.skuList = res.result.skuList[0]; //商品的skuList
      this.goodsGalleryList = this.skuList.goodsGalleryList; //小相册
      this.mobileCommodityVO = res.result.mobileCommodityVO; //商品的mobileCommodityVO文字详情
      // this.skuImg = this.skuList.goodsGalleryList[0].split('-thumb').join('')     //图片处理
      this.skuImg = this.skuList.goodsGalleryList[0];
      this.photopath = this.skuList.goodsGalleryList[0];
      this.releaseTime = this.mobileCommodityVO.releaseTime.split(" ")[0]; //发布时间处理
      // 处理返回的数据
      this.skuList.specList.forEach((item) => {
        //规格
        if (item.specName == "Specifications") {
          this.specification = item.specValue;
        }

        //需求：weights和TheWeight这两个字段哪一个有值就显示哪个，这两个字段不会同时有值
        //克重  ERP数据 江阴服务器数据需要保留
        if (item.specName == "weights") {
          this.weights = item.specValue;
        }
        //克重  隔离后  录入数据更改字段
        if (item.specName == "TheWeight") {
          this.TheWeight = item.specValue;
        }

        //门幅
        if (item.specName == "TheWidth") {
          this.TheWidth = item.specValue;
        }
        //经纬密
        if (item.specName == "TheConst") {
          this.TheConst = item.specValue;
        }
        //原料成分中文
        if (item.specName == "TheContentCn") {
          // console.log('打印一下TheContentCn',item.specValue);
          this.ingredient = item.specValue;
        }
        //原料成分英文
        if (item.specName == "TheContent") {
          this.ingredientEn = item.specValue;
        }
      });
    },
    //new product
    async getcommodityList(xpObj) {
      const { data: xpres } = await homeListApi(xpObj);
      this.new_product_list = xpres.result.rows;
    },
    //开通会员
    gomember() {
      // this.$router.push({ path: '/member' })
    },
    goToNewProdcut() {},
    // 询盘
    goinquiry(id) {
      // this.$router.push({ path: `/inquiry/${id}` })                        //直接跳转
      window.open(window.location.origin + `/index.html#/inquiry/${id}`); //打开新窗口跳转
    },
    // 拿样
    gosample(id) {
      // this.$router.push({ path: `/sample/${id}` })
      window.open(window.location.origin + `/index.html#/sample/${id}`);
    },

    //  three js 初始化3D
    init() {
      // this.loading = true
      // 创建场景
      this.scene = new THREE.Scene();
      // this.scene.background = new THREE.Color('#ffffff')   //不给颜色方便背景图的显示
      // 渲染
      const container = document.getElementById("objContaier");
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setClearAlpha(0.2); //设置渲染器的透明度 方便插入背景图
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.conWidth, this.conHeight);
      container.appendChild(this.renderer.domElement);
      // 相机
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.conWidth / this.conHeight,
        1,
        1000
      );
      this.camera.position.set(0, 10, 20);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      // 轨道控制
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.target.set(0, 5, 0);
      this.controls.update();
      // 光源
      const skyColor = 0xffffff;
      // const groundColor = 0xB97A20;
      // const intensity = 0.1;
      const intensity = 1;
      const light = new THREE.HemisphereLight(skyColor, intensity);
      //--------------
      //加光源    在中心点 不好用
      // var pointLight = new THREE.PointLight(0xffffff, 0.8);
      // this.camera.add(pointLight);
      // this.scene.add(this.camera);
      //--------------
      this.scene.add(light);
      // 直射光
      const dcolor = 0xffffff;
      const dintensity = 0.1;
      // const dintensity = 1;
      const dlight = new THREE.DirectionalLight(dcolor, dintensity);
      dlight.position.set(-5, 0, 0);
      // dlight.position.set(0, 10, 0);
      // dlight.target.position.set(0, 0, 0);
      dlight.target.position.set(-5, 0, 0);
      this.scene.add(dlight);
      this.scene.add(dlight.target);
      // 环境光
      const ambient = new THREE.AmbientLight(0xffffff, 0.3);
      // const ambient = new THREE.AmbientLight(0x404040, 1.5)
      this.scene.add(ambient);
      // 材质模型加载器，模型在服务器上
      const mtlLoader = new MTLLoader();
      const objLoader = new OBJLoader();
      var textureLoader = new THREE.TextureLoader();

      //    http://196.192.168.122:31809/dev/u3ma%E6%96%87%E4%BB%B6%2C/test/Fabric_effect_testing_20230724_Colorway_1.obj?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20230802%2F%2Fs3%2Faws4_request&X-Amz-Date=20230802T084409Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=3f6f62f664fa8566300b3e2964d41d0d575eefcd6d411c7410350e543ef6545e
      mtlLoader.load(
        "/static/Fabric_effect_testing_20230724_Colorway_1.mtl",
        (mtl) => {
          mtl.preload();
          for (const material of Object.values(mtl.materials)) {
            material.side = THREE.DoubleSide;
          }
          objLoader.setMaterials(mtl);
          // this.loading = true
          //    http://196.192.168.122:31809/dev/u3ma%E6%96%87%E4%BB%B6%2C/test/Fabric_effect_testing_20230724_Colorway_1.obj?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20230802%2F%2Fs3%2Faws4_request&X-Amz-Date=20230802T070125Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=e4b39d2359a148b52c70337caa5aa6d208d38ca41e1241739ddb8c0d83f24ac8
          objLoader.load(
            "/static/Fabric_effect_testing_20230724_Colorway_1.obj",
            (root) => {
              //  http://196.192.168.122:31809/fabtank-u3ma/fabtank-u3ma/zip/Fabric_effect_testing_20230724_Colorway_1.obj
              this.root = root;
              const box = new THREE.Box3().setFromObject(root);
              const boxSize = box.getSize(new THREE.Vector3()).length();
              const boxCenter = box.getCenter(new THREE.Vector3());
              this.objBoxSize = boxSize;
              this.objBoxCenter = boxCenter;
              this.cameraResert();

              // 辅助坐标线
              // var axesHelper = new THREE.AxesHelper(15);
              // this.scene.add(axesHelper);

              //   http://196.192.168.122:31809/dev/u3ma%E6%96%87%E4%BB%B6%2C/test/tex_0_02e7ee36d_basecolor.png
              var haokangde = this.skuImg; //直接在load()里写路径不行
              textureLoader.load(haokangde, (texture) => {
                // console.log(texture, root);
                texture.encoding = THREE.sRGBEncoding;

                const material = new THREE.MeshPhongMaterial({
                  // color: 0xBBBBBB
                  color: 0xffffff,
                });
                material.color.convertSRGBToLinear();
                // 定义gammaOutput和gammaFactor
                this.renderer.gammaOutput = true;
                this.renderer.gammaFactor = 2.2; //电脑显示屏的gammaFactor为2.2

                root.children[0].material.color.set(12632256); //设置圆台颜色    11053224
                // root.children[0].material.map = texture;
                root.children[1].material.map = texture;
                root.children[2].material.map = texture;
                // root.children[0].material.needsUpdate = true;
                root.children[1].material.needsUpdate = true;
                root.children[2].material.needsUpdate = true;
                this.scene.add(root);
                this.renderer.render(this.scene, this.camera);
                this.loading = false;
              });
            }
          );
        }
      );
    },
    // 刷新渲染
    animate() {
      this.renderer.render(this.scene, this.camera);
      this.requestId = requestAnimationFrame(this.animate);
    },
    // 相机位置调整
    frameArea(sizeToFitOnScreen, boxSize, boxCenter) {
      const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
      const halfFovY = THREE.MathUtils.degToRad(this.camera.fov * 0.5);
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
      const direction = new THREE.Vector3()
        .subVectors(this.camera.position, boxCenter)
        .multiply(new THREE.Vector3(0, 1, 0)) //调整一键渲染的坐标位置   （光源面）
        // .multiply(new THREE.Vector3(1, 0, 1))
        .normalize();
      this.camera.position.copy(
        direction.multiplyScalar(distance).add(boxCenter)
      ); //重置必须
      this.camera.position.set(0, 90, -15); //左右 远近 上下          重置写为固定坐标
      this.camera.near = boxSize / 100;
      this.camera.far = boxSize * 100;
      this.camera.updateProjectionMatrix();
      this.camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    },
    // 相机位置重置
    cameraResert() {
      if (this.objBoxCenter) {
        this.frameArea(
          this.objBoxSize * 1.2,
          this.objBoxSize,
          this.objBoxCenter
        );
        this.controls.maxDistance = this.objBoxSize * 10;
        this.controls.target.copy(this.objBoxCenter);
        this.controls.update();
        this.scene.add(this.root);
        this.renderer.render(this.scene, this.camera);
      }
    },
  },
  beforeDestroy() {
    try {
      this.scene.clear();
      this.camera.clear();
      this.renderer.dispose();
      this.renderer.forceContextLoss();
      cancelAnimationFrame(this.requestId); // 去除animationFrame
      this.scene = null;
      this.light = null;
      this.camera = null;
      this.renderer = null;
      // this.renderer.content = null;
      this.dragControls = null;
      this.texture = null;
      this.root = null;
      this.controls = null;
      this.object = null;
      this.mesh = null;
      this.container = null;
      // let gl = this.renderer.domElement.getContext("webgl");
      // gl && gl.getExtension("WEBGL_lose_context").loseContext();
      // console.log('销毁操作',this.scene,this.camera,this.renderer,this.container,this.texture,this.root,this.controls,this.mesh)
    } catch (e) {
      // console.log(e)
    }
  },
};
</script>

<style lang="scss">
.el-image-viewer__mask {
  background: #eeeeee;
}

.el-image-viewer__wrapper {
  background: #eeeeee;
  outline: none;
}

.detail-box {
  width: 100%;
  padding-bottom: 200px;
  // height: 2365px;
  display: flex;
  justify-content: center;
  background: #f6f6f6;
  .back {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    margin-right: 40px;
    padding: 5px 0;
    color: #3d3d3d;
    cursor: pointer;
  }

  .detail-box-inner {
    width: 1140px;
    // height: 2232px;
    border-radius: 10px;
    opacity: 1;
    background: #ffffff;
    padding: 10px;
    margin-top: 30px;
    // display: flex;
    .top {
      width: 100%;
      height: 750px;
      margin: 20px 0 45px 0;
      display: flex;
      justify-content: space-between;

      .left {
        width: 600px;
        height: 700px;
        position: relative;
        background: #eeeeee;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          object-fit: cover;
        }

        .smallimg-box {
          position: absolute;
          text-align: center;
          top: 0;
          left: 0;
          width: 88px;
          margin-left: 10px;
          height: 440px;
          margin-top: 10px;
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 0px;
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          // background-color: #FF235A;

          // img {
          //     height: 78px;
          //     border-radius: 10px;
          // }
        }

        .swiper-container {
          position: relative;
          height: 320px;
          // width: 100%;
          opacity: 1;
          // background-color: aqua;
          overflow: hidden;
        }

        .swiper-wrapper {
          align-items: center;
        }

        .swiper-slide {
          // width: 58px;
          width: 82px;
          height: 82px;
          max-height: 88px;
          text-align: center;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.5s;

          // background-color: aqua;
          cursor: pointer;

          .banner-item-img {
            width: 78px;
            height: 78px;
            opacity: 1;
            .activeImg {
              width: 78px;
              height: 78px;
              overflow: hidden;
              border-radius: 4px;
            }
          }

          .active {
            opacity: 1;
            box-sizing: border-box;
            border: #e41818 2px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            .activeImg {
              max-width: 78px;
              max-height: 78px;
              overflow: hidden;
              width: 78px;
              height: 78px;
              border-radius: 4px;
            }
          }
        }

        .swiper-slide-active,
        .swiper-slide-duplicate-active {
          // transform: scale(1.1);
          transition: 0.5s;
          z-index: 2001;
        }
      }

      .right {
        width: 510px;
        height: auto;
        flex: 1;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        .right-top {
          font-family: MiSans;
          font-size: 50px;
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          color: #1a1919;
        }

        .xian {
          height: 0;
          border-top: 1px solid #404040;
          margin: 19px 0 15px 0;
        }

        .describe {
          display: flex;
          flex-direction: column;
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          font-family: Source Han Sans CN;
          color: #3d3d3d;
          flex: 1;

          .describe-top {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 180px;
            // height: 214px;
            // background-color: chartreuse;
            margin: 0 50px -10px 0;

            p {
              margin: 3px 0;
              display: flex;

              span {
                display: block;
                min-width: 60px;
                font-weight: bold;
                margin-right: 10px;
              }
            }
          }

          .describe-bottom {
            width: 100%;
            // height: 105px;
            height: 140px;
            // background-color: chartreuse;
            margin: 0 50px 10px 0;

            p {
              margin: 3px 0;
              display: flex;

              span {
                display: block;
                min-width: 60px;
                font-weight: bold;
                margin-right: 10px;
              }
            }
          }
        }

        .occupied {
          width: 440px;
          height: 48px;
        }

        .download-box {
          width: 440px;
          height: 48px;
          line-height: 48px;
          background-color: #323233;
          border-radius: 5px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Source Han Sans CN;
          font-size: 24px;
          font-weight: normal;
          line-height: 35px;
          cursor: pointer;
          margin: 0 auto;

          img {
            width: 24px;
            height: 24px;
            margin-left: 15px;
          }
        }

        // .dialog {
        //     width: 1000px;
        //     height: 600px;
        //     border-radius: 30px;
        //     background: #FFFFFF;
        //     margin: 285px 0 0 441px;

        //     /deep/.el-dialog__body {
        //         display: flex;
        //         flex-direction: column;
        //         align-items: center;
        //     }

        //     .dialog-top {
        //         height: 32px;
        //         font-size: 24px;
        //         font-weight: 600;
        //         color: #323233;
        //     }

        //     .dialog-select {
        //         width: 765px;
        //         height: 115px;
        //         margin: 45px 0 62px 0;
        //         display: flex;
        //         justify-content: space-between;
        //         align-items: center;

        //         .dialog-select-item {
        //             width: 216px;
        //             height: 113px;
        //             border-radius: 10px;
        //             box-sizing: border-box;
        //             border: 1px solid #787476;
        //             position: relative;
        //             display: flex;
        //             flex-direction: column;
        //             // justify-content: center;
        //             align-items: center;
        //             font-size: 20px;
        //             color: #787476;
        //             cursor: pointer;
        //             text-align: center;

        //             .discounts-logo {
        //                 position: absolute;
        //                 top: 0;
        //                 left: 0;
        //             }

        //             .lower-right-logo,
        //             .check-logo {
        //                 position: absolute;
        //                 right: -1px;
        //                 bottom: -1px;
        //             }

        //             .dialog-select-item-top {
        //                 width: 164px;
        //                 height: 48px;
        //                 margin: 17px 25px 4px 24px;

        //                 span {
        //                     font-size: 36px;
        //                     font-weight: 600;
        //                     color: #FF235A;
        //                 }
        //             }

        //             .line {
        //                 width: 164px;
        //                 height: 0;
        //                 border-top: 1px solid #787476;
        //             }

        //             .dialog-select-item-footer {
        //                 width: 120px;
        //                 height: 27px;
        //                 margin-top: 4px;
        //             }
        //         }
        //     }

        //     .line {
        //         width: 765px;
        //         height: 0;
        //         border-top: 1px solid #787476;
        //     }

        //     .money {
        //         width: 166px;
        //         height: 27px;
        //         font-size: 20px;
        //         color: #323233;
        //         margin: 17px 0 47px 0;

        //         span {
        //             color: #FF235A;
        //         }
        //     }

        //     .scan {
        //         width: 385px;
        //         height: 162px;
        //         display: flex;
        //         justify-content: space-between;

        //         .scan-left,
        //         .scan-right {
        //             width: 128px;
        //             height: 162px;

        //             img {
        //                 width: 128px;
        //                 height: 128px;
        //             }

        //             .scan-left-footer,
        //             .scan-right-footer {
        //                 display: flex;
        //                 justify-content: space-around;
        //                 align-items: center;
        //                 margin-top: 10px;

        //                 img {
        //                     width: 21px;
        //                     height: 21px;
        //                 }
        //             }
        //         }
        //     }
        // }

        .right-footer {
          width: 510px;
          height: 48px;
          display: flex;
          justify-content: space-evenly;
          // margin-top: 21px;
          margin-top: 30px;

          .inquiry {
            width: 200px;
            height: 48px;
            box-sizing: border-box;
            background-color: #655c57;
            border-radius: 5px;
            font-size: 24px;
            color: #ffffff;
            border: 1px solid #655c57;
            cursor: pointer;
          }

          .sample {
            width: 200px;
            height: 48px;
            // background-color: #655C57;
            border-radius: 5px;
            font-size: 24px;
            color: #242424;
            border: none;
            cursor: pointer;
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #655c57;
          }
        }
      }
    }

    .message-box {
      width: 100%;
      height: 60px;
      background-color: #f4f4f4;
      // margin: 35px 0 25px 0;

      span {
        display: inline-block;
        padding: 0 30px;
        line-height: 60px;
        background-color: #404040;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        font-family: MiSans;
        font-size: 24px;
        color: #ffffff;
      }
    }
    .quality-box {
      width: 100%;
      min-height: 80px;
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
      .img-box {
        width: calc((100% / 6));
        // border: 1px solid rgb(204, 204, 204);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .cloth {
      width: 100%;
      height: 654px;
      // height: 1140px;
      // background-color: #C7D5D6;
      margin: 25px 0 33px 0;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }

      .cloth-detail {
        position: absolute;
        top: 0;
        left: 67px;
        // width: 256px;
        width: 300px;
        height: 529px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
        // margin-left: 67px;

        font-size: 20px;
        line-height: 35px;
        font-weight: 400;
        font-family: Source Han Sans CN;
        color: #3d3d3d;
        padding: 31px 20px;

        p {
          line-height: 50px;
          span {
            display: inline-block;
            min-width: 60px;
            font-weight: bold;
            margin-right: 10px;
          }
        }
      }
    }

    .show3D {
      width: 100%;
      height: 810px;
      position: relative;
      // background-color: aqua;
      padding-bottom: 80px;

      .resert {
        // border-radius: 30px;
        // width: 100px;
        // height: 48px;
        box-sizing: border-box;
        background-color: #ffffff;
        border-radius: 70px;
        font-size: 20px;
        font-weight: 700;
        // color: #FF235A;
        // border: 1px solid #FF235A;
        color: #006cbe;
        border: 1px solid #3ca9f2;
        padding: 15px 30px;
      }

      .render-button-box {
        width: 100%;
        position: absolute;
        top: 380px;
        left: 0;

        .render-button {
          width: 286px;
          height: 72px;
          margin: 0 37%;
          border-radius: 100px;
          background-color: #3b5463;
          border: none;
          font-size: 30px;
          font-weight: 700;
          color: #ffffff;
        }
      }

      .objContaier {
        width: 100%;
        height: 759px;
        background: url(../../../assets/images/3Dbg.png) no-repeat center;
        background-size: cover;
        overflow: hidden;
      }
    }
  }
}
.new_product {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  .new_product_box {
    display: flex;
    width: 1140px;
    flex-direction: column;
    align-self: center;
    margin-left: 84px;

    .min-min {
      width: 1140px;
      height: 580px;
      margin-top: 44px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .mim-min-item {
        width: 252px;
        height: 252px;
        // background-color: aqua;
        // border: 1px solid rgb(211, 211, 211);
        margin: 5px;
        margin-bottom: 50px;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
          border-radius: 20px;
        }
        .commdity_name {
          font-family: MiSans;
          font-size: 16px;
          font-weight: bold;
          line-height: normal;
        }
      }
    }
    .product_label {
      font-family: MiSans;
      width: 1140px;
      font-size: 30px;
      font-weight: normal;
      line-height: 75px;
      letter-spacing: 0em;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #000000;
    }
    .product_label_right {
      color: #ffffff;
      text-align: center;
      border-radius: 5px;
      min-width: 40px;
      opacity: 1;
      background: #242424;
      width: auto;
      height: fit-content;
      padding: 5px;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .new_product_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
