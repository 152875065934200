<template>
    <div>
        <PcHeader></PcHeader>
        <div class="container">
            <div class="container-inner">
                <!-- 市场页面 -->
                <div class="box" :data="currentlist" v-for="(item, index) in currentlist" :key="index">
                    <div class="box-inner">
                        <img :src="item.src" alt="">
                        <p>{{ item.text }}</p>
                        <div class="member"><span class="vip">逐日VIP</span><span class="discount">8.5折</span></div>
                        <!-- <img class="img-logo" src="https://pic.ntimg.cn/file/20190602/29233777_112131974087_2.jpg" alt=""> -->
                        <div class="price-member">
                            <span class="price">￥{{ (item.price).toFixed(2) }}</span>
                            <span class="memberPrice">会员价:{{ (item.price * 0.85).toFixed(2) }}</span>
                        </div>
                        <div class="icon-seller">
                            <span class="el-icon-s-shop"></span>
                            <span class="seller">{{ item.seller }}</span>
                        </div>
                        <p class="number">月销 {{ item.number }}</p>
                    </div>
                </div>
            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
                :page-size="size" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        <PcFooter></PcFooter>
    </div>
</template>

<script>
import PcHeader from "../../../components/pc/Header.vue"
import PcFooter from "../../../components/pc/Footer.vue"
// import listJson from "../../../../public/static/marketplace.json"

export default {
    components: {
        PcHeader,
        PcFooter,
        // MobileHeader,
        // MobileFooter
    },
    data() {
        return {
            currentPage3: 1,
            total: 0,      //总条数
            page: 1,
            size: 15,
            currentlist: [],
            list:[]
        }
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.size = val;
            this.page = 1;
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);

            this.page = val;
            this.getlist()
        },
        getlist() {
            this.currentlist = this.list.slice(
                (this.page - 1) * this.size,
                this.page * this.size
            );
            this.total = this.list.length
        },
    },
    created() {
        // if (listJson && Array.isArray(listJson)) {
        //     this.list = listJson;
        // } else {
        //     console.error('listJson不是一个有效的数组或未能正确加载');
        // }
        this.list = this.$config
        // console.log('====>',this.$config)
        // window.location.reload(true)  //从服务器层面强制刷新
    },
    mounted() {
        this.getlist()
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 1360px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    // box-sizing: border-box;
    // height: 1150px;

    // background-color: aqua;
    .container-inner {
        width: 1360px;
        // background-color: #e0973d;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        box-sizing: border-box;

        .box {
            width: 246px;
            height: 378px;
            // box-sizing: border-box;
            display: inline-block;
            font-size: 12px;
            border: 1px solid #f2f2f2;
            // border-top: none;
            // border-left: none;
            background: #fff;
            vertical-align: top;
            // margin-right: 20px;
            cursor: pointer;

            .box-inner {
                display: inline-block;
                width: 244px;
                height: 376px;
                box-sizing: border-box;
                padding: 22px 20px 0;
                background: #fff;
                position: relative;

                img {
                    width: 198px;
                    height: 198px;
                }

                p {
                    height: 40px;
                    white-space: normal;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: #9b9b9b;
                    line-height: 20px;
                    padding-top: 10px;
                    margin-bottom: 5px;
                }

                .member {
                    display: flex;
                    width: 140px;
                    // padding: 5px;
                    // background: url('https://bpic.588ku.com/back_our/20210913/bg/bbd53abfea3c70ab4ec7d6fb09355192_100529.png') no-repeat;
                    // https://img1.dowebok.com/2484.png
                    // https://bpic.588ku.com/back_origin_min_pic/19/07/03/cb85ea4061ccd360567382844e489381.jpg!/fw/750/quality/99/unsharp/true/compress/true
                    height: 20px;
                    line-height: 20px;
                    // width: 90%;
                    overflow: hidden;
                    background-size: cover;
                    color: #e0973d;
                    border-radius: 4px;
                    box-sizing: border-box;

                    // text-align: center;
                    // text-indent: 2.5em;
                    .vip {
                        width: 70px;
                        display: block;
                        line-height: 20px;
                        text-align: center;
                        // padding-left: 5px;
                        // padding-right: 2px;
                        background-color: #1D1910;
                        color: #DAC9B7;
                        // height: 100%;
                    }

                    .discount {
                        width: 70px;
                        display: block;
                        line-height: 20px;
                        text-align: center;
                        // padding-left: 5px;
                        background-color: #FBCCAE;
                        color: #260B00;
                    }
                }

                // .img-logo{
                //     width: 26px;
                //     height: 26px;
                //     border-radius: 50%;
                //     position: absolute;
                //     top: 278px;
                //     left: 20px;
                // }

                .price-member {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .price {
                        display: inline-block;
                        margin-top: 3px;
                        vertical-align: baseline;
                        text-align: left;
                        line-height: 25px;
                        font-family: PingFangSC-Medium;
                        font-size: 18px;
                        color: #fd3f31;
                    }

                    .memberPrice {
                        // width: 180px;
                        // display: block;
                        // line-height: 25px;
                        font-size: 12px;
                        // margin-left: 100px;
                        color: #975709;
                        // margin-top: -25px;
                    }
                }

                .icon-seller {
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: #9b9b9b;

                    .el-icon-s-shop {
                        font-size: 18px;
                        color: #FD5752;
                        margin-right: 10px;
                    }

                    .seller {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .number {
                    line-height: 10px;
                    text-align: end;
                }
            }
        }

        .box:hover {
            // margin-top: -1px;
            // margin-left: -1px;
            // border: 1px solid #fa0404;
            border-color: #fa0404;
        }
    }

    .el-pagination {
        margin: 20px 0;
    }
}
</style>