<template>
    <!-- 询盘拿样报表 -->
    <div class="container">
        <div class="container-top">
            <img class="top-bg" src="../../../assets/images/title_bg.png" alt="">
            <span>询盘拿样数据模块</span>
        </div>
        <div class="container-min">
            <!-- 今日询样数 -->
            <div class="sample">
                <div class="sample-title">
                    <img class="title-img" src="../../../assets/images/title.png" alt="">
                    <span>今日询样数</span>
                </div>
                <div class="sample-content">
                    <topLeft></topLeft>
                </div>
            </div>
            <!-- 今日询样反馈数 -->
            <div class="feedback">
                <div class="feedback-title">
                    <img class="title-img" src="../../../assets/images/title.png" alt="">
                    <span>今日询样反馈数</span>
                </div>
                <div class="feedback-content">

                </div>
            </div>
        </div>
        <div class="container-footer">
            <div class="footer-title">
                <img class="title-img" src="../../../assets/images/title.png" alt="">
                <span>当月询样反馈数</span>
            </div>
            <div class="footer-content">
                <div class="fotter-top">
                    <div class="left">

                    </div>
                    <div class="right">

                    </div>
                </div>
                <div class="fotter-bottom">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import topLeft from "../../../components/pc/Echarts/topLeft.vue"
export default {
    components: {
        topLeft,
    },
    data() {
        return {

        }
    },
    watch: {},
    methods: {

    },
    mounted() {

    },
}
</script>

<style lang='scss' scoped>
* {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    height: 100%;
    min-height: 1750px;

    background-color: #202648;

    .container-top {
        .top-bg {
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        span {
            position: absolute;
            left: 760px;
            top: 32px;
            width: 400px;
            height: 48px;
            opacity: 1;
            font-family: 思源黑体;
            font-size: 50px;
            font-weight: bold;
            line-height: 50px;
            letter-spacing: 0em;
            color: #E4E4E4;
        }
    }

    .container-min {
        display: flex;
        justify-content: space-evenly;

        .sample {
            position: absolute;
            left: 38px;
            top: 174px;
            width: 900px;
            height: 478px;
            opacity: 1;

            .sample-title {
                .title-img {
                    position: absolute;
                    left: -13px;
                    top: -16px;
                    width: 235px;
                    // height: 70px;
                }

                span {
                    position: absolute;
                    left: 19px;
                    top: 13px;
                    width: 110px;
                    height: 16px;
                    opacity: 1;
                    font-family: 思源黑体;
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 16px;
                    letter-spacing: 0em;
                    color: #B7C0DD;
                }
            }

            .sample-content {
                position: absolute;
                left: 0px;
                top: 41px;
                width: 900px;
                height: 437px;
                border-radius: 0px 20px 20px 20px;
                opacity: 1;
                background: linear-gradient(180deg, rgba(49, 101, 223, 0.08) 4%, rgba(49, 101, 224, 0) 100%);
                box-sizing: border-box;
                border: 2px solid #384E91;
                box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.302);
            }
        }

        .feedback {
            position: absolute;
            left: 978px;
            top: 174px;
            width: 900px;
            height: 478px;
            opacity: 1;

            .feedback-title {
                .title-img {
                    position: absolute;
                    left: -13px;
                    top: -16px;
                    width: 236px;
                }

                span {
                    position: absolute;
                    left: 19px;
                    top: 13px;
                    width: 154px;
                    height: 16px;
                    opacity: 1;
                    font-family: 思源黑体;
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 16px;
                    letter-spacing: 0em;
                    color: #B7C0DD;
                }
            }

            .feedback-content {
                position: absolute;
                left: 0px;
                top: 41px;
                width: 900px;
                height: 437px;
                border-radius: 0px 20px 20px 20px;
                opacity: 1;
                background: linear-gradient(180deg, rgba(49, 101, 223, 0.08) 4%, rgba(49, 101, 224, 0) 100%);
                box-sizing: border-box;
                border: 2px solid #384E91;
                box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.302);
            }
        }
    }

    .container-footer {
        .footer-title {
            .title-img {
                position: absolute;
                left: 24px;
                top: 688px;
                width: 236px;
            }

            span {
                position: absolute;
                left: 57px;
                top: 717px;
                width: 154px;
                height: 16px;
                opacity: 1;
                font-family: 思源黑体;
                font-size: 22px;
                font-weight: bold;
                line-height: 16px;
                letter-spacing: 0em;
                color: #B7C0DD;
            }
        }

        .footer-content {
            position: absolute;
            left: 38px;
            top: 745px;
            width: 1840px;
            height: 953px;
            border-radius: 0px 20px 20px 20px;
            opacity: 1;
            background: linear-gradient(180deg, rgba(49, 101, 223, 0.08) 4%, rgba(49, 101, 224, 0) 100%);
            box-sizing: border-box;
            border: 2px solid #384E91;
            box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.302);
            .fotter-top{
                display: flex;
            }
        }
    }
}
</style>