<template>
  <!-- pc端 我要询盘页面 -->
  <div>
    <PcHeader></PcHeader>
    <div class="inquiry-box">
      <div class="inquiry-inner">
        <!-- <div class="back" @click="goBack"><i class="el-icon-arrow-left"></i></div> -->
        <div class="inner">
          <div class="left">
            <img
              v-if="!imgList.url"
              src="../../../assets/images/default.jpg"
              alt=""
            />
            <img v-else :src="imgList.url" alt="" />
          </div>
          <div class="right">
            <div class="company">
              <!-- {{ detailMsg.storeName }} -->
              {{ $t("navbar.supertex") }}
            </div>
            <div class="line"></div>
            <div class="flannelette-specification-box">
              <!-- <span class="flannelette">商品货号:</span> -->
              <span class="flannelette">{{ $t("navbar.ArticleNumber") }}:</span>
              <span class="specification">{{ sn }}</span>
            </div>
            <el-form
              ref="formLabelAlign"
              :label-position="labelPosition"
              label-width="126px"
              :model="formLabelAlign"
            >
              <el-form-item
                :label="$t('navbar.ExpectedOrderQuantity')"
                prop="number"
                :rules="[
                  {
                    required: true,
                    trigger: 'blur',
                    message: $t('navbar.QuantityCannotEmpty'),
                  },
                  {
                    type: number,
                    message: $t('navbar.ExpectedQuantity'),
                    pattern: /^[1-9]\d*$/,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="formLabelAlign.number"
                  style="height: 50px"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('navbar.ExpectedPrice')"
                prop="prices"
                :rules="[
                  {
                    required: true,
                    trigger: 'blur',
                    message: $t('navbar.PriceCannotEmpty'),
                  },
                  {
                    type: number,
                    message: $t('navbar.PriceMustRoundNumber'),
                    pattern: /^[1-9]\d*$/,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="formLabelAlign.prices"></el-input>
              </el-form-item>
              <el-form-item :label="$t('navbar.question')" prop="question">
                <el-input v-model="formLabelAlign.question"></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('navbar.SupplementaryInformation')"
                prop="textarea"
                :rules="{
                  required: true,
                  message: $t('navbar.SupplementaryCannotEmpty'),
                }"
              >
                <el-input
                  type="textarea"
                  placeholder=""
                  :autosize="{ minRows: 8, maxRows: 8 }"
                  v-model="formLabelAlign.textarea"
                  style="margin-bottom: 140px"
                  maxlength="200"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                :label="$t('navbar.contacts')"
                prop="contact"
                :rules="{
                  required: true,
                  message: $t('navbar.contactCannotEmpty'),
                }"
              >
                <el-input v-model="formLabelAlign.contact"></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('navbar.companyName')"
                prop="company"
                :rules="{
                  required: true,
                  message: $t('navbar.companyNameCannotEmpty'),
                }"
              >
                <el-input v-model="formLabelAlign.company"></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('navbar.phone')"
                prop="phone"
                :rules="[
                  {
                    required: true,
                    trigger: 'blur',
                    message: $t('navbar.contactNumberCannotEmpty'),
                  },
                  {
                    type: number,
                    message: $t('navbar.contactNumberMustNumber'),
                    pattern:
                      '^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="formLabelAlign.phone"></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('navbar.address')"
                prop="address"
                :rules="{
                  required: true,
                  message: $t('navbar.addressCannotEmpty'),
                }"
              >
                <el-input v-model="formLabelAlign.address"></el-input>
              </el-form-item>
              <el-form-item :label="$t('navbar.faxes')" prop="faxes">
                <el-input v-model="formLabelAlign.faxes"></el-input>
              </el-form-item>
              <el-form-item :label="$t('navbar.EMAIL')" prop="email">
                <el-input v-model="formLabelAlign.email"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="btn" @click="submitForm" v-throttle="2000">{{
                  $t("navbar.CommitConfirm")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <PcFooter></PcFooter>
  </div>
</template>

<script>
import { commodityDetail, inquiryCommodity } from "../../../api/index";
import PcHeader from "../../../components/pc/Header.vue";
import PcFooter from "../../../components/pc/Footer.vue";
export default {
  components: {
    PcHeader,
    PcFooter,
  },
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        commodityId: "",
        number: "",
        prices: "",
        question: "", //问题
        textarea: "",
        contact: "", //联系人
        company: "", //公司名称
        phone: "",
        address: "",
        faxes: "", //传真
        email: "",
      },
      number: "",
      value3: "",
      id: "",
      detailMsg: {},
      imgList: [],
      sn: "",
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async getInquiryCommodity() {
      let subform = await inquiryCommodity(this.formLabelAlign);
      console.log(subform);
    },
    submitForm() {
      // this.getInquiryCommodity()
      // console.log(this.formLabelAlign)
      // console.log("提交表单")

      this.$refs.formLabelAlign.validate((valid) => {
        if (valid) {
          // alert('提交成功!');
          this.$message({
            message: "提交成功!",
            // offset: 300,
            type: "success",
          });
          this.getInquiryCommodity();
          this.$refs.formLabelAlign.resetFields();
          // window.close();
          timer = setTimeout(() => window.close(), 1000);
        } else {
          // console.log('提交失败!!');
          this.$message.error("提交失败!!");
          return false;
        }
      });
    },
    async getCommodityDetail(id) {
      let { data: res } = await commodityDetail(this.id);
      // console.log(res)
      this.detailMsg = res.result;
      this.sn = res.result.skuList[0].sn;
      // console.log(this.detailMsg)
      // console.log(this.sn)
      //详情页图片处理
      this.imgList = JSON.parse(
        JSON.stringify(this.detailMsg.commodityImagesList[0])
      );
    },
  },
  created() {
    this.id = this.$route.params.id;
    // console.log(this.id)
    this.formLabelAlign.commodityId = this.id;
    // console.log(this.formLabelAlign.commodityId)
    this.getCommodityDetail();
  },
  beforeDestroy() {
    clearInterval(timer); //清除定时器
    // console.log('清除定时器')
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-form-item.is-error .el-textarea__inner {
  border: 0.0625rem solid #dcdfe6;
}

/deep/.el-form-item__error {
  // margin-left: -100px;
  font-size: 16px;
}

.inquiry-box {
  width: 100%;
  // height: 1190px;
  display: flex;
  justify-content: center;

  .inquiry-inner {
    // width: 1140px;
    // height: 1190px;
    margin-top: 40px;
    font-family: MiSans;
    // display: flex;

    .back {
      width: 80px;
      height: 30px;
      background-color: rgb(241, 241, 241);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      margin: -20px 0 10px 0;
      padding: 5px 0;
    }

    .inner {
      display: flex;

      .left {
        width: 350px;
        height: 350px;
        // background-color: yellowgreen;
        margin-right: 64px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        // flex: 1;
        width: 68%;
        // height: 1080px;

        .company {
          width: 437px;
          line-height: 48px;
          font-size: 36px;
          color: #1a1919;
        }

        .line {
          border-top: 1px solid #404040;
          margin: 17px 0;
        }

        .flannelette-specification-box {
          font-size: 24px;
          color: #1a1919;
          display: flex;

          .flannelette {
            display: block;
            padding: 0 10px;
            line-height: 32px;
            // width: 105px;
            // height: 32px;
            // background-color: aqua;
          }

          .specification {
            display: block;
            width: 50%;
            height: 32px;
            margin-left: 37px;
          }
        }

        .el-form {
          margin-top: 30px;

          .el-form-item {
            display: flex;
            margin-bottom: 30px;

            .el-input,
            .el-select,
            .el-textarea {
              width: 574px;
              height: 50px;
              border-radius: 10px;
              box-sizing: border-box;
              margin-left: -110px;

              /deep/.el-textarea__inner {
                border-radius: 10px;
              }

              /deep/.el-input__inner {
                width: 574px;
                height: 50px;
                border-radius: 10px;
                border: 0.0625rem solid #dcdfe6;
              }

              /deep/.el-input__inner:focus {
                border: 0.0625rem solid #007aff;
              }
            }

            textarea {
              min-width: 574px;
              max-width: 574px;
              min-height: 190px;
              border-radius: 10px;
              box-sizing: border-box;
              border: 1px solid #b8b8b8;
              margin-left: -110px;
              outline: none;
            }

            .btn {
              // width: 182px;

              font-size: 22px;
              margin: 10px 0 50px -110px;
              width: 440px;
              height: 48px;
              line-height: 48px;
              background-color: #323233;
              border-radius: 5px;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: Source Han Sans CN;
              font-size: 24px;
              font-weight: normal;
              line-height: 35px;
              cursor: pointer;
              margin: 0 auto;
            }

            /deep/.el-form-item__label {
              font-size: 20px;
              min-width: 180px;
            }
          }
          /deep/.el-form-item.is-error .el-input__inner {
            border: 0.0625rem solid #f56c6c;
          }
          /deep/.el-form-item.is-error .el-textarea__inner {
            border: 0.0625rem solid #f56c6c;
          }
        }
      }
    }
  }
}
</style>
