<!-- 底部公用组件 -->
<template viewport>
  <div class="footer_contact">
    <div class="footer_contact_top">CONTACT</div>
    <div class="horizon_line"></div>
    <div style="display: flex">
      <div style="flex: 1; margin-top: 78px">
        <div class="footer_contact_left">
          <span class="contact_label">CONTACT US</span>

          <div class="footer_contact_tip">
            Please leave your practice method and we will reply to you promptly
          </div>
          <el-form ref="form" :model="formLabelAlign" >
            <div>
              <input
                class="footer_contact_input"
                placeholder="Company Name"
                v-model="formLabelAlign.company"
                required
              />
            </div>
            <div>
              <input
                class="footer_contact_input"
                placeholder="Email"
                v-model="formLabelAlign.email"
                required
              />
            </div>
            <div>
              <el-button
                class="footer_contact_submit"
                type="submit"
                @click="submitContact"
              >
                Click to enter ————>
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
      <div style="flex: 1; height: 100%; margin-top: 78px">
        <div class="contact_info">
          <div class="row" style="margin-bottom: 30px">
            <div class="tit">
              <img src="../../assets/images/ic_address.png" />
              <span class="contact_label"
                >No. 3, Xinyuan Road, Jiangyin City, Wuxi City, Jiangsu Province, China</span
              >
            </div>
          </div>
          <div class="row" style="margin-bottom: 30px">
            <div class="tit">
              <img src="../../assets/images/ic_phone.png" />
              <span class="contact_label">+86 15951870710</span>
            </div>
          </div>
          <div class="row" style="margin-bottom: 30px">
            <div class="tit">
              <img src="../../assets/images/ic_tel.png" />
              <span class="contact_label">400-136-3669</span>
            </div>
          </div>
          <div class="row" style="margin-bottom: 30px">
            <div class="tit">
              <img src="../../assets/images/ic_email.png" />
              <span class="contact_label">linda@kqfs.com.cn</span>
            </div>
          </div>
          <div class="row" style="margin-bottom: 30px">
            <div class="tit">
              <img src="../../assets/images/ic_time.png" />
              <span class="contact_label">Mon-sat 9:00 - 18:00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { footerInfoApi, footerListApi } from "../../api/index";
import { commodityDetail, inquiryCommodity } from "../../api/index";

import i18nConfig from "../../i18n/config/zh";
export default {
  data() {
    return {
      visible: false,
      privacy: "",
      privacyEn: "",
      footerName: "",
      footerNameEn: "",
      clickInfo: "", //点击某个页脚信息
      storedValue: "", //语言判断
      enable: 0, // 0 上架   1 下架
      formLabelAlign: {
        id: 1,
        commodityId: 1,
        userId: 0,
        number: "1",
        prices: "1",
        question: "1",
        textarea: "1",
        contact: "1",
        company: "",
        phone: "1",
        address: "1",
        faxes: "1",
        email: "",
        inquiryStatus: 0,
      },
    };
  },
  methods: {
    expertRecruitment() {
      this.$router.push("/recruitment");
    },
    open(event) {
      // console.log('event',event)
      //根据不同语言状态  获取点击的中文信息  以对应接口中的 footerType类型 获取对应内容
      if (this.storedValue == "zh") {
        this.clickInfo = event.srcElement.innerHTML;
        // console.log("中文状态下的点击信息",this.clickInfo)
      } else if (this.storedValue == "en") {
        this.clickInfo = event.srcElement.innerHTML;
        const navbarObject =
          typeof i18nConfig.navbar === "string"
            ? JSON.parse(i18nConfig.navbar)
            : i18nConfig.navbar;
        const keys = Object.keys(navbarObject);
        // 遍历对象查找匹配的 key
        for (let key of keys) {
          if (key === this.clickInfo) {
            // console.log("这回对不对",navbarObject[key]);
            this.clickInfo = navbarObject[key];
            break; // 找到匹配的 key 后退出循环
          }
        }
        // console.log("英文状态下的点击信息",this.clickInfo)
      }
      this.getFooterList();
      setTimeout(() => {
        if (this.enable == 0) {
          this.openCache();
        }
      }, 500);
    },
    //弹框
    openCache() {
      // const h = this.$createElement('div');
      if (this.storedValue == "zh") {
        this.$msgbox({
          title: this.footerName,
          message: this.$createElement("div", {
            domProps: { innerHTML: this.privacy },
          }),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then((action) => {
          done();
        });
      } else if (this.storedValue == "en") {
        this.$msgbox({
          title: this.footerNameEn,
          //this.$createElement('div', { style: 'color: aqua' }, [
          message: this.$createElement("div", [
            this.$createElement("div", {
              domProps: { innerHTML: this.privacyEn },
            }),
          ]),
          showCancelButton: true,
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
        }).then((action) => {
          done();
        });
      }
    },
    //通过类型查找页脚信息
    async getFooterList() {
      let { data: res } = await footerInfoApi(this.clickInfo);
      // console.log("通过类型查找页脚信息>>>",res)
      if (res.result) {
        this.privacy = res.result.footerContent;
        this.privacyEn = res.result.footerContentEn;
        this.footerName = res.result.footerName;
        this.footerNameEn = res.result.footerNameEn;
        this.enable = res.result.enable;
      } else {
        this.enable = 1;
      }
    },
    async submitContact() {
        console.log("提交信息", this.formLabelAlign);
      let subform = await inquiryCommodity(this.formLabelAlign);
      this.$message.success("Submit successfully");
    },
  },
  created() {},
  mounted() {
    try {
      //浏览器首次加载没有locale，所以加载lang
      this.storedValue =
        window.localStorage.getItem("locale") ||
        window.localStorage.getItem("lang");
      // console.log("mounted加载", this.storedValue);
    } catch (error) {
      console.error("Error accessing localStorage:", error);
    }
  },
};
</script>

<style lang="scss">
.el-message-box__header {
  padding: 1.9375rem 0.9375rem 0.625rem;
}
.el-message-box {
  width: 800px;
  margin-top: -300px;
  border-radius: 20px;
}
.el-message-box__title {
  width: 100%;
  text-align: center;
  font-size: 24px;
}
.el-message-box__headerbtn {
  font-size: 24px;
}
.el-message-box__content {
  .el-message-box__container {
    font-size: 20px;
    height: 400px;
    overflow: auto;
    padding: 0 50px;
    line-height: 40px;
  }
}
.el-message-box__btns {
  // width: 98%;
  text-align: center;
  .el-button--small {
    font-size: 20px;
    margin: 0 60px;
    width: 35%;
    border-radius: 20px;
  }
}
.el-message-box__close {
  display: none;
}
</style>

<style lang="scss" scoped>
.footer_contact {
  width: 100%;
  height: auto;
  padding-bottom: 200px;
  min-height: 660px;
  border-top: 1px solid #242424;
  background-image: url("../../assets/images/img_contact_bg.png");
  opacity: 1;
  background-color: #161316;
  .horizon_line {
    opacity: 0.4;
    height: 1px;
    background: #ffffff;
    width: 100%;
  }
  .footer_contact_top {
    font-family: MiSans;
    font-size: 50px;
    font-weight: normal;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: center;
    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #ffffff;
    padding: 45px 0px 21px 0px;
  }
  .footer_contact_left {
    margin-left: 185px;
    margin-right: 114px;

    .footer_contact_tip {
      font-family: Source Han Sans;
      font-size: 30px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #8d8d8d;
      margin-bottom: 54px;
    }

    .footer_contact_input {
      background: #8d8d8d;
      padding-left: 10px;
      margin-bottom: 16px;
      height: 50px;
      font-family: Source Han Sans;
      font-size: 24px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      width: 100%;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #3d3d3d;
    }
    .footer_contact_submit {
      height: 50px;
      opacity: 1;
      width: 232px;
      margin-top: 34px;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      background-color: transparent;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #ffffff;
    }
  }
  .contact_info {
    border-left: 1px solid #625d5d;
    padding: 20px;
    margin: auto;
    .row {
      margin-left: 100px;
      margin-bottom: 46px;
      .tit {
        margin-right: 40px;
        display: flex;
        align-items: center;
      }
      .contact_label {
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;
        margin-left: 40px;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #8d8d8d;
      }
    }
  }
}
</style>
