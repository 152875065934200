<template>
    <div class="information-box">
        <!-- 轮播图 -->
        <div class="banner">
            <el-carousel trigger="click" height="440px">
                <el-carousel-item v-for="item in 4" :key="item">
                    <h3 class="small">{{ item }}</h3>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="min">
            <!-- 分析师专栏 -->
            <div class="analyst">
                <div class="analyst-top">
                    <div class="analyst-top-left">分析师专栏
                        <div class="line"></div>
                    </div>
                    <div class="analyst-top-right"><span>更多</span><span>></span></div>
                </div>
                <div class="analyst-footer">

                </div>
            </div>
            <!-- 最新资讯 -->
            <div class="information">
                <div class="information-left">
                    <div class="information-left-top">
                        <div class="information-left-top-left">
                            最新资讯
                            <div class="line"></div>
                        </div>
                        <div class="information-left-top-right"><span>更多</span><span>></span></div>
                    </div>
                    <div class="information-left-fotbox"></div>
                    <hr />
                    <div class="information-left-fotbox"></div>
                    <hr />
                    <div class="information-left-fotbox"></div>
                    <hr />
                    <div class="information-left-fotbox"></div>
                    <hr />
                    <div class="information-left-fotbox"></div>
                    <hr />
                    <div class="information-left-fotbox"></div>
                </div>
                <div class="information-right">
                    <div class="information-right-top">
                        <div class="information-right-top-top">
                            专题报道
                            <div class="line"></div>
                        </div>
                        <div class="information-right-top-box"></div>
                    </div>
                    <div class="information-right-min">
                        <div class="information-right-min-top">
                            热搜文章
                            <div class="line"></div>
                        </div>
                        <div class="information-right-min-box"></div>
                    </div>
                    <div class="information-right-footer">
                        <div class="information-right-footer-top">
                            <div class="information-right-footer-top-left">
                                产业观察
                                <div class="line"></div>
                            </div>
                            <div class="information-right-footer-top-right"><span>更多</span><span>></span></div>
                        </div>
                        <div class="information-right-footer-box"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.information-box {
    width: 100%;

    .banner {
        width: 1920px;
        margin: auto;

        .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 150px;
            margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }

        .el-carousel__item:nth-child(2n+1) {
            background-color: #d3dce6;
        }

        //左箭头
        ::v-deep .el-carousel__arrow--left {
            top: 180px;
            left: 366px;
            font-size: 86px;
            color: #FFFFFF;
            opacity: 0.4;
            background: rgba(255, 255, 255, 0);
            border-radius: 50%;
            width: 86px;
            height: 86px;
        }

        //右箭头
        ::v-deep .el-carousel__arrow--right {
            top: 180px;
            right: 366px;
            font-size: 86px;
            color: #FFFFFF;
            opacity: 0.4;
            background: rgba(255, 255, 255, 0);
            border-radius: 50%;
            width: 86px;
            height: 86px;
        }
    }

    .min {
        width: 100%;
        .analyst {
            width: 1142px;
            height: 300px;
            background-color: aqua;
            margin: 40px auto;

            .analyst-top {
                width: 100%;
                height: 37px;
                display: flex;
                justify-content: space-between;

                .analyst-top-left {
                    font-size: 24px;
                    line-height: 26px;
                    font-weight: 700;

                    .line {
                        width: 40px;
                        border: 1px solid #FF235A;
                        margin: 10px 0 18px 0;
                    }
                }

                .analyst-top-right {
                    width: 58px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                }
            }

            .analyst-footer {
                width: 100%;
                height: 245px;
                background-color: #c0b9bb;
            }
        }

        .information {
            width: 1142px;
            height: 1320px;
            background-color: yellowgreen;
            margin: auto;
            margin-bottom: 130px;
            display: flex;
            justify-content: space-between;

            .information-left {
                width: 756px;
                height: 100%;
                background-color: darkcyan;

                .information-left-top {
                    width: 100%;
                    height: 37px;
                    display: flex;
                    justify-content: space-between;

                    .information-left-top-left {
                        font-size: 24px;
                        line-height: 26px;
                        font-weight: 700;

                        .line {
                            width: 40px;
                            border: 1px solid #FF235A;
                            margin: 10px 0 18px 0;
                        }
                    }

                    .information-left-top-right {
                        width: 58px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26px;
                    }
                }

                .information-left-fotbox {
                    width: 756px;
                    height: 164px;
                    background-color: red;
                    margin: 26px 0;
                }
            }

            .information-right {
                width: 318px;
                height: 100%;
                background-color: azure;

                .information-right-top {
                    .information-right-top-top {
                        width: 96px;
                        font-size: 24px;
                        line-height: 26px;
                        font-weight: 700;

                        .line {
                            width: 40px;
                            border: 1px solid #FF235A;
                            margin: 10px 0 22px 0;
                        }
                    }

                    .information-right-top-box {
                        width: 318px;
                        height: 268px;
                        background-color: #475669;
                    }
                }

                .information-right-min {
                    .information-right-min-top {
                        width: 96px;
                        font-size: 24px;
                        line-height: 26px;
                        font-weight: 700;
                        margin-top: 30px;

                        .line {
                            width: 40px;
                            border: 1px solid #FF235A;
                            margin: 10px 0 20px 0;
                        }
                    }

                    .information-right-min-box {
                        width: 318px;
                        height: 271px;
                        background-color: #475669;
                    }
                }

                .information-right-footer {
                    .information-right-footer-top {
                        width: 100%;
                        height: 37px;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 30px;

                        .information-right-footer-top-left {
                            font-size: 24px;
                            line-height: 26px;
                            font-weight: 700;

                            .line {
                                width: 40px;
                                border: 1px solid #FF235A;
                                margin: 10px 0 20px 0;
                            }
                        }

                        .information-right-footer-top-right {
                            width: 58px;
                            display: flex;
                            justify-content: space-between;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 26px;
                        }
                    }

                    .information-right-footer-box {
                        width: 318px;
                        height: 544px;
                        background-color: #475669;
                        margin-top: 21px;
                    }
                }
            }
        }
    }
}
</style>