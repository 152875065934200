<template>
    <div>
        <PcHeader></PcHeader>
        <div class="container">
            <div class="back-box">
                <div class="back" @click="goBack"><i class="el-icon-arrow-left"></i></div>
            </div>
            <div class="container-inner">
                <div class="top">
                    <img src="../../../assets/images/expert-bg.png" alt="">
                </div>
                <div class="info">
                    <div class="info_img">
                        <img :src="specialistList.icon" alt="">
                    </div>
                    <div class="info_box">
                        <div class="info_text">
                            {{specialistList.lastName}}{{specialistList.firstName}}
                        </div>
                        <div class="info_businessSector">
                            <div class="businessSector_item" v-for="(item2,index2) in tagList" :key="index2">
                                <span>{{item2}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttom">
                    <div class="item" v-for="(item,index) in fabricList" :key="index" @click="getDetail(item.id)">
                        <img v-if="imgloading" ref="imgRefs" :src="item.thumbnail" alt="">
                        <img v-else ref="imgRefs" :src="item.originalImage" alt="">
                        <!-- <div class="commodityName"> -->
                            <el-tooltip v-if="item.commodityName && item.productId" class="item" effect="light" :content="item.commodityName + ' ' + item.productId" placement="top">
                                <div class="commodityName">
                                    {{item.commodityName}} {{ item.productId }}
                                </div>
                            </el-tooltip>
                            <el-tooltip v-if="item.commodityName && !item.productId" class="item" effect="light" :content="item.commodityName" placement="top">
                                <div class="commodityName">
                                    {{item.commodityName}} {{ item.productId }}
                                </div>
                            </el-tooltip>
                            <el-tooltip v-if="!item.commodityName && item.productId" class="item" effect="light" :content="item.productId" placement="top">
                                <div class="commodityName">
                                    {{item.commodityName}} {{ item.productId }}
                                </div>
                            </el-tooltip>
                        <!-- </div> -->
                    </div>
                </div>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="specialistObj.page"
                    :page-size="specialistObj.pageSize" layout="prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <PcFooter></PcFooter>
    </div>
</template>

<script>
    import PcHeader from "../../../components/pc/Header.vue"
    import PcFooter from "../../../components/pc/Footer.vue"
    import { specialistListApi,specialistDetailApi } from "../../../api/index"
    export default {
        components: {
            PcHeader,
            PcFooter
        },
        data() {
            return {
                specialistObj:{			//面料专家查询
                    id:'',    //传递过来的id
                    page: 1,    //当前页
                    pageSize: 15,   //每页条数
                },
                total:0,
                specialistList:{},      //面料专家数据
                fabricList:[],      //面料专家面料数据
                tagList:[],
                imgloading: true,
                num: 0,
            }
        },
        watch:{
            'specialistObj.page': function (newPage, oldPage) {
                if (newPage !== oldPage) {
                    this.imgloading = true;
                    this.num = 0
                }
            },
            fabricList(val){
                this.$nextTick(function () {
                let lists = this.$refs.imgRefs;
                for (let i in lists) {
                    this.imgLoad(lists[i], () => {
                        if (this.num == lists.length) {
                            // console.log("高清图加载完成");
                            this.imgloading = false
                        }
                    })
                }
            })
            }
        },
        methods: {
            // 高清图片加载
            imgLoad(img, callback) {
                var _this = this;
                var timer = setInterval(function () {
                    if (img.complete) {
                        _this.num++;
                        // console.log(_this.num);
                        callback(img);
                        clearInterval(timer);
                    }
                }, 50);
            },
            //获取面料专家数据
            async getSpecialistList(){
                let {data:res} = await specialistListApi(this.specialistObj)
                if (res.result == null) {
                    return
                } else {
                    this.specialistList = res.result.rows[0]
                    // console.log('获取面料专家数据',res.result)
                    this.tagList = this.specialistList.businessSector.split(',')
                }
            },
            //获取面料专家面料数据
			async getSpecialistDetailList(specialistObj){
				let {data:res} = await specialistDetailApi(this.specialistObj)
				if (res.result == null) {
					return
				} else {
					this.fabricList = res.result.rows
                    this.total = res.result.totalCount
					// console.log('获取面料专家面料数据',res,this.fabricList)
				}
			},
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.specialistObj.page = val
                this.getSpecialistDetailList(this.specialistObj)
            },
            // 推荐选品跳详情
			getDetail(id){
				this.$router.push({ path: `/fabricdetail/${id}`})
			},
            goBack() {
                this.$router.back()
            },
        },
        created() {
            this.specialistObj.id = this.$route.params.id
        },
        mounted() {
            this.getSpecialistList(this.specialistObj)
            this.getSpecialistDetailList(this.specialistObj)
        }
    }
</script>

<style lang="scss" scoped>
.container {
    width: 1360px;
    min-height: 1480px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .back-box{
        width: 100%;
        height: 80px;
        .back {
            width: 80px;
            height: 30px;
            background-color: rgb(241, 241, 241);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
            margin: 20px 0 -5px 0;
            padding: 5px 0;
        }
    }

    .container-inner {
        width: 1360px;
        min-height: 1350px;
        display: flex;
        // align-items: flex-start;
        flex-wrap: wrap;
        // box-sizing: border-box;
        position: relative;
        .top{
            width: 100%;
            height: 300px;
            
            img{
                width: 100%;
                height: 100%;
            }
        }
        .info{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            margin-left: 5%;
            .info_img{
                width: calc(413px * 0.4);
                height: calc(579px * 0.4);
                border-radius: 18px;
                
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 18px;
                }
            }
            .info_box{
                margin-left: 70px;
                height: 200px;
                // line-height: 80px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .info_text{
                    font-size: 36px;
                }
                .info_businessSector{
                    width: 1000px;
                    // height: 150px;
                    display: flex;
                    flex-wrap: wrap;
                    .businessSector_item{
                        display: inline-block;
                        border-radius: 5px;
                        opacity: 1;
                        padding: 0 12px;
                        background: rgba(250, 81, 81, 0.2);
                        margin: 5px 10px;
                        display: flex;

                        span{
                            font-family: Source Han Sans;
                            font-size: 20px;
                            font-weight: normal;
                            line-height: 36px;
                            letter-spacing: 0em;
                            color: #FA5151;
                        }
                    }
                }
            }
        }
        .buttom{
            width: 100%;
            min-height: 1000px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            .item{
                width: 18%;
                height: 300px;
                margin: 1%;
                text-align: center;
                img{
                    width: 225px;
                    height: 225px;
                    border-radius: 16px;
                }
                .commodityName{
                    text-align: left;
                    margin: 20px auto;
                    width: 225px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 18px;
                }
            }
        }
        .el-pagination {
            width: 100%;
            text-align: center;
        }
    }
}
</style>