<template viewport>
  <div class="header">
    <div class="header-inner">
      <img src="../../assets/images/ic_logo.png" alt="" />

      <ul class="nav">
        <div v-for="(item, index) in list" :key="index">
          <li
            class="router-link-exact-active"
            style="min-width: 45px"
            @click="refresh"
          >
            <router-link v-show="datalang == '中文简体'" :to="item.path">{{
              item.name
            }}</router-link>
            <router-link v-show="datalang == 'English'" :to="item.path">{{
              item.nameEn
            }}</router-link>
          </li>
        </div>
      </ul>

      <!-- 论坛按钮 -->
      <!-- <button v-show="datalang == '中文简体'" @click="goForum" class="forum">时尚论坛</button>
            <button v-show="datalang == 'English'" @click="goForum" class="forum">Forum</button> -->

      <div class="inp">
        <!-- <i class="el-icon-search inp-search"></i> -->
        <el-input
          :placeholder="$t('navbar.cotton')"
          v-model.trim="searchObj.keyWord"
          clearable
          @keyup.enter.native="getInputValue"
          ref="ref"
          @focus="gofabric"
        ></el-input>
        <el-button class="inp-btn" @click="getInputValue"
          ><i class="el-icon-search inp-search-btn"></i
        ></el-button>
      </div>

      <!-- <img src="../../assets/images/avatar.png" alt="" class="avatar-img"> -->

      <!-- 国家按钮 -->
      <!-- <Nation class="right-menu-item"></Nation> -->

      <!-- 国际化按钮 -->
      <Lang class="right-menu-item"></Lang>

      <div class="but">
        <button
          class="button-login"
          v-if="!token"
          @click="dakai"
          style="width: 90px"
        >
          {{ token ? user : $t("navbar.Login") }}
        </button>
        <div class="login-box" v-else @click="dialogFormVisible3 = true">
          <img src="../../../src/assets/images/img-head.png" />
          <button class="button-login2">
            {{ token ? user : $t("navbar.Login") }}
          </button>
        </div>

        <!-- 商家入驻 -->
        <!-- <button v-show="token" @click="goShopEntry" class="enter">店铺入驻</button> -->

        <!-- 测试版 -->
        <el-dialog
          title=""
          :visible.sync="dialogFormVisible"
          fullscreen
          class="dialog"
        >
          <div class="dialog-inner">
            <el-form ref="loginForm" :model="loginForm" @submit.native.prevent>
              <div class="dialog-inner-top">
                <button class="login-btn" style="margin-bottom: 20px">
                  {{ $t("navbar.Login") }}
                </button>
              </div>
              <div class="login">
                <div class="dialog-inner-footer">
                  <el-form-item
                    label=""
                    prop="username"
                    :rules="{
                      required: true,
                      message: $t('navbar.UserNameCannotEmpty'),
                    }"
                  >
                    <el-input
                      :placeholder="$t('navbar.AccountNumber')"
                      v-model.trim="loginForm.username"
                      clearable
                      class="dialog-inner-footer-input"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label=""
                    prop="password"
                    :rules="{
                      required: true,
                      message: $t('navbar.PasswordCannotEmpty'),
                    }"
                    style="margin-bottom: -15px"
                  >
                    <el-input
                      :placeholder="$t('navbar.Password')"
                      v-model.trim="loginForm.password"
                      show-password
                      @keyup.enter.native.once="gologin(loginForm)"
                      class="dialog-inner-footer-input"
                      style="margin: 20px 0"
                    ></el-input>
                  </el-form-item>

                  <span
                    class="dialog-inner-footer-span"
                    style="cursor: pointer"
                    @click="
                      (dialogFormVisible2 = true), (dialogFormVisible = false)
                    "
                    >{{ $t("navbar.ForgetThePassword") }}?</span
                  >
                  <el-form-item>
                    <el-button
                      class="dialog-inner-footer-btn"
                      @click.prevent="gologin(loginForm)"
                      v-throttle="2000"
                      >{{ $t("navbar.Login") }}</el-button
                    >
                  </el-form-item>

                  <!-- <el-form-item>
                                        <el-button class="dialog-inner-footer-btn btn-join-club" @click.prevent="joinClub"
                                            v-throttle="2000">{{$t('navbar.joinClub')}}</el-button>
                                    </el-form-item> -->

                  <!-- <el-form-item>
                                        <el-button class="dialog-inner-footer-btn btn-expert-recruitment" @click.prevent="expertRecruitment"
                                            v-throttle="2000">{{$t('navbar.ExpertRecruitment')}}</el-button>
                                    </el-form-item> -->
                </div>
                <div class="line"></div>
                <div class="dialog-img-box">
                  <div class="policy" style="width: 100%; text-align: center">
                    <a href="#">《{{ $t("navbar.PrivacyPolicy") }}》</a>
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </el-dialog>
        <el-dialog
          title=""
          :visible.sync="dialogFormVisible2"
          class="dialog2"
          fullscreen
        >
          <p class="pp1">{{ $t("navbar.RetrievePassword") }}</p>
          <p class="pp2">
            {{ $t("navbar.Service")
            }}<span style="color: #576b95; margin-left: 5px"
              >0510-86562540</span
            >
          </p>
        </el-dialog>

        <el-dialog
          title=""
          :visible.sync="dialogFormVisible3"
          class="dialog3"
          fullscreen
        >
          <p class="pp3">{{ $t("navbar.Logout") }}?</p>
          <span class="dialog-footer">
            <el-button
              @click="dialogFormVisible3 = false"
              class="dialog-footer-btn-one"
              >{{ $t("navbar.Cancel") }}</el-button
            >
            <el-button @click="uplogin" class="dialog-footer-btn-two">{{
              $t("navbar.Confirm")
            }}</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginApi, commodityList } from "../../api/index";
import { mapMutations } from "vuex";
// import { setToken, setUser, removeToken } from "@/utils/cookie.js"
import Lang from "../pc/Lang/index.vue";
import Nation from "../pc/Nation/index.vue";
export default {
  data() {
    return {
      list: [
        /**{
          path: "/home",
          name: "热销",
          nameEn: "HOTLIST",
        },*/
        {
          path: "/apparel",
          name: "服装",
          nameEn: "APPAREL",
        },
        {
          path: "/fabric",
          name: "面料库",
          nameEn: "FABRIC LIBRARY",
        },
        // {
        //     path: '/forum', //http://172.16.2.234:8088/
        //     name: '论坛'
        // },
        // {
        //     path: '/buyinformation',
        //     name: '求购信息'
        // },
        // {
        //   path: "/selection",
        //   name: "新品",
        //   nameEn: "NEW PRODUCT",
        // },
        // {
        //     // id: 3,
        //     path: '/marketplace',
        //     name: '应用市场',
        //     nameEn:'Marketplace',
        // },
      ],
      index: 0,
      loginForm: {
        username: "",
        password: "",
      },
      currentUser: "",

      active: 0,
      tabIndex: 0,
      input: "",
      // input2: '',
      // input3: '',
      // input4: '',
      // input5: '',
      // input6: '',
      // input7: '',
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      activeIndex: "1",
      isShowFooter: true,
      // activeName: 'first',
      formLabelWidth: "100px",
      options: [
        {
          value: "选项1",
          label: "设计师",
        },
        {
          value: "选项2",
          label: "管理员",
        },
        {
          value: "选项3",
          label: "供应商",
        },
      ],
      value: "",
      options2: [
        {
          value: "选项1",
          label: "个人",
        },
        {
          value: "选项2",
          label: "团体",
        },
      ],
      value2: "",

      language: "zh", // 选中的语言
      languagelist: ["english", "zh"],

      searchObj: {
        keyWord: "",
        page: 1,
        pageSize: 18,
      },
      idsObj: {
        current: 1, //当前第几页
        ids: null, //列表选择商品 传入的商品ID  默认进入页面展示的数据
        pageSize: 18,
      },
      isRefresh: false,
      datalang: "", //路由语言判断
    };
  },
  components: {
    Lang,
    Nation,
  },
  watch: {
    // $route(to, from) {
    //     if (to.path == '/forum') {
    //         window.location.href = 'http://172.16.2.234:8088/';
    //     }
    // },
  },
  methods: {
    goForum() {
      // window.open('http://58.215.206.250:28088/')
      window.open("http://form.fabtank.cn:18088/");
    },
    goShopEntry() {
      this.$router.push("/shopEntry");
      console.log("去往商家入驻页面");
    },
    refresh() {
      // console.log('刷新页面1！')
      if (this.$route.path == "/fabric" || this.$route.path == "/selection") {
        // console.log('刷新页面2！')
        this.isRefresh = true;
        //让点击事件在在面料库页面触发，在面料库刷新，不在这里使用this.$router.go(0)，优化体验
        this.$EventBus.$emit("isRefresh", this.isRefresh);

        // this.$router.go(0)
        // if (this.idsObj.current != 1) {
        //     // console.log('刷新页面3！')
        //     this.$router.go(0)
        // }
      }
    },
    gofabric() {
      if (this.token) {
        if (this.$route.path != "/fabric") {
          this.$router.push("/fabric");
          // console.log('99999');
        }
      }
    },
    selectlang(e) {
      console.log(e.target.selectedIndex);
      // 根据监听改变获取到的值去数组里找到对应的值
      localStorage.setItem("lang", this.languagelist[e.target.selectedIndex]);
      this.$i18n.locale = this.languagelist[e.target.selectedIndex]; //切换字典文件
    },

    dakai() {
      // console.log("打开弹出层")
      this.dialogFormVisible = true;
    },
    uplogin() {
      console.log("退出登录！");
      // window.Cookie.clear()
      this.dialogFormVisible3 = false;

      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
        for (var i = keys.length; i--; ) {
          document.cookie =
            keys[i] + "=0;path=/;expires=" + new Date(0).toUTCString(); //清除当前域名下
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString();
          document.cookie =
            keys[i] +
            "=0;path=示例：/index.vue（不同域的path,也就是你清除不了的cookie）;domain=示例：10.10.10.208（不同域的domain,也就是你清除不了的cookie）;expires=" +
            new Date(0).toUTCString();
        }
      }

      sessionStorage.clear();
      this.$router.push("/home");
      //想清空vuex中的数据，让页面重新加载就行了
      window.location.reload();
    },
    changeActive(index) {
      this.active = index;
      // this.index = index
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // handleClick(tab, event) {
    //     console.log(tab, event);
    // },

    //登录接口
    async goLoginApi() {
      let res = await LoginApi(this.loginForm);
      // console.log(res, "登录返回的数据！")
      // this.token = res.data.result
      // console.log(this.token)
      if (res.status === 200 && res.data.result) {
        // 登录成功,将token保存
        // window.localStorage.setItem("token", res.data.result)
        // this.$store.state.token = res.data.result
        // Cookies.set("token", res.data.result)
        // console.log(Cookies.get('token'))
        // 登录的时候手动设置 store
        // setToken(res.data.result)
        this.setToken(res.data.result);
        // this.$store.state.token = res.data.result
        this.setUser(this.loginForm.username);
      }
      if (res.data.success) {
        this.$message({
          message: "登录成功！",
          type: "success",
        });
        //上面定义了什么类型 这里清空时就要用什么类型 否则控制台会报错
        this.loginForm = {};
        this.dialogFormVisible = false;
      } else {
        this.$message.error("用户名或密码不正确，请重新登录！");
      }
    },
    gologin(loginForm) {
      this.goLoginApi(loginForm);
      // console.log("登录")
    },
    goregister() {
      console.log("注册");
    },
    async getcommodityList(idsObj) {
      let { data: res } = await commodityList(idsObj);
      console.log(res);
    },
    getInputValue() {
      if (this.token) {
        // if (this.$route.path != '/fabric') {
        //     this.$router.push('/fabric')
        // }

        if (this.searchObj.keyWord !== "") {
          // this.goSearchKeyWord()
          // console.log(this.searchObj.keyWord)
          //将关键字传入fabric页面 在那边请求接口
          this.$EventBus.$emit("msg", this.searchObj.keyWord);
          this.searchObj.keyWord = "";
          // console.log('66666');
        } else {
          // this.$message.error('请输入搜索词！');
          this.getcommodityList(this.idsObj);
          // window.location.reload()
          this.$router.go(0);
        }
      } else {
        alert("请先登录！");
      }
    },
    joinClub() {
      this.$router.push("/joinClubSupplie");
      this.dialogFormVisible = false;
    },
    // expertRecruitment(){
    //     this.$router.push('/recruitment');
    //     this.dialogFormVisible = false
    // },
    ...mapMutations(["setToken"]),
    ...mapMutations(["setUser"]),
  },
  //引入 getters
  computed: {
    token() {
      return this.$store.state.token;
    },
    user() {
      return this.$store.state.user;
    },
    // ...mapGetters(['getToken'])
  },
  created() {
    // 获取缓存的语种，如若没有（新用户），保存当前默认语言
    if (localStorage.getItem("lang")) {
      // console.log(localStorage.getItem('lang'))
      this.language = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.language);
    }

    this.$EventBus.$on("this.languageValue", (data) => {
      this.datalang = data;
      // console.log('xxxxxxx',this.datalang);
    });
  },
  mounted() {
    this.$EventBus.$on("page", (page) => {
      this.idsObj.current = page;
      // console.log("$EventBus-page", this.idsObj.current)
    });
    this.$EventBus.$on("pages", (pages) => {
      this.idsObj.current = pages;
      // console.log("$EventBus-pages", this.idsObj.current)
    });
  },
  beforeDestroy() {
    //移除监听事件"msg"
    this.$EventBus.$off("msg");
  },
};
</script>

<style lang="scss">
.router-link-active {
  &.router-link-exact-active {
    font-weight: 700;
    border-bottom: 10px solid #1a1919;
    padding-bottom: 25px;
    font-weight: bold;
    color: #000 !important;
  }
}

* {
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  border-bottom: 0.0625rem solid black;
  background: linear-gradient(180deg, #cecece 0%, #c0c4c5 98%);
  // height: 103px;
  // position: relative;
  .el-dialog__wrapper {
    // position: sticky;
    top: 200px;
  }

  .el-dialog__body {
    padding: 0;
  }

  .header-inner {
    max-width: 1920px;
    height: 98px;
    line-height: 98px;
    margin: auto;
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #cecece 0%, #c0c4c5 98%);

    img {
      // position: absolute;
      width: 138px;
      height: 70px;
      margin-left: 75px;
    }

    .nav {
      display: flex;
      list-style: none;
      // position: absolute;
      // left: 401px;
      // top: 41px;
      margin-top: -68px;
      margin-left: 7%;
      width: 680px;
      height: 27px;
      justify-content: space-between;

      li {
        min-width: 30px;
        height: 98px;
        overflow: hidden;
        font-family: MiSans;
        font-size: 20px;
        letter-spacing: 0em;
        color: #3d3d3d;
      }
    }

    a {
      text-decoration: none;
      font-size: 24px;
      color: #fff;
    }

    .forum {
      width: 120px;
      height: 100%;
      vertical-align: middle;
      font-family: MiSans;
      border: none;
      font-size: 20px;
      background-color: #ffffff;
      margin-left: 2%;
      // padding: 32px 0;
      cursor: pointer;
    }

    .right-menu-item {
      // width: 100px;
      margin-left: 1%;
      // background-color: #FF235A;
    }

    .inp {
      // position: absolute;
      // margin-left: 175px;
      margin-left: 8%;
      // width: 640px;
      width: 30%;
      line-height: 42px;
      border-radius: 5px;
      font-size: larger;
      opacity: 1;
      background: rgba(255, 255, 255, 0.6);
      box-sizing: border-box;
      border: 1px solid #000000;
      display: flex;
      align-items: center;
      background-color: #f6f6f6;

      .inp-search {
        font-size: 26px;
        margin-left: 16px;
        margin-top: 2px;
      }

      input {
        // position: absolute;
        // width: 300px;
        width: 85%;
        // margin-left: 10px;
        margin-left: 2%;
        border: none;
        outline: none;
        background-color: #f6f6f6;
      }

      .inp-btn {
        width: 45px;
        height: 34px;
        border-radius: 5px;
        opacity: 1;
        background: #000000;
        border: none;
        margin-right: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        .inp-search-btn {
          font-size: 16px;
        }
      }
    }

    .avatar-img {
      // position: absolute;
      // margin-left: 30px;
      margin-left: 2%;
      width: 35px;
      height: 35px;
      border: 1px solid black;
      border-radius: 50%;
      z-index: 999;
    }

    .but {
      // position: absolute;
      // margin-left: 20px;
      margin-left: 1%;
      margin-right: 1%;
      display: flex;

      .button-login {
        // border: none;
        font-size: 20px;
        // width: 160px;
        // line-height: 27px;
        background-color: #242424;
        padding: 5px 15px;
        color: #fff;
        border-radius: 5px;
        // border: 1px solid #ABB8C2;
      }

      .button-login2 {
        border: none;
        padding: 5px 15px;
        background: transparent;
        color: #000;
        border-radius: 5px;
      }

      button {
        border: none;
        font-size: 20px;
        width: 50px;
        line-height: 27px;
        background-color: white;
      }

      .enter {
        width: 90px;
        margin-left: 10px;
        margin-right: 20px;
      }

      .dialog {
        // margin:0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -12%;
        margin-top: -15%;
        // margin: auto;
        // height: 480px;
        width: 475px;
        height: 580px;
        // width: 475px;
        // height: 640px;
        min-width: 475px;
        // min-height: 640px;
        max-height: 580px;
        // height: 30%;
        background-color: #323233;
        border-radius: 40px;

        .dialog-inner {
          margin-top: 25px;
          padding: 0 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // align-content: space-evenly;

          .scan-box {
            display: flex;
            justify-content: center;
            margin: 50px 0 30px -38px;

            img {
              width: 154px;
              height: 156px;
              padding: 29px 35px 40px 36px;
              border-radius: 15px;
              box-shadow: 2px 2px 4px 4px #f2f2f2;
            }
          }

          // .policy2 {
          //     margin-top: 10px;
          // }

          // .policy {
          //     width: 286px;
          //     height: 27px;
          //     margin-bottom: -8px;

          //     a {
          //         font-weight: 500;
          //     }

          //     a:focus {
          //         font-weight: 500;
          //         margin: 0;
          //         padding: 0;
          //         border: none;
          //     }
          // }

          .line {
            width: 450px;
            height: 1px;
            border-top: 1px solid #d8d8d8;
            margin: 12px 0 10px 0;
          }

          .account-box {
            width: 260px;
            height: 44px;
            margin: 248px 0 25px 0;
            display: flex;
            align-items: center;

            span {
              display: block;
              padding: 6px 11px 7px 34px;
              border: 1px solid #787476;
              border-radius: 20px;
              font-size: 16px;

              .img-hsj {
                width: 16px;
                height: 16px;
                margin: 4px 0 10px -25px;
              }
            }

            img {
              width: 35px;
              height: 35px;
            }

            .img-qq {
              margin-left: 160px;
            }

            .img-wb {
              margin-left: 220px;
            }
          }

          .dialog-inner-top {
            display: flex;
            justify-content: center;
            // .login-btn:focus{
            //     border-bottom: 1px solid #FF235A;
            // }
          }

          .dialog-inner-footer {
            // height: 350px;
            height: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            .dialog-inner-footer-input,
            .el-input__inner {
              font-size: 16px;
              width: 326px;
              height: 44px;
              border-radius: 10px;
            }

            .dialog-inner-footer-span {
              font-size: 20px;
              margin-left: 200px;
            }

            .dialog-inner-footer-btn {
              font-size: 20px;
              width: 326px;
              height: 44px;
              border-radius: 20px;
              background-color: #ff235a;
              color: white;
            }

            .btn-join-club {
              background-color: rgb(235, 235, 235);
              color: rgba($color: #000000, $alpha: 0.9);
              border: 1px solid #eeeff2;
            }

            .btn-expert-recruitment {
              background-color: rgb(158, 223, 248);
              color: rgba($color: #414141, $alpha: 0.9);
              border: 1px solid #eeeff2;
            }
          }

          // //else
          // .register {
          //     width: 450px;
          //     display: flex;
          //     flex-direction: column;
          //     align-items: center;

          //     .fegister-footer {
          //         width: 326px;
          //         height: 425px;
          //         display: flex;
          //         flex-direction: column;
          //         align-items: center;
          //         justify-content: space-evenly;

          //         .dialog-inner-footer-btn {
          //             font-size: 20px;
          //             width: 326px;
          //             height: 44px;
          //             border-radius: 20px;
          //             background-color: #FF235A;
          //             color: white;
          //         }

          //         .el-input__inner {
          //             border-radius: 10px;
          //             font-size: 16px;
          //         }
          //     }

          //     .policy {
          //         width: 100%;
          //         height: 27px;
          //         margin-bottom: -8px;

          //         a {
          //             font-weight: 500;
          //         }

          //         a:focus {
          //             font-weight: 500;
          //             margin: 0;
          //             padding: 0;
          //             border: none;
          //         }
          //     }

          //     .line {
          //         width: 450px;
          //         height: 1px;
          //         border-top: 1px solid #D8D8D8;
          //         margin: 12px 0 10px 0;
          //     }
          // }

          .dialog-img-box {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .dialog2 {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -238px;
        margin-top: -170px;
        // margin: auto;
        width: 500px;
        height: 340px;
        border-radius: 40px;

        .pp1 {
          font-size: 30px;
          width: 100%;
          text-align: center;
          margin-top: 20px;
        }

        .pp2 {
          width: 100%;
          font-size: 24px;
          text-align: center;
          margin-top: 30px;
        }
      }

      .dialog3 {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -238px;
        margin-top: -180px;
        // margin: auto;
        width: 475px;
        height: 360px;
        border-radius: 40px;

        .pp3 {
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          margin-top: 40px;
        }

        .dialog-footer {
          margin: 55px;

          .dialog-footer-btn-one {
            width: 100px;
            background-color: #f4f4f5;
            margin: 60px 50px;
            color: black;
          }

          .dialog-footer-btn-two {
            width: 100px;
            background-color: #655C57;
            color: #fff;
          }
        }
      }
    }
  }
  .login-box {
    height: 100%;
    display: flex;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
